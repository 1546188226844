"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStoredOrDefault = exports.storeValue = exports.storeEventValue = exports.storeEventCheck = exports.decodeText = exports.encodeText = void 0;
const textDecoderNotSupported = 'Sorry, this system does not support TextDecoder...';
const encodeText = (str) => {
    if (!('TextDecoder' in window)) {
        console.error(textDecoderNotSupported);
        return;
    }
    return new TextEncoder().encode(str);
};
exports.encodeText = encodeText;
const decodeText = (buffer) => {
    if (!('TextDecoder' in window)) {
        console.error(textDecoderNotSupported);
        return;
    }
    return new TextDecoder().decode(buffer);
};
exports.decodeText = decodeText;
const storeEventCheck = (key, setter) => (e) => {
    localStorage.setItem(key, JSON.stringify(e.target.checked));
    setter === null || setter === void 0 ? void 0 : setter(e.target.checked);
};
exports.storeEventCheck = storeEventCheck;
const storeEventValue = (key, setter) => (e) => {
    var _a, _b;
    localStorage.setItem(key, JSON.stringify((_a = e.target) === null || _a === void 0 ? void 0 : _a.value));
    setter === null || setter === void 0 ? void 0 : setter((_b = e.target) === null || _b === void 0 ? void 0 : _b.value);
};
exports.storeEventValue = storeEventValue;
const storeValue = (key, value, setter, converter) => {
    var _a;
    localStorage.setItem(key, JSON.stringify((_a = converter === null || converter === void 0 ? void 0 : converter(value)) !== null && _a !== void 0 ? _a : value));
    setter === null || setter === void 0 ? void 0 : setter(value);
};
exports.storeValue = storeValue;
function getStoredOrDefault(key, def) {
    const value = localStorage.getItem(key);
    if (
    // eslint-disable-next-line eqeqeq
    value == null ||
        (typeof def !== 'number' && value === '0') ||
        (typeof def !== 'string' && value === ''))
        return def;
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return def;
    }
}
exports.getStoredOrDefault = getStoredOrDefault;
