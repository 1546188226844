import QrCodeIcon from '@mui/icons-material/QrCode';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LabelDesign } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import React, { memo } from 'react';
import {
  arePropsEqual,
  isCommonNodeDataEqual,
  isSpecificDataEqual
} from 'src/util/processFlow/node';
import BaseNode, { Content, Handles } from './BaseNode';
import { TypedNode } from 'src/util/processFlow/types';
import { NodeProps } from '@xyflow/react';

export const LabelDesignNode: React.FC<NodeProps<TypedNode<LabelDesign>>> = (props) => {
  const labelDesign = props.data.specificData;
  const content: Content = {
    titleId: 'processFlow.LabelDesign',
    description: labelDesign?.name,
    iconStart: QrCodeIcon
  };

  const handles: Handles = {
    inputHandles: props.data.parent ? [] : [HandleType.MULTIPLE],
    outputHandles: [HandleType.SINGLE]
  };

  return (
    <div>
      {/* Content and basic handlers */}
      <BaseNode
        selected={props.selected}
        content={content}
        handles={handles}
        id={props.id}
        {...props.data.baseNodeData}
        parent={props.data.parent}
      />
    </div>
  );
};

export default memo(LabelDesignNode, arePropsEqual(isCommonNodeDataEqual, isSpecificDataEqual));
