import StartIcon from '@mui/icons-material/Start';
import { NodeProps } from '@xyflow/react';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { arePropsEqual, isBaseNodeDataEqual, isSpecificDataEqual } from 'src/util/processFlow/node';
import { TypedNode } from 'src/util/processFlow/types';
import BaseNode, { Content, Handles } from './BaseNode';

export const StartNode: React.FC<NodeProps<TypedNode<undefined>>> = (props) => {
  const { $t } = useIntl();

  const content: Content = {
    iconStart: StartIcon,
    description: $t({ id: 'processFlow.Start' })
  };
  const handles: Handles = {
    outputHandles: [HandleType.START]
  };

  return (
    <div>
      {/* Content and basic handlers */}
      <BaseNode
        selected={props.selected}
        content={content}
        handles={handles}
        id={props.id}
        {...props.data.baseNodeData}
      />
    </div>
  );
};

export default memo(StartNode, arePropsEqual(isSpecificDataEqual, isBaseNodeDataEqual));
