import { CircularProgress, Container, Typography } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';

const useStyles = appMakeStyles((theme) => ({
  loadingText: {
    margin: theme.spacing(2)
  },
  progressCircle: {
    margin: theme.spacing(2)
  },
  centeredColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%'
  }
}));

const LoadingCircle: React.FC<{ text?: string }> = (props) => {
  const classes = useStyles();
  const { text } = props;

  return (
    <Container className={classes.centeredColumn}>
      <CircularProgress
        data-testid="loading-circle"
        className={classes.progressCircle}
        sx={{
          width: 50,
          height: 50
        }}
      />
      {text?.length && (
        <Typography variant="h5" className={classes.loadingText}>
          {text}
        </Typography>
      )}
    </Container>
  );
};

export default LoadingCircle;
