import {
  KeyUserPublic,
  MaybeKeyUserPublic,
  UserPublic
} from 'flyid-core/dist/Database/Models/User';
import { MyUserStateData } from 'src/redux/types/userTypes';

export const isModeratorClaims = (user?: MyUserStateData) => {
  return user?.claims?.moderator === true;
};

export const isModeratorProf = (profile?: MaybeKeyUserPublic): profile is UserPublic => {
  return profile?.moderator === true;
};

export const isAssistantProf = (profile?: MaybeKeyUserPublic): profile is UserPublic => {
  return profile?.assistant === true;
};

export const isPilotProf = (profile?: MaybeKeyUserPublic): profile is UserPublic => {
  return profile?.pilot === true;
};

export const isKeyUserProf = (profile?: MaybeKeyUserPublic): profile is KeyUserPublic => {
  return profile?.keyUser === true;
};

type UserSelector = (uid: string, profile: UserPublic) => boolean;

/**
 * Filters users, returning only moderators.
 * Returns undefined if usersData is not valid.
 */
export const filterModerators = (
  usersData?: { [uid: string]: UserPublic | undefined },
  criteria?: UserSelector
) => {
  if (!usersData) return undefined;
  const moderators: { [uid: string]: UserPublic }[] = [];

  Object.entries(usersData).forEach(([uid, userData]) => {
    if (!userData?.parent && !!userData?.moderator) {
      // Use filtering criteria if provided, otherwise select moderator
      (criteria?.(uid, userData) ?? true) && moderators.push({ [uid]: userData });
    }
  });

  return moderators;
};
