import { KeyUserPublic, UserPublic } from 'flyid-core/dist/Database/Models/User';
import { isNulli, sortObjectByValueSelector } from 'flyid-core/dist/Util/helpers';
import { useMemo } from 'react';
import { filterModerators, isKeyUserProf } from 'src/util/helpers/user';
import { AppState } from '../store';
import { selectTargetCompany } from './globalSelectors';

/** Currently authenticated user profile. Undefined if not yet loaded */
export const selectCurrentUser = (state: AppState) => state.user;

export const selectCurrentUserProfile = (state: AppState) =>
  selectCurrentUser(state)?.profile ?? null;

export const selectUserProfile = (state: AppState, uid: string) => {
  const userProfiles = selectUserProfiles(state);
  return userProfiles ? userProfiles[uid] : null;
};

export const selectUserProfiles = (state: AppState, sortByName = false) => {
  const targetCompany = selectTargetCompany(state);
  if (!targetCompany) return undefined;

  const { isLoaded, data, error } = state.firestore.userProfiles[targetCompany] ?? {};
  if (!isLoaded) return null;

  return sortByName
    ? useMemo(
        () => data && sortObjectByValueSelector(data, (u) => u!.firstName + u!.lastName),
        [state.firestore.userProfiles, sortByName, targetCompany]
      )
    : data;
};

export const selectUserProfilePics = (state: AppState) => state.user.profilePics;

export const selectUserProfileThumbs = (state: AppState) => state.user.profilePicThumbs;

type SelectAuthDomainsExtraArgs = {
  company?: string;
  targetUser?: string;
};
/**
 * Selects the user's authDomains for the given company parameter.
 * If no company parameter is given, defaults to the value provided by {@link selectTargetCompany}
 */
export const selectAuthDomains = (state: AppState, extraArgs?: SelectAuthDomainsExtraArgs) => {
  const { company = selectTargetCompany(state), targetUser = undefined } = extraArgs ?? {};

  const profile = targetUser
    ? selectUserProfile(state, targetUser)
    : selectCurrentUserProfile(state);
  if (isNulli(profile)) return undefined;

  if (isKeyUserProf(profile) && company) {
    const { isLoaded, data: authDomains } = state.firestore.authDomains?.[company] ?? {};
    return isLoaded ? authDomains ?? [] : null;
  }
  return profile === null ? null : (profile as UserPublic).authDomains;
};

export const selectKeyUserCompanies = (state: AppState, ordered = true) => {
  const profile = selectCurrentUserProfile(state);
  if (isNulli(profile) || !isKeyUserProf(profile)) return undefined;

  const companies = (profile as KeyUserPublic).company;
  return ordered ? [...companies].sort() : companies;
};

/**
 * Returns the domain parent or null, if the requiried data has not been loaded yet.
 */
export const selectDomainParent = (state: AppState, domain: string) => {
  const currProfile = selectCurrentUser(state);
  // If current user is not key user, it must be the moderator which parents this domain.
  if (!currProfile?.profile?.keyUser) return currProfile?.uid;

  // If key user, then we have to select the correct parent and pass it down to other components
  const targetCompany = selectTargetCompany(state);

  if (targetCompany) {
    const selectionData = selectUserProfiles(state, false);
    if (selectionData === null) return null;
    const parentCandidates = filterModerators(
      selectionData,
      (uid, p) => p.company === targetCompany && p.authDomains && p.authDomains.includes(domain)
    );
    if (parentCandidates) {
      // It should only have one, but can fail if database has some sort of inconsistency.
      if (parentCandidates.length > 1)
        console.error('More than one parent found for this domain!!');
      return Object.keys(parentCandidates[0] ?? {})[0];
    }
  }

  return undefined;
};
