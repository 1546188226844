"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPublicCustomClaimsKeys = exports.CustomClaimsKeys = exports.CustomClaims = void 0;
class CustomClaims {
    constructor(arrayTypes) {
        this.company = '';
        // Permissions
        this.pilot = false;
        this.checker = false;
        this.assistant = false;
        this.moderator = false;
        this.keyUser = false;
        this.admin = false;
        this.authDomains = arrayTypes;
        this.authLicenses = arrayTypes;
        this.ownedAPIKeys = arrayTypes;
    }
}
exports.CustomClaims = CustomClaims;
exports.CustomClaimsKeys = Object.keys(new CustomClaims([]));
exports.UserPublicCustomClaimsKeys = exports.CustomClaimsKeys.filter((k) => k !== 'ownedAPIKeys');
