import ForkRightIcon from '@mui/icons-material/ForkRight';
import { Box } from '@mui/material';
import { NodeProps, useUpdateNodeInternals } from '@xyflow/react';
import { Case, CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import {
  getPureId,
  HandleType,
  NodeType
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LabelDesign } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import { isLogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import React, { memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import { buildCaseDataDescription, getElseCase } from 'src/util/processFlow/cases';
import {
  arePropsEqual,
  isCommonNodeDataEqual,
  isSpecificDataEqual
} from 'src/util/processFlow/node';
import { TypedNode } from 'src/util/processFlow/types';
import BaseNode, { Content, Handles } from './BaseNode';

export const ConditionalNode: React.FC<NodeProps<TypedNode<CaseData[]>>> = (props) => {
  const intl = useIntl();

  const updateNodeInternals = useUpdateNodeInternals();
  const { getNodes, setEdges } = useAppReactFlow();

  const propCasesData = props.data.specificData;
  if (!propCasesData) throw Error('Missing cases data!');

  // Make sure else case always exists.
  if (propCasesData.length === 0) {
    propCasesData.push([getElseCase()]);
  }

  const casesCount = propCasesData?.length;

  const content: Content = {
    iconStart: ForkRightIcon,
    description: intl.$t({ id: 'processFlow.Conditional' })
  };

  const nodeNamesById = useMemo(() => {
    const targetNodeIds = propCasesData.flatMap((casesData) =>
      casesData
        .filter((c): c is Case => !isLogicalOperator(c))
        .map((c) => c.targetNodeId)
        .filter(Boolean)
    );
    return getNodes()
      .filter((n) => targetNodeIds.includes(getPureId(n.id)!) && n.type === NodeType.LabelDesign)
      .reduce<{ [id: string]: string }>(
        (r, n) =>
          Object.assign(r, { [getPureId(n.id)!]: (n.data.specificData as LabelDesign).name }),
        {}
      );
  }, [getNodes, propCasesData]);

  const outputHandleDescriptors = propCasesData?.map((cd) => (
    <Box key={`CN-OutHandle-CW-${props.id}`}>
      {buildCaseDataDescription(intl, nodeNamesById, cd).map((caseDataDescription, i) => (
        <Box sx={{ textAlign: 'center' }} key={`cdd${i}`}>
          {caseDataDescription}
        </Box>
      ))}
    </Box>
  ));

  const handles: Handles = {
    inputHandles: [HandleType.MULTIPLE],
    outputHandles: casesCount ? new Array(casesCount).fill(HandleType.SINGLE) : [],
    outputHandleDescriptors
  };

  useEffect(() => {
    // ReactFlow requires internal state changes when Handler changes
    updateNodeInternals(props.id);
  }, [propCasesData, setEdges]);

  return (
    <Box>
      {/* Content and basic handlers */}
      <BaseNode
        id={props.id}
        selected={props.selected}
        content={content}
        handles={handles}
        {...props.data.baseNodeData}
        parent={props.data.parent}
      />
    </Box>
  );
};

export default memo(ConditionalNode, arePropsEqual(isCommonNodeDataEqual, isSpecificDataEqual));
