"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateParentDimensions = exports.getCurrentParentDimensions = exports.getOutputNodePosition = exports.getNewChildPosition = exports.PARENT_HORIZONTAL_PADDING = exports.PARENT_VERTICAL_START = exports.PARENT_VERTICAL_SPACING = exports.PARENT_TITLE_HEIGHT_PX = exports.ExpectedSizes = exports.createEdgeBetween = exports.generateDoubleId = exports.getTypedElementId = exports.getTypedId = void 0;
const Elements_1 = require("../Database/Models/Settings/ProcessFlow/Elements");
/** Get node id in format NodeType_RandomizedId */
const getTypedId = (node) => `${node.type}_${node.id}`;
exports.getTypedId = getTypedId;
const getTypedElementId = (id, type) => `${type}_${id}`;
exports.getTypedElementId = getTypedElementId;
const generateId = () => Math.random().toString(36).slice(2);
const generateDoubleId = () => generateId().concat(generateId());
exports.generateDoubleId = generateDoubleId;
const createEdgeBetween = (sourceNodeId, targetNodeId, sourceHandle = `${Elements_1.HandleType.SINGLE}_0`, targetHandle = `${Elements_1.HandleType.MULTIPLE}_0`) => {
    return {
        source: sourceNodeId,
        target: targetNodeId,
        sourceHandle,
        targetHandle
    };
};
exports.createEdgeBetween = createEdgeBetween;
/** Used both in front end sizing and backend migration scripts */
exports.ExpectedSizes = {
    [Elements_1.NodeType.Start]: { width: 118, height: 44 },
    [Elements_1.NodeType.LabelDesign]: { width: 174, height: 77 },
    [Elements_1.NodeType.ManualInputField]: { width: 152, height: 77 },
    [Elements_1.NodeType.TakePicture]: { width: 156, height: 77 },
    [Elements_1.NodeType.AutoFillData]: { width: 156, height: 44 },
    [Elements_1.NodeType.Conditional]: { width: 156, height: 48 },
    [Elements_1.NodeType.LogicalBlock]: { width: 174, height: 140 },
    [Elements_1.NodeType.CustomMarker]: { width: 203, height: 140 },
    [Elements_1.NodeType.End]: { width: 118, height: 44 }
};
/****************************************
 **         PARENT NODE DATA           **
 **                                    **
 **  These are used both on front      **
 **  end, defining widget positioning  **
 **  and back end settings migration   **
 ****************************************/
exports.PARENT_TITLE_HEIGHT_PX = 24;
exports.PARENT_VERTICAL_SPACING = 16;
exports.PARENT_VERTICAL_START = exports.PARENT_TITLE_HEIGHT_PX + exports.PARENT_VERTICAL_SPACING;
exports.PARENT_HORIZONTAL_PADDING = 24;
const getNewChildPosition = (newNodeSize, index, parentWidth, outputSiblingNode) => {
    var _a, _b, _c, _d, _e, _f;
    const sibWidth = (_b = (_a = outputSiblingNode === null || outputSiblingNode === void 0 ? void 0 : outputSiblingNode.measured) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : outputSiblingNode === null || outputSiblingNode === void 0 ? void 0 : outputSiblingNode.width;
    const newNodeWidth = (_d = (_c = newNodeSize === null || newNodeSize === void 0 ? void 0 : newNodeSize.measured) === null || _c === void 0 ? void 0 : _c.width) !== null && _d !== void 0 ? _d : newNodeSize.width;
    const newNodeHeight = (_f = (_e = newNodeSize === null || newNodeSize === void 0 ? void 0 : newNodeSize.measured) === null || _e === void 0 ? void 0 : _e.height) !== null && _f !== void 0 ? _f : newNodeSize.height;
    const contentParentWidth = parentWidth - (outputSiblingNode ? 2 * exports.PARENT_HORIZONTAL_PADDING + (sibWidth !== null && sibWidth !== void 0 ? sibWidth : 130) / 3 : 0);
    return {
        x: (contentParentWidth - (newNodeWidth !== null && newNodeWidth !== void 0 ? newNodeWidth : 190)) / 2,
        y: exports.PARENT_VERTICAL_START + index * ((newNodeHeight !== null && newNodeHeight !== void 0 ? newNodeHeight : 77) + exports.PARENT_VERTICAL_SPACING)
    };
};
exports.getNewChildPosition = getNewChildPosition;
const getOutputNodePosition = (node, parentWidth, parentHeight) => {
    var _a, _b, _c, _d;
    const nodeWidth = (_b = (_a = node.measured) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : node.width;
    const nodeHeight = (_d = (_c = node.measured) === null || _c === void 0 ? void 0 : _c.height) !== null && _d !== void 0 ? _d : node.height;
    const pos = {
        x: parentWidth - (nodeWidth !== null && nodeWidth !== void 0 ? nodeWidth : 240) / 3,
        y: (parentHeight + exports.PARENT_TITLE_HEIGHT_PX - (nodeHeight !== null && nodeHeight !== void 0 ? nodeHeight : 100)) / 2
    };
    return pos;
};
exports.getOutputNodePosition = getOutputNodePosition;
const calculateParentWidth = (contentChildren, outputChild) => {
    var _a, _b;
    let width = 0;
    contentChildren.forEach((n) => {
        var _a, _b, _c;
        width = Math.max((_c = (_b = (_a = n.measured) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : n.width) !== null && _c !== void 0 ? _c : 100, width);
    });
    // If there's an output node, add some padding as space for new edges
    if (outputChild) {
        const outChildWidth = (_b = (_a = outputChild.measured) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : outputChild.width;
        width += 1 * exports.PARENT_HORIZONTAL_PADDING + (outChildWidth !== null && outChildWidth !== void 0 ? outChildWidth : 100) / 3;
    }
    width += 2 * exports.PARENT_HORIZONTAL_PADDING;
    return width;
};
const calculateParentHeight = (contentChildren, outputChild) => {
    let height = exports.PARENT_VERTICAL_START;
    contentChildren.forEach((n) => {
        var _a, _b, _c;
        height += ((_c = (_b = (_a = n.measured) === null || _a === void 0 ? void 0 : _a.height) !== null && _b !== void 0 ? _b : n.height) !== null && _c !== void 0 ? _c : 30) + exports.PARENT_VERTICAL_SPACING;
    });
    return height;
};
const getCurrentParentDimensions = (parentRef) => {
    var _a, _b;
    if (((_a = parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) && parentRef.current.clientHeight) {
        return { width: (_b = parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth, height: parentRef.current.clientHeight };
    }
    return undefined;
};
exports.getCurrentParentDimensions = getCurrentParentDimensions;
const calculateParentDimensions = (contentChildren, outputChild) => {
    if (!contentChildren.length)
        return undefined;
    return {
        width: calculateParentWidth(contentChildren, outputChild),
        height: calculateParentHeight(contentChildren, outputChild)
    };
};
exports.calculateParentDimensions = calculateParentDimensions;
