"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBarcodeFieldComplete = exports.getBarcodeFieldEmptiableFields = exports.BarcodeFieldRequiredKeys = exports.BarcodeFieldEntries = exports.BarcodeFieldKeys = exports.BarcodeField = exports.BarcodeFieldLimiterTypeKeys = exports.BarcodeFieldLimiterTypes = exports.BarcodeFieldTypeKeys = exports.BarcodeCharacterFilter = exports.BarcodeFieldTypes = void 0;
const helpers_1 = require("../../../../Util/helpers");
var BarcodeFieldTypes;
(function (BarcodeFieldTypes) {
    BarcodeFieldTypes["data"] = "data";
    BarcodeFieldTypes["constant"] = "constant";
})(BarcodeFieldTypes = exports.BarcodeFieldTypes || (exports.BarcodeFieldTypes = {}));
var BarcodeCharacterFilter;
(function (BarcodeCharacterFilter) {
    BarcodeCharacterFilter["Numeric"] = "0-9";
    BarcodeCharacterFilter["AlphabeticLowerCase"] = "a-z";
    BarcodeCharacterFilter["AlphabeticUpperCase"] = "A-Z";
    BarcodeCharacterFilter["Custom"] = "Custom";
})(BarcodeCharacterFilter = exports.BarcodeCharacterFilter || (exports.BarcodeCharacterFilter = {}));
exports.BarcodeFieldTypeKeys = Object.keys(BarcodeFieldTypes);
var BarcodeFieldLimiterTypes;
(function (BarcodeFieldLimiterTypes) {
    BarcodeFieldLimiterTypes["range"] = "range";
    BarcodeFieldLimiterTypes["separator"] = "separator";
})(BarcodeFieldLimiterTypes = exports.BarcodeFieldLimiterTypes || (exports.BarcodeFieldLimiterTypes = {}));
exports.BarcodeFieldLimiterTypeKeys = Object.keys(BarcodeFieldLimiterTypes);
class BarcodeField {
    constructor() {
        this.name = '';
        this.type = BarcodeFieldTypes.data;
        this.prefix = '';
        this.postfix = '';
        this.limiterType = BarcodeFieldLimiterTypes.separator;
        this.limiter = '';
        this.checkField = '';
        this.useCheckField = false;
        this.isAutoFillable = false;
        this.characterFilters = [];
    }
}
exports.BarcodeField = BarcodeField;
exports.BarcodeFieldKeys = Object.keys(new BarcodeField());
exports.BarcodeFieldEntries = Object.entries(new BarcodeField());
exports.BarcodeFieldRequiredKeys = exports.BarcodeFieldKeys;
const getBarcodeFieldEmptiableFields = (data) => {
    const emptiableKeys = ['prefix', 'postfix'];
    if (!data.useCheckField)
        emptiableKeys.push('checkField');
    if (data.type === BarcodeFieldTypes.constant)
        emptiableKeys.push('limiter', 'limiterType');
    return emptiableKeys;
};
exports.getBarcodeFieldEmptiableFields = getBarcodeFieldEmptiableFields;
const isBarcodeFieldComplete = (bf) => {
    const basicFields = Boolean(bf && bf.name && bf.type) && (0, helpers_1.isNonNulli)(bf === null || bf === void 0 ? void 0 : bf.prefix) && (0, helpers_1.isNonNulli)(bf === null || bf === void 0 ? void 0 : bf.postfix);
    const typeSpecific = bf && (bf.type === BarcodeFieldTypes.constant || Boolean(bf.limiterType && bf.limiter));
    const checkField = (bf === null || bf === void 0 ? void 0 : bf.useCheckField) ? Boolean(bf.checkField) : true;
    const flags = (0, helpers_1.isNonNulli)(bf === null || bf === void 0 ? void 0 : bf.isAutoFillable) && (0, helpers_1.isNonNulli)(bf === null || bf === void 0 ? void 0 : bf.useCheckField);
    const filters = Array.isArray(bf === null || bf === void 0 ? void 0 : bf.characterFilters) &&
        !!(!bf.characterFilters.includes(BarcodeCharacterFilter.Custom) || bf.customFilter);
    const res = Boolean(basicFields && typeSpecific && checkField && flags && filters);
    if (!res)
        console.log({ checkField, basicFields, type: typeSpecific, bf });
    return res;
};
exports.isBarcodeFieldComplete = isBarcodeFieldComplete;
