import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CodeIcon from '@mui/icons-material/Code';
import FlagIcon from '@mui/icons-material/Flag';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import QrCodeIcon from '@mui/icons-material/QrCode';
import StartIcon from '@mui/icons-material/Start';
import TableViewIcon from '@mui/icons-material/TableView';
import { Box, Typography } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { NodeType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import React, { DragEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { appMakeStyles } from 'src/theme/theme';

const useStyles = appMakeStyles(({ spacing, palette }) => ({
  node: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: palette.grey[200],
    borderColor: palette.primary.main,
    borderRadius: spacing(1),
    borderStyle: 'solid',
    borderWidth: 'thin',
    color: palette.primary.dark,
    padding: spacing(1),
    marginBottom: spacing(1),
    cursor: 'move'
  },
  icon: { width: spacing(4), height: spacing(4) },
  text: { marginLeft: spacing(2) }
}));

type Props = {
  style: ClassNameMap;
};

const nodes = [
  { IconComp: StartIcon, type: NodeType.Start },
  { IconComp: QrCodeIcon, type: NodeType.LabelDesign },
  { IconComp: StickyNote2Icon, type: NodeType.CustomMarker },
  { IconComp: KeyboardIcon, type: NodeType.ManualInputField },
  { IconComp: CameraAltIcon, type: NodeType.TakePicture },
  { IconComp: TableViewIcon, type: NodeType.AutoFillData },
  { IconComp: ForkRightIcon, type: NodeType.Conditional },
  { IconComp: CodeIcon, type: NodeType.LogicalBlock },
  { IconComp: FlagIcon, type: NodeType.End }
];

const FlowSidebar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { $t } = useIntl();

  const [expand, setExpand] = useState(false);

  const onDragStart = (event: DragEvent, nodeType: NodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'copyMove';
  };

  return (
    <Box
      component="aside"
      sx={{ maxWidth: '300px', p: 2, ...props.style }}
      onMouseEnter={() => setExpand(true)}
      onMouseLeave={() => setExpand(false)}
    >
      {nodes.map(({ type, IconComp }) => (
        <div
          className={classes.node}
          onDragStart={(e) => onDragStart(e, type)}
          draggable
          key={type}
        >
          <IconComp className={classes.icon} />
          {expand && (
            <Typography noWrap variant="h6" className={classes.text}>
              {$t({ id: `processFlow.${type}` })}
            </Typography>
          )}
        </div>
      ))}
    </Box>
  );
};

export default FlowSidebar;
