import { useEffect, useState } from 'react';
import { getPicturePath } from 'flyid-core/dist/Util/database';
import { ref, getDownloadURL } from 'firebase/storage';
import { defaultBucket } from 'src/firebase/firebase';

const useFirebaseImage = (
  company: string | undefined,
  domain: string,
  pictureFilename: string | null
) => {
  const [image, setImage] = useState<{ src: string; alt: string } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!company || !domain || !pictureFilename) return;

    setLoading(true);

    // Reference to the image in Firebase Storage
    const storageRef = ref(defaultBucket, getPicturePath(company, domain, pictureFilename));

    // Fetching the image URL
    const fetchImageUrl = async () => {
      try {
        const url = await getDownloadURL(storageRef);
        setImage({ src: url, alt: url });
      } catch (err) {
        setError('Failed to load image');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchImageUrl();
  }, [pictureFilename]);

  return { image, loading, error };
};

export default useFirebaseImage;
