import { DomainSettings } from 'flyid-core/dist/Database/Models';
import { useMemo } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { firestore } from 'src/firebase/firebase';
import { closestSettingsDocQuery } from 'src/util/helpers/database';
import { migrateSettings } from 'flyid-core/dist/Database/Migration/settingsMigration';

export const useClosestSettings = (
  company: string | undefined,
  domain: string | undefined,
  startTime: Date | undefined,
  defaultValue: DomainSettings | undefined | null
): [boolean, DomainSettings | undefined] => {
  const [settingsDocs, loadingSettings] = useCollectionDataOnce(
    // Preconditions for query building
    company && domain && startTime
      ? closestSettingsDocQuery(firestore, company, domain, startTime)
      : undefined
  );
  const settings = settingsDocs?.[0];
  // Memoize this value to avoid infinite loops, since 'migrateSettingsToProcessFlow' will create
  // a new object every iteration it goes through
  const closestSettings = useMemo(
    () =>
      loadingSettings
        ? undefined
        : // Use closest settings if available
          (settings &&
            (settings?.version ?? 1 >= 2
              ? settings
              : // Migrate settings if not version 2 yet
                migrateSettings(settings))) ??
          // Fallback to default value if loading is done and no closest settings have been found
          defaultValue,
    [loadingSettings, settings]
  );

  return [loadingSettings, closestSettings ?? undefined];
};
