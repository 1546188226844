import React from 'react';
import { CardMedia, Skeleton } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import { getLogo } from 'src/assets/assets';

const useStyles = appMakeStyles((theme) => ({
  splashStyle: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    position: 'absolute',
    maxWidth: 'none'
  },
  logo: {
    width: 272,
    height: 120
  }
}));

export default function Splash() {
  const classes = useStyles();
  setTimeout(() => localStorage.setItem('firstTimeInit', 'false'), 1000);

  const logo = getLogo();
  return (
    <div className={classes.splashStyle}>
      {logo ? (
        <CardMedia component="img" className={classes.logo} image={logo} title="Logo" />
      ) : (
        <Skeleton variant="rectangular" className={classes.logo} data-testid="skeleton" />
      )}
    </div>
  );
}
