"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatteryDataConverter = void 0;
const time_1 = require("../../../Util/time");
exports.BatteryDataConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { operationDate: (0, time_1.parseDateFromTimestamp)(data.operationDate), startTime: (0, time_1.parseDateFromTimestamp)(data.startTime), flightStartTime: (0, time_1.parseDateFromTimestamp)(data.flightStartTime), endTime: (0, time_1.parseDateFromTimestamp)(data.endTime) });
    },
    toFirestore: (data) => data
};
