import GlobalFeedback from 'flyid-ui-components/dist/layout/GlobalFeedback';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { handleAction } from 'src/redux/actions/actionsHandler';
import { MyDialogState, uiActions, updateUiNoReset } from 'src/redux/reducers/uiReducer';
import imageUnavailable from '../../assets/images/img_unavailable_white.png';

const GlobalFeedbackImpl: React.FC = () => {
  const dispatch = useAppDispatch();
  const { ui, acqPictures } = useAppSelector((s) => s);

  const onDialogCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateUiNoReset({
        dialog: MyDialogState.apply(ui.dialog, { checkboxState: e.target.checked })
      })
    );
  };

  return (
    <GlobalFeedback
      ui={ui}
      uiActions={uiActions}
      dispatch={dispatch}
      pictures={acqPictures}
      handleAction={handleAction}
      onDialogChange={onDialogCheckboxChange}
      imageUnavailable={imageUnavailable}
    />
  );
};

export default GlobalFeedbackImpl;
