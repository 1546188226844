// Components
import { Route, Switch } from 'react-router-dom';

// Pages
import ProtectedRoute from './components/utils/ProtectedRoute';

import EmailActionsHandler from './components/utils/EmailActionsHandler';

import Main from './components/dashboard/Main';

import Domain from './components/dashboard/Domain';
import SessionView from './components/dashboard/Session';
import CreateInventoryTask from './components/dashboard/Tasks/CreateInventoryTask';
import InventoryTasks from './components/dashboard/Tasks/InventoryTasks';
import SessionReview from './components/dashboard/SessionReview';
// import AllAddresses from './components/dashboard/AllAddresses';

import AddUser from './components/user/AddUser';
import ChangePassword from './components/user/ChangePassword';
import ForgotPassword from './components/user/ForgotPassword';
import Login from './components/user/Login';
import SetPassword from './components/user/SetPassword';
import UserProfile from './components/user/UserProfile';

import CreateApiKey from './components/management/apikeys/CreateApiKey';
import EditApiKeyAuthDomains from './components/management/apikeys/EditApiKeyAuthDomains';
import AddDomain from './components/management/domainsettings/AddDomain';
import DomainSettings from './components/management/domainsettings/DomainSettings';
import LicensesEditor from './components/management/domainsettings/licenses/LicensesEditor';
import ManageApiKeys from './components/management/ManageApiKeys';
import ManageDomains from './components/management/ManageDomains';
import ManageUsers from './components/management/ManageUsers';

import AddOrUpdateTabularData from './components/management/domainsettings/tabulardata/AddOrUpdateTabularData';
import AccuracyReport from './components/reports/AccuracyReport';

const Routes: React.FC = () => {
  return (
    <Switch>
      {/* Email actions handling routes */}
      <Route path="/emailActions" component={EmailActionsHandler} />
      {/* User related routes */}
      <Route path="/login" component={Login} />
      <Route path="/forgotpw" component={ForgotPassword} />

      {/* Dashboard routes */}
      {/* <ProtectedRoute path="/domains/:domain/all" component={AllAddresses} /> */}
      <ProtectedRoute
        path="/domains/:domain/sessions/:session/accuracyreport"
        component={AccuracyReport}
      />
      <ProtectedRoute path="/domains/:domain/sessions/:session/review" component={SessionReview} />
      <ProtectedRoute path="/domains/:domain/sessions/:session" component={SessionView} />
      <ProtectedRoute path="/domains/:domain/tasks/create" component={CreateInventoryTask} />
      <ProtectedRoute path="/domains/:domain/tasks" component={InventoryTasks} />
      <ProtectedRoute path="/domains/:domain" component={Domain} />

      {/* User related routes */}
      <ProtectedRoute path="/setpin" render={(props) => <SetPassword isPin={true} {...props} />} />
      <ProtectedRoute path="/setpw" render={(props) => <SetPassword isPin={false} {...props} />} />
      <ProtectedRoute
        path="/changepw"
        render={(props) => <ChangePassword isPin={false} {...props} />}
      />
      <ProtectedRoute
        path="/changepin"
        render={(props) => <ChangePassword isPin={true} {...props} />}
      />
      <ProtectedRoute exact path="/profile" component={UserProfile} />
      {/* Management routes (only for moderators */}
      <ProtectedRoute modOnly path="/manageusers" component={ManageUsers} />
      <ProtectedRoute modOnly path="/managedomains" component={ManageDomains} />
      <ProtectedRoute
        modOnly
        path="/managekeys/:apiKeyId/authDomains"
        component={EditApiKeyAuthDomains}
      />
      <ProtectedRoute modOnly path="/managekeys/create" component={CreateApiKey} />
      <ProtectedRoute modOnly path="/managekeys" component={ManageApiKeys} />
      <ProtectedRoute modOnly path="/addDomain" component={AddDomain} />
      <ProtectedRoute modOnly path="/adduser" component={AddUser} />
      <ProtectedRoute modOnly path="/domain/:domain/settings" component={DomainSettings} />
      <ProtectedRoute modOnly path="/domain/:domain/editLicenses" component={LicensesEditor} />
      <ProtectedRoute
        modOnly
        path="/domain/:domain/addOrUpdateTable"
        component={AddOrUpdateTabularData}
      />
      {/* <ProtectedRoute modOnly path="/ft" component={Test} /> */}
      <ProtectedRoute modOnly path="/profile/:uid" component={UserProfile} />
      {/* Main */}
      <ProtectedRoute exact={false} path="/" component={Main} />
    </Switch>
  );
};

export default Routes;
