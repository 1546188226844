import {
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
  FieldPath,
  Firestore,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QuerySnapshot,
  where
} from 'firebase/firestore';
import { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { getDomainSettingsCol } from 'flyid-core/dist/Util/database';
import { buildCollectionRef } from 'src/firebase/firestore';

// Domain Settings
export const settingsCollectionRef = (db: Firestore, company: string, domain: string) =>
  buildCollectionRef(getDomainSettingsCol(company, domain));

export const mostRecentSettingsDocQuery = (db: Firestore, company: string, domain: string) =>
  query(settingsCollectionRef(db, company, domain), orderBy('createdDate', 'desc'), limit(1));

export const closestSettingsDocQuery = (
  db: Firestore,
  company: string,
  domain: string,
  referenceTime: Date
) =>
  query(
    settingsCollectionRef(db, company, domain),
    where('createdDate', '<', referenceTime),
    orderBy('createdDate', 'desc'),
    limit(1)
  );

export const getMostRecentSettings = (db: Firestore, company: string, domain: string) =>
  getQuery(mostRecentSettingsDocQuery(db, company, domain)).then(checkSettingsSnapshot);

export const getClosestSettings = async (
  db: Firestore,
  company: string,
  domain: string,
  referenceTime: Date
) =>
  getQuery(closestSettingsDocQuery(db, company, domain, referenceTime)).then(checkSettingsSnapshot);

function checkSettingsSnapshot(querySnap: QuerySnapshot): DomainSettings | undefined {
  if (querySnap.empty) {
    return undefined;
  } else {
    const newestSettDoc = querySnap.docs[0];
    return newestSettDoc.exists() ? (newestSettDoc.data() as DomainSettings) : undefined;
  }
}

// Firestore utils
const getQuery = (q: Query<DocumentData>) => getDocs(q);

export function getSlicedWhereArrayOperandQuery(
  collectionRefOrQuery: CollectionReference | Query,
  propertyName: string | FieldPath,
  whereFilterOp: 'in' | 'not-in' | 'array-contains-any',
  array: (string | boolean | number)[]
) {
  const slices = Math.floor(array.length / 10) + (array.length % 10 > 0 ? 1 : 0);

  const queries: Array<Query> = [];
  for (let i = 0; i < slices; i++) {
    queries.push(
      query(
        collectionRefOrQuery,
        where(propertyName, whereFilterOp, array.slice(i * 10, (i + 1) * 10))
      )
    );
  }

  return queries;
}

export const getAndAccumulateQueries = async (queries: Query[]) => {
  return (await Promise.all(
    queries.map((_query) =>
      getDocs(_query).then((querySnap) =>
        !querySnap.empty
          ? (querySnap.docs
              .map((doc) => (doc.exists() ? doc : undefined))
              .filter((d) => Boolean(d)) as DocumentData[])
          : undefined
      )
    )
  ).then((docs) => docs.filter((d) => Boolean(d)).flat())) as DocumentSnapshot[];
};
