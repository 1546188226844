import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionData, ImageLoadWrapper, PictureState } from '../types/pictureTypes';

const PICTURE_GROUP_MAX_SIZE = 20;

const pictureSlice = createSlice({
  name: 'picture',
  initialState: {} as PictureState,
  reducers: {
    setPictureLoading: (state, action: PayloadAction<ActionData>) => {
      const picId = action.payload.id;

      if (Object.keys(state).length >= PICTURE_GROUP_MAX_SIZE) {
        // Reduce to half size when buffer is full, by deleting oldest pics first
        const oldestFirst = Object.entries(state).sort(
          ([, picA], [, picB]) => picA.loadedAt - picB.loadedAt
        );
        while (oldestFirst.length > PICTURE_GROUP_MAX_SIZE / 2) {
          delete state[oldestFirst[0][0]];
          oldestFirst.shift();
        }
      }

      const imageData = state[picId] || new ImageLoadWrapper();
      imageData.isLoading = true;

      state[picId] = imageData;
    },
    setPictureLoaded: (state, action: PayloadAction<ActionData>) => {
      const { src, alt, id } = action.payload;

      const imageData = state[id] || new ImageLoadWrapper();

      imageData.isLoading = false;
      imageData.isLoaded = true;
      imageData.src = src;
      imageData.alt = alt;
      imageData.loadedAt = new Date().getTime();

      state[id] = imageData;
    }
  }
});

export const { setPictureLoaded, setPictureLoading } = pictureSlice.actions;

export default pictureSlice.reducer;
