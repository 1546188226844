"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionConverter = exports.SessionRequiredKeys = exports.SessionEntries = exports.SessionKeys = exports.Session = exports.ReviewState = exports.PushStatus = void 0;
const time_1 = require("../../Util/time");
var PushStatus;
(function (PushStatus) {
    // Session is waiting to be pushed
    PushStatus["PENDING"] = "PENDING";
    // Session has been successfully pushed
    PushStatus["PUSHED"] = "PUSHED";
    // Push failed
    PushStatus["FAILED"] = "FAILED";
})(PushStatus = exports.PushStatus || (exports.PushStatus = {}));
var ReviewState;
(function (ReviewState) {
    // Session review can be done
    ReviewState["PENDING"] = "PENDING";
    // Session review alredy done
    ReviewState["COMPLETED"] = "COMPLETED";
    // Push failed,
    ReviewState["FAILED"] = "FAILED";
    // Session don't need to be reviewed
    ReviewState["NO_NEED"] = "NO_NEED";
})(ReviewState = exports.ReviewState || (exports.ReviewState = {}));
class Session {
    constructor(addresses) {
        this.wasTask = false;
        this.invalid = 0;
        this.startTime = new Date();
        this.endTime = new Date();
        this.pushStatus = PushStatus.PENDING;
        this.total = 0;
        this.empty = 0;
        this.reviewState = ReviewState.NO_NEED;
        this.addresses = addresses;
    }
}
exports.Session = Session;
exports.SessionKeys = Object.keys(new Session(''));
exports.SessionEntries = Object.entries(new Session(''));
exports.SessionRequiredKeys = [];
exports.SessionConverter = {
    fromFirestore: (snap) => {
        var _a, _b, _c;
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { 
            // Leave fallbacks for catastrophic cases
            startTime: (0, time_1.parseDateFromTimestamp)((_a = data.startTime) !== null && _a !== void 0 ? _a : 0), endTime: (0, time_1.parseDateFromTimestamp)((_c = (_b = data.endTime) !== null && _b !== void 0 ? _b : data.startTime) !== null && _c !== void 0 ? _c : new Date()) });
    },
    toFirestore: (data) => data
};
