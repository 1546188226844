import { releaseProxy, wrap } from 'comlink';
import { Session } from 'flyid-core/dist/Database/Models/Session';
import { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { useEffect, useMemo, useState } from 'react';
import { DecodedFile } from 'src/util/helpers/files';
import { GetSessionDataResult, ParsedTableData } from 'src/util/helpers/table';
import { Nilable } from 'tsdef';
import { TranslatableError, toTranslatableError } from './../util/locale';
import { DataWorker } from './dataWorker';

class TableInputFileState {
  isWorking = false;
  parsedTableOrError?: ParsedTableData | TranslatableError = undefined;
}
export function useTableInputFileParser(
  tableName: Nilable<string>,
  inputFile: Nilable<DecodedFile>,
  settings: Nilable<DomainSettings>
) {
  const [data, setData] = useState(new TableInputFileState());

  const { workerApi } = useWorker();
  useEffect(() => {
    const canWork = !!inputFile && !!settings;
    setData({ isWorking: canWork, parsedTableOrError: undefined });

    if (canWork) {
      // We're starting the calculation here
      workerApi
        .parseTabularDataInputFile(tableName, inputFile, settings)
        .then((parsedTable: ParsedTableData) => {
          setData({ isWorking: false, parsedTableOrError: parsedTable });
        })
        .catch((err: Error) => {
          setData({ isWorking: false, parsedTableOrError: toTranslatableError(err) });
        });
    }
  }, [workerApi, setData, inputFile, settings]);

  return data;
}

class SessionDataTypeState {
  isWorking = false;
  rowsAndColumnsOrError?: GetSessionDataResult | TranslatableError = undefined;
}
export function useSessionDataParser(
  sessionData: Session | undefined,
  settings: DomainSettings | undefined | null,
  showInputData: boolean,
  showBaseFields: boolean
) {
  // We'll want to expose a wrapping object so we know when parsing is in progress
  const [data, setData] = useState(new SessionDataTypeState());
  // Acquire our worker
  const { workerApi } = useWorker();

  useEffect(() => {
    // Do not start working if data is not available
    if (!sessionData || !settings) return;
    // We're starting the calculation here
    setData({ isWorking: true, rowsAndColumnsOrError: undefined });

    workerApi
      .getSessionData(sessionData, settings, { showInputData, showBaseFields })
      .then((rowsAndColumns) => {
        setData({ isWorking: false, rowsAndColumnsOrError: rowsAndColumns });
      })
      .catch((err: Error) => {
        setData({ isWorking: false, rowsAndColumnsOrError: toTranslatableError(err) });
      });
  }, [workerApi, setData, sessionData, settings, showInputData, showBaseFields]);

  return data;
}

// Without hooks
export async function getSessionData(
  sessionData: Session,
  settings: DomainSettings,
  showInputData: boolean,
  showBaseFields: boolean
) {
  const { workerApi, cleanup } = makeWorkerApiAndCleanup();

  return workerApi
    .getSessionData(sessionData, settings, { showInputData, showBaseFields })
    .then((rowsAndColumns) => {
      cleanup();
      return rowsAndColumns;
    })
    .catch((err) => {
      cleanup();
      throw err;
    });
}

export function getDeflated(data: pako.Data) {
  const { workerApi, cleanup } = makeWorkerApiAndCleanup();

  return workerApi
    .getDeflated(data)
    .then((deflatedData) => {
      cleanup();
      return deflatedData;
    })
    .catch((err) => {
      cleanup();
      throw err;
    });
}

export function getInflated(data: string) {
  const { workerApi, cleanup } = makeWorkerApiAndCleanup();

  return workerApi
    .getInflated(data)
    .then((inflatedData) => {
      cleanup();
      return inflatedData;
    })
    .catch((err) => {
      cleanup();
      throw err;
    });
}

// Internal
function useWorker() {
  // memoise a worker so it can be reused; create one worker up front
  // and then reuse it subsequently instead of creating new workers each time
  const workerApiAndCleanup = useMemo(() => makeWorkerApiAndCleanup(), []);

  useEffect(() => {
    // cleanup our worker when we're done with it
    return () => workerApiAndCleanup.cleanup();
  }, [workerApiAndCleanup]);

  return workerApiAndCleanup;
}

/** Creates a worker, a cleanup function and returns it */
function makeWorkerApiAndCleanup() {
  // Here we create our worker and wrap it with comlink so we can interact with it
  const worker = new Worker(new URL('./dataWorker.ts', import.meta.url));
  // Wrap worker with comlink to make api promise-like
  const workerApi = wrap<DataWorker>(worker);

  // A cleanup function that releases the comlink proxy and terminates the worker
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  return { workerApi, cleanup };
}
