import type Konva from 'konva';
import type { KonvaNodeComponent } from 'react-konva';

export const getPako = () => {
    // const start = performance.now()
    return import('pako')
        .then((pako) => {
            // console.log(`pako loaded! took ${performance.now() - start}`);
            return pako;
        })
}

export const getXlsx = () => {
    // const start = performance.now()
    return import('xlsx')
        .then((xlsx) => {
            // console.log(`xlsx loaded! took ${performance.now() - start}`);
            return xlsx;
        })
}

export const getKonva = () => {
    // const start = performance.now()
    return import('react-konva')
        .then((konva) => {
            // console.log(`konva loaded! took ${performance.now() - start}`);
            return konva;
        })
}

export const getKonvaComponent = <
    N extends Konva.Node,
    C extends Konva.NodeConfig = Konva.NodeConfig,
>(component: string) => {
    // const start = performance.now()
    return import('react-konva')
        .then((konva) => {
            // console.log(`konva.${component} loaded! took ${performance.now() - start}`);
            return { 'default': konva[component]! as unknown as KonvaNodeComponent<N, C> };
        })
}