import { immerable } from 'immer';

export type ActionData = {
  id: string;
  src?: string;
  alt?: string;
};

export class ImageLoadWrapper {
  // To allow usage of class instance in redux
  [immerable]? = true;

  isLoading = false;
  isLoaded = false;
  src?: string;
  alt?: string;
  loadedAt = 0; // load time ms
}

export type PictureState = { [key: string]: ImageLoadWrapper };
