import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const UnstyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',

  '&:focus, &:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
}));

export default (props) => <UnstyledLink {...props} />;
