"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCustomMarkerComplete = exports.CustomMarkerRequiredKeys = exports.CustomMarkerEntries = exports.CustomMarkerKeys = exports.CustomMarker = void 0;
const helpers_1 = require("../../../../Util/helpers");
/** Describes a custom marker input */
class CustomMarker {
    constructor() {
        this.name = '';
        this.allowedMarkers = [];
        this.isMandatory = false;
    }
}
exports.CustomMarker = CustomMarker;
exports.CustomMarkerKeys = Object.keys(new CustomMarker());
exports.CustomMarkerEntries = Object.entries(new CustomMarker());
exports.CustomMarkerRequiredKeys = ['name', 'allowedMarkers'];
/** Verifies if label data contains the minimum elements for correct functioning */
const isCustomMarkerComplete = (cm) => {
    var _a;
    const res = Boolean((cm === null || cm === void 0 ? void 0 : cm.name) && ((_a = cm.allowedMarkers) === null || _a === void 0 ? void 0 : _a.length) && (0, helpers_1.isNonNulli)(cm.isMandatory));
    //   if (!res) console.log(cm?.name, cm?.allowedMarkers, cm?.isMandatory);
    return res;
};
exports.isCustomMarkerComplete = isCustomMarkerComplete;
