"use strict";
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiState = exports.ImageViewerState = exports.BackdropState = exports.DialogState = exports.SnackbarState = void 0;
const immer_1 = require("immer");
class SnackbarState {
    constructor() {
        this[_a] = true;
        this.duration = 3000;
        this.severity = "error";
        this.show = false;
    }
}
exports.SnackbarState = SnackbarState;
_a = immer_1.immerable;
class DialogState {
    setConfirmAction(action, confirmActionData) {
        this.confirmAction = action;
        this.confirmActionData = confirmActionData;
        return this;
    }
    setCancelAction(action, cancelActionData) {
        this.cancelAction = action;
        this.cancelActionData = cancelActionData;
        return this;
    }
    constructor(data) {
        var _f, _g, _h, _j, _k, _l;
        this[_b] = true;
        this.title = "";
        this.message = "";
        this.checkboxMessage = "";
        this.useCheckbox = false;
        this.checkboxState = false;
        this.show = false;
        this.getConfirmAction = () => this.confirmAction;
        this.getCancelAction = () => this.cancelAction;
        this.getConfirmActionData = () => this.confirmActionData;
        this.getCancelActionData = () => this.cancelActionData;
        this.title = (_f = data.title) !== null && _f !== void 0 ? _f : "";
        this.message = (_g = data.message) !== null && _g !== void 0 ? _g : "";
        this.checkboxMessage = (_h = data.checkboxMessage) !== null && _h !== void 0 ? _h : "";
        this.useCheckbox = (_j = data.useCheckbox) !== null && _j !== void 0 ? _j : false;
        this.checkboxState = (_k = data.checkboxState) !== null && _k !== void 0 ? _k : false;
        this.show = (_l = data.show) !== null && _l !== void 0 ? _l : false;
    }
    // Applies changes to given dialog state and returns a new object
    static apply(old, newData) {
        const newDialogState = new DialogState(Object.assign(Object.assign({}, old), newData));
        let action = old.getConfirmAction();
        if (action)
            newDialogState.setConfirmAction(action, old.getConfirmActionData());
        action = old.getCancelAction();
        if (action)
            newDialogState.setCancelAction(action, old.getCancelActionData());
        return newDialogState;
    }
}
exports.DialogState = DialogState;
_b = immer_1.immerable;
class BackdropState {
    constructor() {
        this[_c] = true;
        this.show = false;
    }
}
exports.BackdropState = BackdropState;
_c = immer_1.immerable;
class ImageViewerState {
    constructor() {
        this[_d] = true;
        this.show = false;
    }
}
exports.ImageViewerState = ImageViewerState;
_d = immer_1.immerable;
class UiState {
    constructor(initialLoadingButtonsState) {
        this[_e] = true;
        this.snackbar = new SnackbarState();
        this.dialog = new DialogState({});
        this.backdrop = new BackdropState();
        this.imageViewer = new ImageViewerState();
        this.loadingButton = Object.assign({}, initialLoadingButtonsState);
    }
}
exports.UiState = UiState;
_e = immer_1.immerable;
