import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { LogicalBlock } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalBlock';
import { LogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import { cloneDeep } from 'lodash';
import React, { FormEvent } from 'react';
import { useIntl } from 'react-intl';
import useStateReducer from 'src/hooks/useStateReducer';
import { appMakeStyles } from 'src/theme/theme';
import { EditorProps } from 'src/util/processFlow/types';

const useStyles = appMakeStyles(({ resizableContainer, spacing, other }) => ({
  container: {
    ...resizableContainer(1),
    marginLeft: 0,
    maxWidth: '1000px'
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  formControl: {
    minWidth: '100%'
  },
  name: {
    margin: spacing(0, 4, 0, 0)
  }
}));

const LogicalBlockEditor: React.FC<EditorProps<LogicalBlock>> = (props) => {
  const { $t } = useIntl();
  const classes = useStyles();

  const [logicalBlockData, setLogicalBlockData] = useStateReducer(props.data);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogicalBlockData({ ...logicalBlockData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onSave(cloneDeep(logicalBlockData));
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {$t({ id: 'LogicalBlock.title' })}
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Name, checkfield, inputType | action, Max attempts */}
        <Grid container alignItems="center">
          <Grid item xs>
            <TextField
              name="name"
              id="name"
              type="text"
              className={classes.name}
              label={`${$t({ id: 'logicalBlock.name' })} *`}
              value={logicalBlockData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl} margin="normal">
              <FormLabel id="radio-buttons">{$t({ id: 'lbe.logicOperation' })}</FormLabel>
              <RadioGroup
                row
                name="operation"
                aria-labelledby="radio-buttons"
                value={logicalBlockData.operation}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={LogicalOperator.OR}
                  control={<Radio />}
                  label={$t({ id: `logic.${LogicalOperator.OR}` })}
                />
                <FormControlLabel
                  value={LogicalOperator.AND}
                  control={<Radio />}
                  label={$t({ id: `logic.${LogicalOperator.AND}` })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs />
          <Grid item xs="auto" sx={{ mt: 1 }}>
            <Button variant="contained" color="secondary" type="submit">
              {$t({ id: 'saveChanges' })}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default LogicalBlockEditor;
