import { cx } from '@emotion/css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Box, IconButton, lighten, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Handle, NodeProps, Position, useUpdateNodeInternals } from '@xyflow/react';
import { getType, HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { Rotation } from 'flyid-core/dist/Util/geometry';
import { isNonNulli } from 'flyid-core/dist/Util/helpers';
import ConditionalWrapper from 'flyid-ui-components/dist/utils/ConditionalWrapper';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { TooltipOppositePosition } from 'src/util/helpers/geometry';
import { isNodeTypeCopyable } from 'src/util/processFlow/node';
import { BaseNodeData } from 'src/util/processFlow/types';

type SvgIconType = typeof SvgIcon;

const useStyles = appMakeStyles(
  ({ spacing, palette, reactFlow: { inputHandle, outputHandle } }) => ({
    root: {
      padding: spacing(1),
      color: palette.primary.dark,
      backgroundColor: palette.grey[200],
      borderColor: palette.primary.dark,
      borderRadius: spacing(1),
      borderStyle: 'solid',
      borderWidth: '2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    widgetsContainer: {
      borderRadius: spacing(1),
      backgroundColor: '#7777',
      position: 'absolute',
      minWidth: 'calc(100% - 1px)',
      height: 'calc(100% - 1px)',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    editButton: {
      margin: spacing(0.5),
      backgroundColor: palette.secondary.main,
      '&:hover': {
        backgroundColor: lighten(palette.secondary.main, 0.3)
      }
    },
    rotateButton: {
      margin: spacing(0.5),
      backgroundColor: palette.info.main,
      '&:hover': {
        backgroundColor: lighten(palette.info.main, 0.3)
      }
    },
    dragButton: {
      margin: spacing(0.5),
      backgroundColor: '#0002',
      '&:hover': {
        backgroundColor: '#0003'
      }
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: palette.grey[200],
      color: palette.primary.dark
    },
    underlinedBox: {
      width: '100%',
      borderBottom: 'solid thin',
      marginBottom: spacing(1)
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    icon: { width: spacing(3), height: spacing(3) },
    inputHandle: inputHandle(1.5, 1.5),
    outputHandle: outputHandle(1.5, 1.5),
    wrapButtonsContainer: {
      flex: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  })
);

// Content description
export type Content = {
  // Intl title id
  titleId?: string;
  iconStart?: SvgIconType;
  iconEnd?: SvgIconType;
  description?: string;
  /** If 'complexLayout' is used, ignore all other params */
  complexLayout?: (inputPos: Position) => JSX.Element;
};

export type Handles = {
  inputHandles?: HandleType[];
  inputHandleDescriptors?: JSX.Element[];
  outputHandles?: HandleType[];
  outputHandleDescriptors?: JSX.Element[];
};

type Props = BaseNodeData &
  Partial<NodeProps> & {
    content: Content;
    handles?: Handles;
    autoHandle?: boolean;
    wrapButtons?: boolean;
    parent?: string;
  };

const StylesByPos: Record<Position, [string, string]> = {
  [Position.Top]: ['left', 'top'],
  [Position.Bottom]: ['left', 'bottom'],
  [Position.Left]: ['top', 'left'],
  [Position.Right]: ['top', 'right']
};

/* Returns positioning style per handle position and index/count */
const getHandleStyle = (index: number, count: number, pos: Position): React.CSSProperties => {
  const styles = StylesByPos[pos];
  let percent = 100 / count;
  percent = percent * (1 + index) - percent / 2;
  return {
    [styles[0]]: `${percent}%`,
    [styles[1]]: 0,
    pointerEvents: 'auto'
  };
};

const initialHandlePosition = { input: Position.Left, output: Position.Right };

const getHandlePositions = (r: Rotation) => {
  switch (r.state) {
    case Rotation.State.NONE:
      return { ...initialHandlePosition };
    case Rotation.State.ONE_QUARTER_CW:
      return { input: Position.Top, output: Position.Bottom };
    case Rotation.State.TWO_QUARTERS_CW:
      return { input: Position.Right, output: Position.Left };
    case Rotation.State.THREE_QUARTERS_CW:
      return { input: Position.Bottom, output: Position.Top };
  }
};

/**
 * This component is not really a node, but the styled box that will render node content
 */
const BaseNode: React.FC<Props> = (props) => {
  const { content, handles, id, parent, rotation: propsRotation } = props;
  const rotation = useMemo(() => new Rotation(propsRotation), []);

  const classes = useStyles();
  const { palette, spacing } = useAppTheme();
  const { $t } = useIntl();

  const widgetsRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [largestChildWidth, setLargestChildWidth] = useState<number | undefined>(undefined);
  const [mouseOver, setMouseOver] = useState(false);
  const [isTimeToCheckAgain, setIsTimeToCheckAgain] = useState(true);
  const [handlePosition, setHandlePosition] = useState({ ...getHandlePositions(rotation) });

  const { setNodes } = useAppReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  const { isConnecting, connectionParams, isCtrlPressed } = useAppSelector((s) => s.processFlow);

  const StartIcon = content.iconStart;
  const EndIcon = content.iconEnd;

  // const onMouseMove = useCallback(
  //   (e: React.MouseEvent) => {
  //     if (
  //       id &&
  //       isConnecting &&
  //       connectionParams &&
  //       connectionParams!.nodeId !== id &&
  //       isTimeToCheckAgain
  //     ) {
  //       setIsTimeToCheckAgain(false);

  //       const b = e.currentTarget.getBoundingClientRect();
  //       const actionArea = getMouseActionArea(e.clientX - b.x, e.clientY - b.y, b.width, b.height);

  //       let input: Position, output: Position;
  //       // ReactFlow HandleType
  //       if (connectionParams.handleType === 'source') {
  //         input = PositionByBoxActionArea[actionArea];
  //         output = OppositePosition[input];
  //       } else {
  //         output = PositionByBoxActionArea[actionArea];
  //         input = OppositePosition[output];
  //       }

  //       setHandlePosition({ input, output });
  //     }
  //   },
  //   [isConnecting, id, connectionParams, isTimeToCheckAgain]
  // );

  const getInputHandleStyle = useCallback(
    (index: number) =>
      getHandleStyle(index, handles?.inputHandles?.length ?? 0, handlePosition.input),
    [handles, handlePosition]
  );

  const getOutputHandleStyle = useCallback(
    (idx: number) =>
      getHandleStyle(idx, handles?.outputHandles?.length ?? 0, handlePosition.output),
    [handles, handlePosition]
  );

  useLayoutEffect(() => {
    if (!props.autoHandle && widgetsRef.current && contentRef.current) {
      setLargestChildWidth(
        Math.max(widgetsRef.current.clientWidth, contentRef.current.clientWidth)
      );
    }
  }, [widgetsRef.current, contentRef.current, content.description]);

  const updateCommonNodeDataRotation = useCallback(
    (thisNodeId: string, _rotation: Rotation.State) => {
      setNodes((ns) =>
        ns.map((n) => {
          if (n.id === thisNodeId) {
            n = cloneDeep(n);
            n.data.baseNodeData.rotation = _rotation;
          }
          return n;
        })
      );
    },
    [setNodes]
  );

  const onRotateClockwise = useCallback(() => {
    rotation.rotateClockwise();
    updateCommonNodeDataRotation(props.id!, rotation.state);
  }, []);

  const onRotateCounterClockwise = useCallback(() => {
    rotation.rotateCounterClockwise();
    updateCommonNodeDataRotation(props.id!, rotation.state);
  }, []);

  useEffect(() => {
    if (isNonNulli(propsRotation)) {
      rotation.state = propsRotation;
      setHandlePosition(getHandlePositions(rotation));
    }
  }, [propsRotation]);

  useEffect(() => {
    id && updateNodeInternals(id);
    setTimeout(() => setIsTimeToCheckAgain(true), 500);
  }, [handlePosition]);

  useEffect(() => {
    setMouseOver(false);
  }, [parent]);

  // useEffect(() => console.log(`finished rendering ${id || ''}'s BaseNode`));

  const maxHandleSize = Math.max(
    handles?.inputHandles?.length ?? 0,
    handles?.outputHandles?.length ?? 0
  );

  const hideDetach = Boolean(parent && props.hideDetach);
  const showDetach = Boolean(parent && props.onDetachFromParent && !hideDetach);
  const showDrag = !parent && !hideDetach;

  const showCopyButton = isNodeTypeCopyable(getType(props.id)) && isCtrlPressed;
  const MultipleFunButton = showCopyButton
    ? ContentCopyIcon // Copy button
    : parent
      ? OpenWithIcon // Detach button
      : DragHandleIcon; // Drag button

  return (
    <Box
      className={classes.root}
      sx={{
        boxShadow: props.selected ? `0 0 1px .5px ${palette.primary.dark}` : undefined,
        width: largestChildWidth ? `${largestChildWidth + 4}px` : 'fit-content',
        minHeight: spacing(maxHandleSize * 3)
      }}
      onMouseEnter={() => setMouseOver(true)}
      // onMouseMove={props.autoHandle ? onMouseMove : undefined}
      onMouseLeave={() => setMouseOver(false)}
    >
      {/* Content & Widgets for common nodes */}
      <Box>
        {/* Content */}
        {content.complexLayout?.(handlePosition.input) ?? (
          <Box className={classes.contentContainer} ref={contentRef}>
            {content.titleId && (
              <>
                <Typography noWrap>{$t({ id: content.titleId })}</Typography>
                <Box className={classes.underlinedBox} />
              </>
            )}
            <Box className={classes.content}>
              {StartIcon && (
                <StartIcon
                  className={classes.icon}
                  sx={content.description ? { mr: 1 } : undefined}
                />
              )}
              {content.description && (
                <Typography noWrap variant="body1">
                  {content.description}
                </Typography>
              )}
              {EndIcon && (
                <EndIcon
                  className={classes.icon}
                  sx={content.description ? { ml: 1 } : undefined}
                />
              )}
            </Box>
          </Box>
        )}

        {/* Widgets */}
        <Box
          className={classes.widgetsContainer}
          sx={{
            visibility: mouseOver ? 'visible' : 'hidden',
            flexWrap: props.wrapButtons ? 'wrap' : 'nowrap',
            alignContent: props.wrapButtons ? 'center' : 'space-around'
          }}
          ref={widgetsRef}
        >
          {!props.autoHandle ? (
            <ConditionalWrapper
              key={`BN-Widgets-CW`}
              condition={Boolean(props.wrapButtons)}
              wrapper={(children) => <Box className={classes.wrapButtonsContainer}>{children}</Box>}
            >
              {!parent ? (
                <>
                  <Tooltip title={$t({ id: 'rotate' })}>
                    <IconButton
                      className={classes.rotateButton}
                      size="small"
                      disableRipple
                      onClick={onRotateCounterClockwise}
                    >
                      <RotateLeftIcon sx={{ color: palette.common.white }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={$t({ id: 'rotate' })}>
                    <IconButton
                      className={classes.rotateButton}
                      size="small"
                      disableRipple
                      onClick={onRotateClockwise}
                    >
                      <RotateRightIcon sx={{ color: palette.common.white }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </ConditionalWrapper>
          ) : null}
          <ConditionalWrapper
            key={'BN-CW-Edit'}
            condition={Boolean(props.wrapButtons)}
            wrapper={(children) => <Box className={classes.wrapButtonsContainer}>{children}</Box>}
          >
            <>
              {props.onEditClick && (
                <Tooltip title={$t({ id: 'edit' })}>
                  <IconButton
                    className={classes.editButton}
                    onClick={props.onEditClick}
                    size="small"
                  >
                    <EditIcon sx={{ color: palette.common.white }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {/* Drag or detach button */}
              {showDetach || showDrag ? (
                <Tooltip title={$t({ id: showCopyButton ? 'copy' : parent ? 'detach' : 'drag' })}>
                  <IconButton
                    className={cx(
                      classes.dragButton,
                      !parent && !showCopyButton ? 'custom-drag-handle' : undefined
                    )}
                    size="small"
                    onClick={
                      showCopyButton
                        ? props.onNodeCopy
                        : parent
                          ? () => props.onDetachFromParent?.(parent)
                          : undefined
                    }
                    disableRipple={!parent}
                  >
                    <MultipleFunButton sx={{ color: palette.common.white }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          </ConditionalWrapper>
        </Box>
      </Box>

      {/* Handles */}
      {handles?.inputHandles?.map((type, index) => (
        <Handle
          key={`target${index}`}
          type="target"
          id={`${type}_${index}`}
          style={getInputHandleStyle(index)}
          position={handlePosition.input}
          className={classes.inputHandle}
        />
      ))}

      {handles?.outputHandles?.map((type, index) => {
        const descriptor = handles?.outputHandleDescriptors?.[index];
        const hasDescriptor = descriptor !== undefined;
        return (
          <ConditionalWrapper
            key={`BN-Handle-CW-${type}-${index}`}
            condition={hasDescriptor}
            wrapper={(children) =>
              children && (
                <Tooltip
                  placement={TooltipOppositePosition[handlePosition.output]}
                  arrow
                  sx={{ ml: 'auto' }}
                  title={<Typography variant="subtitle2">{descriptor}</Typography>}
                >
                  {children}
                </Tooltip>
              )
            }
          >
            <Handle
              key={`source${index}`}
              type="source"
              position={handlePosition.output}
              id={`${type}_${index}`}
              className={classes.outputHandle}
              style={getOutputHandleStyle(index)}
              // onConnect={(c) => console.log(c)}
            />
          </ConditionalWrapper>
        );
      })}
    </Box>
  );
};

export default BaseNode;
