import {
  BarcodeField,
  isBarcodeFieldComplete
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/BarcodeField';
import { isBarcodePatternComplete } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/BarcodePattern';
import {
  buildCase,
  Case,
  CaseData,
  CaseTypes,
  isCaseComplete
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import {
  CustomMarker,
  isCustomMarkerComplete
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';
import { getPureId } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import {
  isLabelDesignComplete,
  LabelDesign
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import { LogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import {
  isMifComplete,
  ManualInputField
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import {
  isPictureTakingComplete,
  PictureTaking
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/PictureTaking';
import { TwoWayMap } from 'flyid-core/dist/Util/structs';
import React, { ReactElement } from 'react';
import { IntlShape } from 'react-intl';
import { LogicalBlockParent, TypedNode } from './types';

export const buildCaseDescription = (
  intl: IntlShape,
  targetField: string,
  targetNodeName: string,
  type: CaseTypes,
  isNot = false
) => {
  if (type === CaseTypes.else) return intl.$t({ id: 'else' }).toUpperCase();

  let usePast = false;
  let targetDescription: string;
  switch (type) {
    case CaseTypes.fieldEqualsExpected:
      targetDescription =
        targetNodeName + (targetField ? (targetNodeName ? ': ' : '') + targetField : '');
      break;
    case CaseTypes.hasFlag:
    // @ts-expect-error this fallthrough is meant to be
    case CaseTypes.isPicTaken:
      usePast = true;
    default:
      targetDescription = targetField;
  }

  const verbToBe = isNot ? (usePast ? 'wasNot' : 'isNot') : usePast ? 'was' : 'is';

  const msg =
    targetDescription +
    ` ${intl.$t(
      { id: `conditional.${verbToBe}` },
      { type: intl.$t({ id: `conditional.${type}` }) }
    )}`;
  return msg;
};

export const buildOperatorDescription = (
  intl: IntlShape,
  cond: LogicalOperator,
  index: number | string = 0,
  bold = false
) => {
  const boldKey = Math.random().toString().substring(0, 10);
  return intl.$t(
    { id: `logic.bold.${cond}` },
    {
      bold: (s) =>
        bold ? React.createElement('b', { key: `${boldKey}${index}` } as ReactElement, s)! : s
    }
  );
};

export const buildCaseDataDescription = (
  intl: IntlShape,
  nodeNamesById: Record<string, string>,
  data: CaseData
) =>
  data.map((caseOrOp, i) =>
    isCaseComplete(caseOrOp)
      ? buildCaseDescription(
          intl,
          caseOrOp.target,
          nodeNamesById[caseOrOp.targetNodeId] ?? '',
          caseOrOp.type,
          caseOrOp.isNot
        )
      : buildOperatorDescription(intl, caseOrOp, i, true)
  );

export const buildElseCase = (availableCases: Map<string, Case>, intl: IntlShape): string => {
  const elseCaseDescriptor = buildCaseDescription(intl, '', '', CaseTypes.else);
  availableCases.set(elseCaseDescriptor, getElseCase());
  return elseCaseDescriptor;
};

/**
 * Build label design cases -> Nullables, Invalidables (all) and those which use checkFields
 * and adds it to {@link availableCases} and {@link oppositeMap}
 */
export const buildCasesForLabelDesigns = (
  availableCases: Map<string, Case>,
  oppositeMap: TwoWayMap<string, string>,
  intl: IntlShape,
  labels: TypedNode<LabelDesign>[]
): void => {
  const directMap = new Map<string, string>();

  // Filter valid labels (user may have added a label but it is incomplete)
  const completeLabels = labels.filter((n) => isLabelDesignComplete(n.data.specificData));

  // Invalidable labels
  completeLabels.forEach((n) =>
    buildOptions(
      availableCases,
      directMap,
      intl,
      '',
      n.id,
      n.data.specificData!.name,
      CaseTypes.isInvalid
    )
  );
  // Nullable fields
  completeLabels.forEach((n) =>
    n.data.specificData?.barcodePatterns
      ?.filter((bp) => !bp.isRequired)
      .forEach((bp) =>
        bp.dataFields.forEach((df) =>
          buildOptions(availableCases, directMap, intl, '', n.id, df.name, CaseTypes.isNull)
        )
      )
  );
  // Fields with checkFields
  completeLabels
    .map((n) => [
      n.data.specificData?.name,
      n.id,
      n.data.specificData?.barcodePatterns
        ?.filter((bp) => isBarcodePatternComplete(bp))
        .flatMap((barcodePattern) =>
          barcodePattern.dataFields.filter((bf) => isBarcodeFieldComplete(bf) && bf.useCheckField)
        )
    ])
    .filter((p): p is [string, string, BarcodeField[]] => Boolean(p))
    .forEach(([labelName, nodeId, barcodeFields]) =>
      barcodeFields.forEach((bf) =>
        buildOptions(
          availableCases,
          directMap,
          intl,
          labelName,
          nodeId,
          bf.name,
          CaseTypes.fieldEqualsExpected
        )
      )
    );
  // Add opposites
  oppositeMap.addData(directMap);
};

/**
 * Build logical block cases (currently only used for *OR* logical block):
 * * LogicalBlockParents: Invalidables (when *OR*)
 * * LabelDesign: Nullables + those which use checkFields.
 *
 * Adds it to {@link availableCases} and {@link oppositeMap}
 */
export const buildCasesForLogicalBlockParents = (
  availableCases: Map<string, Case>,
  oppositeMap: TwoWayMap<string, string>,
  intl: IntlShape,
  labelsByParent: { [parent: string]: [TypedNode<LogicalBlockParent>, TypedNode<LabelDesign>[]] }
): void => {
  const directMap = new Map<string, string>();

  Object.entries(labelsByParent).forEach(([parentId, [parentNode, ldns]]) => {
    // Invalidable logical blocks
    buildOptions(
      availableCases,
      directMap,
      intl,
      '',
      parentId,
      parentNode.data.specificData!.name,
      CaseTypes.isInvalid
    );

    // Filter valid labels (user may have added a label but it is incomplete)
    const completeLabels = ldns.filter((n) => isLabelDesignComplete(n.data.specificData));
    // Nullable fields
    completeLabels.forEach((n) =>
      n.data.specificData?.barcodePatterns
        ?.filter((bp) => !bp.isRequired)
        .forEach((bp) =>
          bp.dataFields.forEach((df) =>
            buildOptions(availableCases, directMap, intl, '', n.id, df.name, CaseTypes.isNull)
          )
        )
    );
    // Fields with checkFields
    completeLabels
      .map((n) => [
        n.data.specificData?.name,
        n.id,
        n.data.specificData?.barcodePatterns
          ?.filter((bp) => isBarcodePatternComplete(bp))
          .flatMap((barcodePattern) =>
            barcodePattern.dataFields.filter((bf) => isBarcodeFieldComplete(bf) && bf.useCheckField)
          )
      ])
      .filter((p): p is [string, string, BarcodeField[]] => Boolean(p))
      .forEach((p) =>
        p[2].forEach((bf) =>
          buildOptions(
            availableCases,
            directMap,
            intl,
            p[0],
            p[1],
            bf.name,
            CaseTypes.fieldEqualsExpected
          )
        )
      );
    // Add opposites
    oppositeMap.addData(directMap);
  });
};

/**
 * Build MIF cases -> Nullables and those which use checkFields
 * and adds it to {@link availableCases} and {@link oppositeMap}
 */
export const buildCasesForMIFs = (
  availableCases: Map<string, Case>,
  oppositeMap: TwoWayMap<string, string>,
  intl: IntlShape,
  mifs: TypedNode<ManualInputField>[]
) => {
  const directMap = new Map<string, string>();

  const completeMifNodes: TypedNode<ManualInputField>[] = mifs.filter((n) =>
    isMifComplete(n.data.specificData)
  );
  // Nullable MIFs
  completeMifNodes
    .filter((n) => !n.data.specificData?.mandatory)
    .forEach((n) =>
      buildOptions(
        availableCases,
        directMap,
        intl,
        '',
        n.id,
        n.data.specificData!.field,
        CaseTypes.isNull
      )
    );
  // MIFs with checkFields
  completeMifNodes
    .filter((n) => n.data.specificData?.useCheckField)
    .forEach((n) =>
      buildOptions(
        availableCases,
        directMap,
        intl,
        '',
        n.id,
        n.data.specificData!.field,
        CaseTypes.fieldEqualsExpected
      )
    );
  // Add opposites
  oppositeMap.addData(directMap);
};

/**
 * Get picture taking cases -> Non-mandatory pictures
 * and adds it to {@link availableCases} and {@link oppositeMap}
 */
export const buildCasesForPictureTaking = (
  availableCases: Map<string, Case>,
  oppositeMap: TwoWayMap<string, string>,
  intl: IntlShape,
  pictureTakings: TypedNode<PictureTaking>[]
) => {
  const directMap = new Map<string, string>();
  // PictureTaking has pic been taken?
  pictureTakings
    .filter((n) => isPictureTakingComplete(n.data.specificData) && !n.data.specificData.isMandatory)
    .forEach((n) =>
      buildOptions(
        availableCases,
        directMap,
        intl,
        n.data.specificData!.name,
        n.id,
        '',
        CaseTypes.isPicTaken
      )
    );
  // Add opposites
  oppositeMap.addData(directMap);
};

/**
 * Get picture taking cases -> Non-mandatory pictures
 * and adds it to {@link availableCases} and {@link oppositeMap}
 */
export const buildCasesForCustomMarkers = (
  availableCases: Map<string, Case>,
  oppositeMap: TwoWayMap<string, string>,
  intl: IntlShape,
  pictureTakings: TypedNode<CustomMarker>[]
) => {
  const directMap = new Map<string, string>();
  // PictureTaking has pic been taken?
  pictureTakings
    .filter((n) => isCustomMarkerComplete(n.data.specificData))
    .forEach((n) =>
      n.data.specificData?.allowedMarkers.forEach((marker) =>
        buildOptions(
          availableCases,
          directMap,
          intl,
          n.data.specificData!.name,
          n.id,
          marker,
          CaseTypes.hasFlag
        )
      )
    );
  // Add opposites
  oppositeMap.addData(directMap);
};

/**
 * Builds maps with case descriptions to Case objects and cases to opposites, for use
 * in selectors and settings building and adds it to {@link availableCases} and {@link oppositeMap}
 *
 * @param availableCases Map of case description to case of all available cases to be filled
 * @param oppositeMap Map of case to its opposite to be filled
 * @param intl Intl instance
 * @param targetField Target name
 * @param type Case type
 */
const buildOptions = (
  availableCases: Map<string, Case>,
  oppositeMap: Map<string, string> | null,
  intl: IntlShape,
  targetNodeName: string,
  targetNodeId: string,
  targetField: string,
  type: CaseTypes
) => {
  const isCondition = buildCaseDescription(intl, targetField, targetNodeName, type);
  const isNotCondition = buildCaseDescription(intl, targetField, targetNodeName, type, true);

  const _case = buildCase(targetField, getPureId(targetNodeId) ?? targetNodeId, type, false);

  availableCases.set(isCondition, _case);
  availableCases.set(isNotCondition, { ..._case, isNot: true });
  oppositeMap?.set(isCondition, isNotCondition);
};

export function getElseCase(): Case {
  return {
    isNot: false,
    type: CaseTypes.else,
    target: '',
    targetNodeId: ''
  };
}
