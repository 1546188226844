import { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { Task } from 'flyid-core/dist/Database/Models/Task';

export type TaskParserResult = { [taskName: string]: ParsedTask };
type ParsedTask = Omit<Task, 'createdDate' | 'name' | 'state'>;

/**
 * @param {*} settings Domain settings
 * @returns {expectedDataColumns, expectedBarcodeCheckFields, expectedMifCheckFields}
 */
export function getExpectedColumns(settings: DomainSettings) {
  // Collect expected fields from settings
  const expectedDataColumns = new Set<string>();
  const expectedBarcodeCheckFields = new Set<string>();
  for (const label of Object.values(settings.processFlow.labelDesigns)) {
    for (const bcp of label.barcodePatterns) {
      for (const field of bcp.dataFields) {
        if (field.type === 'data') {
          expectedDataColumns.add(field.name);
          if (field.useCheckField) {
            expectedBarcodeCheckFields.add(field.checkField);
          }
        }
      }
    }
  }

  const expectedMifCheckFields = new Set<string>();
  for (const mif of Object.values(settings.processFlow.manualInputFields)) {
    expectedDataColumns.add(mif.field);
    if (mif.useCheckField) {
      expectedMifCheckFields.add(mif.checkField);
    }
  }
  return { expectedDataColumns, expectedBarcodeCheckFields, expectedMifCheckFields };
}

/**
 * Returns a summary of the addresses contained in the given task data
 *
 * @param {object} parsedInputData
 * @param {boolean} vertical
 */
export function getTaskExampleAddresses(parsedTask: ParsedTask, vertical = false) {
  const joiner = vertical ? ', \n' : ', ';
  const dot = vertical ? '.\n' : '.';

  const exampleAddressesInput = parsedTask.exampleAddresses;

  let exampleAddresses = '[';
  // let exampleAddresses = '';
  if (parsedTask.addressesCount > exampleAddressesInput.count) {
    exampleAddresses += exampleAddressesInput.start.join(joiner);
    exampleAddresses += [joiner, dot, dot, dot, joiner].join('');
    exampleAddresses += exampleAddressesInput.end.join(joiner);
  } else {
    exampleAddresses += [...exampleAddressesInput.start, exampleAddressesInput.end].join(joiner);
  }
  exampleAddresses += ']';

  return exampleAddresses;
}
