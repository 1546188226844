import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useAuth } from 'src/hooks/useAuth';
import Splash from './Splash';

const LandingLoading: React.FC = ({ children }) => {
  const { loading: authLoading } = useAuth();
  return JSON.parse(localStorage.getItem('firstTimeInit') || 'false') ? (
    <TransitionGroup>
      <CSSTransition
        key={`lltransition${String(authLoading)}`}
        timeout={{ enter: 1000, exit: 500 }}
        classNames={'fade'}
      >
        {authLoading ? <>{children}</> : <Splash />}
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <>{children}</>
  );
};

export default LandingLoading;
