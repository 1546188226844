import { UserPublic } from 'flyid-core/dist/Database/Models';
import { isNulli } from 'flyid-core/dist/Util/helpers';
import { isKeyUserProf } from 'src/util/helpers/user';
import { AppState } from '../store';

/** Selects the target company, which is possibly a global when current user is key user */
export const selectTargetCompany = (state: AppState) => {
  const profile = state.user.profile;
  if (isNulli(profile)) return undefined;

  return isKeyUserProf(profile) ? state.globals.targetCompany : (profile as UserPublic).company;
};

/** Selects the global target parent. Is only available when current user is key user */
export const selectTargetParentUid = (state: AppState) => {
  const profile = state.user.profile;
  if (isNulli(profile)) return undefined;

  return isKeyUserProf(profile) ? state.globals.targetParentUid : undefined;
};
