"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pidExtraTheme = exports.pidPalette = void 0;
exports.pidPalette = {
    background: {
        default: "#FAFAFA"
    },
    primary: {
        light: "#8C5AC2",
        main: "#5B2E91",
        dark: "#2B0062",
        contrastText: "#FFFFFF"
    },
    secondary: {
        light: "#9EF062",
        main: "#6BBD30",
        dark: "#368C00",
        contrastText: "#FFFFFF"
    }
};
exports.pidExtraTheme = {
    other: {
        grey: {
            light: "#AAAAAA",
            main: "#777777",
            dark: "#444444",
            contrastText: "#6B5B95"
        },
        white: "#FFFFFF",
        transparent: "#00000000"
    }
};
