"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIKeyConverter = exports.APIKeyKeys = exports.APIKey = exports.APIKeyStatus = void 0;
const time_1 = require("../../Util/time");
var APIKeyStatus;
(function (APIKeyStatus) {
    APIKeyStatus["INVALID"] = "INVALID";
    APIKeyStatus["ACTIVE"] = "ACTIVE";
})(APIKeyStatus = exports.APIKeyStatus || (exports.APIKeyStatus = {}));
class APIKey {
    constructor() {
        this.token = '';
        this.company = '';
        this.parentUid = '';
        this.description = '';
        this.authDomains = [];
        this.status = APIKeyStatus.INVALID;
        this.creationDate = new Date();
    }
}
exports.APIKey = APIKey;
exports.APIKeyKeys = Object.keys(new APIKey());
exports.APIKeyConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { creationDate: (0, time_1.parseDateFromTimestamp)(data.creationDate) });
    },
    toFirestore: (data) => data
};
