"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPrivateConverter = exports.MaybeKeyUserPublicConverter = exports.KeyUserPublicConverter = exports.UserPublicConverter = exports.MobileUserDataKeys = exports.MobileUserData = exports.UserKeys = exports.User = exports.UserPublicKeys = exports.UserPublic = exports.UserCommonKeys = exports.UserCommon = exports.PermissionsKeys = exports.Permissions = exports.UserPrivateKeys = exports.UserPrivate = exports.UserModeratorKeys = exports.UserModerator = exports.TestUser = void 0;
const firestore_1 = require("../../Util/firestore");
const helpers_1 = require("../../Util/helpers");
class TestUser {
}
exports.TestUser = TestUser;
class UserModerator {
    // Allow any number of named parameters to be passed inside object
    constructor(data) {
        this.authLicenses = [];
        if (data) {
            Object.assign(this, data);
        }
    }
}
exports.UserModerator = UserModerator;
exports.UserModeratorKeys = Object.keys(new UserModerator());
class UserPrivate {
    constructor() {
        this.hashedPassword = '';
        this.salt = '';
    }
}
exports.UserPrivate = UserPrivate;
exports.UserPrivateKeys = Object.keys(new UserPrivate());
class Permissions {
    constructor() {
        this.pilot = false;
        this.checker = false;
        this.assistant = false;
        this.moderator = false;
    }
}
exports.Permissions = Permissions;
exports.PermissionsKeys = Object.keys(new Permissions());
class UserCommon {
    constructor() {
        this.company = '';
        this.email = '';
        this.pwUpdated = false;
        this.firstName = '';
        this.lastName = '';
        this.employeeId = '';
    }
}
exports.UserCommon = UserCommon;
exports.UserCommonKeys = Object.keys(new UserCommon());
class UserPublic extends UserCommon {
    constructor() {
        super(...arguments);
        this.authDomains = [];
        // Permissions interface
        this.pilot = false;
        this.checker = false;
        this.assistant = false;
        this.moderator = false;
    }
}
exports.UserPublic = UserPublic;
exports.UserPublicKeys = Object.keys(new UserPublic());
class User extends UserPublic {
    constructor() {
        super(...arguments);
        // UserPrivate interface
        this.hashedPassword = '';
        this.salt = '';
    }
}
exports.User = User;
exports.UserKeys = Object.keys(new User());
/**
 * This class represents an object as expected by the mobile side,
 * containing an uid and having some unused data filtered out.
 *
 * Should not be casted into, only use the constructor to build this kind of data structure!
 *
 * @param uid this user's Id
 * @param userData user's public and private data
 */
class MobileUserData extends UserCommon {
    constructor(uid, userData) {
        super();
        this.uid = '';
        this.hashedPassword = '';
        this.salt = '';
        this.uid = uid;
        if (userData) {
            Object.assign(this, (0, helpers_1.filterObjectByKeys)(userData, Object.keys(this)));
        }
    }
}
exports.MobileUserData = MobileUserData;
exports.MobileUserDataKeys = Object.keys(new MobileUserData(''));
exports.UserPublicConverter = (0, firestore_1.DirectConverter)();
exports.KeyUserPublicConverter = (0, firestore_1.DirectConverter)();
exports.MaybeKeyUserPublicConverter = (0, firestore_1.DirectConverter)();
exports.UserPrivateConverter = (0, firestore_1.DirectConverter)();
