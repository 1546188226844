import { Nilable } from 'tsdef';
import messages_en from '../locales/en.json';
import messages_es from '../locales/es.json';
import messages_pt from '../locales/pt.json';
import { getTranslations as getUiComponentsTranslations } from 'flyid-ui-components/dist/locales';

export const DEFAULT_LOCALE = 'en-US';

export const SUPPORTED_LOCALES = ['pt-BR', 'en-GB', 'en-US', 'es-ES'] as const;
export type SupportedLocalesType = (typeof SUPPORTED_LOCALES)[number];

const { en: uiEn, es: uiEs, pt: uiPt } = getUiComponentsTranslations();

export const supportedMessages: Record<SupportedLocalesType, { [msgKey: string]: string }> = {
  'pt-BR': { ...messages_pt, ...uiPt },
  'en-GB': { ...messages_en, ...uiEn },
  'en-US': { ...messages_en, ...uiEn },
  'es-ES': { ...messages_es, ...uiEs }
};

export const readableLocaleByLocale: Record<SupportedLocalesType, string> = {
  'pt-BR': 'Português (BR)',
  'en-GB': 'English (GB)',
  'en-US': 'English (US)',
  'es-ES': 'Español (ES)'
};

const LOCALE_ALIASES: { [alias: string]: SupportedLocalesType } = {
  en: 'en-US',
  pt: 'pt-BR',
  'pt-PT': 'pt-BR',
  es: 'es-ES'
};

const getBrowserLocales = () => {
  let locales = ([] as string[]).concat(navigator.languages, navigator.language).filter(Boolean);
  locales = locales.map((locale) => LOCALE_ALIASES[locale] || locale);

  return [...new Set(locales)];
};

export const pickLocale = (proposedLocales?: string[]): SupportedLocalesType => {
  const browserLocales = proposedLocales || getBrowserLocales();
  let result: Nilable<SupportedLocalesType> = undefined;
  for (let i = 0; i < browserLocales.length && !result; i++) {
    if (SUPPORTED_LOCALES.indexOf(browserLocales[i] as SupportedLocalesType) !== -1) {
      result = browserLocales[i] as SupportedLocalesType;
    }
  }

  return result || DEFAULT_LOCALE;
};

export const localeToUse = pickLocale();
export const messages = supportedMessages[localeToUse];

export class TranslatableError {
  msgCode: string;
  args?: { [key: string]: string };
  err?: Error;

  constructor(msgCode: string, args?: { [key: string]: string }, err?: Error) {
    this.msgCode = msgCode;
    this.args = args;
    this.err = err;
  }
}
export const isTranslatableError = (v: unknown): v is TranslatableError =>
  typeof (v as TranslatableError)?.msgCode === 'string';

export const toTranslatableError = (err: Error) =>
  isTranslatableError(err)
    ? err
    : new TranslatableError('unkownErrorMsg', { message: err.message }, err);
