"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSummableType = exports.isMifComplete = exports.ManualInputFieldOptionalFields = exports.ManualInputFieldEmptiableKeys = exports.ManualInputFieldRequiredKeys = exports.ManualInputFieldEntries = exports.ManualInputFieldKeys = exports.ManualInputField = exports.ManualInputTypeKeys = exports.ManualInputTypes = void 0;
const helpers_1 = require("../../../../Util/helpers");
var ManualInputTypes;
(function (ManualInputTypes) {
    ManualInputTypes["integer"] = "integer";
    ManualInputTypes["decimal"] = "decimal";
    ManualInputTypes["text"] = "text";
    ManualInputTypes["binary"] = "binary";
})(ManualInputTypes = exports.ManualInputTypes || (exports.ManualInputTypes = {}));
exports.ManualInputTypeKeys = Object.keys(ManualInputTypes);
/** Descriptor of fields that may be filled manually by the user */
class ManualInputField {
    constructor() {
        this.field = '';
        this.description = '';
        this.useCheckField = false;
        this.inputType = ManualInputTypes.text;
        this.mandatory = false;
        this.showHistory = true;
        this.isSummable = false;
        this.maxAttempts = 0;
        this.checkField = '';
        this.unity = '';
        this.isAutoFillable = false;
    }
}
exports.ManualInputField = ManualInputField;
exports.ManualInputFieldKeys = Object.keys(new ManualInputField());
exports.ManualInputFieldEntries = Object.entries(new ManualInputField());
exports.ManualInputFieldRequiredKeys = [
    'field',
    'description',
    'useCheckField',
    'inputType',
    'mandatory',
    'maxAttempts',
    'checkField',
    'unity'
];
exports.ManualInputFieldEmptiableKeys = [
    'maxAttempts',
    'checkField',
    'unity'
];
exports.ManualInputFieldOptionalFields = [
    'showHistory',
    'isSummable'
];
function isMifComplete(mif) {
    const basicFields = Boolean((mif === null || mif === void 0 ? void 0 : mif.field) &&
        mif.description &&
        mif.inputType &&
        (0, helpers_1.isNonNulli)(mif.mandatory) &&
        (0, helpers_1.isNonNulli)(mif.useCheckField));
    const checkField = (mif === null || mif === void 0 ? void 0 : mif.useCheckField) ? !!mif.checkField && (0, helpers_1.isNonNulli)(mif.maxAttempts) : true;
    return basicFields && checkField;
}
exports.isMifComplete = isMifComplete;
const isSummableType = (type) => type === ManualInputTypes.integer || type === ManualInputTypes.decimal;
exports.isSummableType = isSummableType;
