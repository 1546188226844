import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  Container,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { BaseFieldsKeys } from 'flyid-core/dist/Database/Models/Settings/BaseFields';
import { FieldsSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { ResultFieldsKeys } from 'flyid-core/dist/Database/Models/Settings/ResultFields';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import LoadingButton from 'src/components/widgets/LoadingButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useOnceEffect from 'flyid-ui-components/dist/hooks/useOnceEffect';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionsHandler';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectDomainParent } from 'src/redux/selectors/userSelectors';
import { appMakeStyles } from 'src/theme/theme';

const useStyles = appMakeStyles(({ spacing, other }) => ({
  root: { flexGrow: 1 },
  settingsSkeleton: { height: spacing(4), marginLeft: spacing(2) },
  title: { color: other.grey.dark, margin: spacing(1, 0) },
  tooltip: { alignSelf: 'center' }
}));

const TAG_AUTO_FILLABLE = 'AutoFillables';
const TAG_EXTRA_TASK = 'ExtraTaskFields';
const TAG_CUSTOM_MARKERS = 'CustomMarkers';
const FIELD_LIST_TAGS = [TAG_AUTO_FILLABLE, TAG_EXTRA_TASK, TAG_CUSTOM_MARKERS];

const initialState = {
  // Addressing patterns
  addrPattern: '',
  addrPatternUser: '',
  // Base fields
  address: '',
  checkedAt: '',
  employeeId: '',
  employeeName: '',
  // Result fields
  null: '',
  empty: '',
  invalid: '',
  positive: '',
  negative: '',
  // Runtime state variables
  [TAG_CUSTOM_MARKERS]: [] as string[],
  [TAG_AUTO_FILLABLE]: [] as string[],
  [TAG_EXTRA_TASK]: [] as string[],
  [`new${TAG_CUSTOM_MARKERS}`]: '',
  [`new${TAG_AUTO_FILLABLE}`]: '',
  [`new${TAG_EXTRA_TASK}`]: '',
  [`adding${TAG_CUSTOM_MARKERS}`]: false,
  [`adding${TAG_AUTO_FILLABLE}`]: false,
  [`adding${TAG_EXTRA_TASK}`]: false
};
type State = typeof initialState;

const FieldsEditor: React.FC = () => {
  const { domain } = useParams<DomainMatchParams>();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();

  const [state, setState] = useStateReducer<State>(initialState);

  const { settings, ui, parentUid } = useAppSelector((s) => ({
    ui: s.ui,
    settings: selectSettings(s, domain),
    parentUid: selectDomainParent(s, domain)
  }));

  useOnceEffect(() => {
    if (settings) {
      setState({
        addrPattern: settings.fieldSettings.addrPattern,
        addrPatternUser: settings.fieldSettings.addrPatternUser,
        ...settings.fieldSettings.baseFields,
        ...settings.fieldSettings.resultFields,
        [TAG_CUSTOM_MARKERS]: settings.fieldSettings.customMarkers || [],
        [TAG_AUTO_FILLABLE]: settings.fieldSettings.autoFillableFields || [],
        [TAG_EXTRA_TASK]: settings.fieldSettings.extraTaskFields || []
      });
      return [true];
    }
    return [false];
  });

  const showConfirmationDialog = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newSettings = buildDatabaseSettings();
    if (!settings || !newSettings || !parentUid) return;
    if (state.positive === state.negative) {
      dispatch(
        updateUi({
          snackbar: {
            message: $t(
              { id: 'dSett.NegEqPosError' },
              {
                neg: $t({ id: 'negative' }),
                pos: $t({ id: 'positive' })
              }
            ),
            severity: 'error',
            show: true
          }
        })
      );
      return;
    }

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: `dSett.updtConfTitle` }),
          message: $t({ id: `dSett.updtConfMsg` }),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: `dSett.updtConfCheck` })}</b>,
          show: true
        }).setConfirmAction(Actions.EDIT_DOMAIN_SETTINGS_FIELDS, {
          parentUid,
          data: {
            domain,
            currentSettings: settings,
            newSettings
          }
        })
      })
    );
  };

  const buildDatabaseSettings = () => {
    const stateData = { ...state };

    for (const key in stateData) {
      // Null result, autoFillableFields and extraTaskFields can be empty
      if (
        key === 'null' ||
        key.includes(TAG_AUTO_FILLABLE) ||
        key.includes(TAG_EXTRA_TASK) ||
        key.includes(TAG_CUSTOM_MARKERS)
      )
        continue;

      if (isEmpty(stateData[key])) {
        dispatch(
          updateUi({
            snackbar: {
              message: $t({ id: 'dSett.reqField' }, { field: key }),
              severity: 'error',
              show: true
            }
          })
        );

        return null;
      }
    }

    const fieldsSettings: FieldsSettings = {
      addrPattern: stateData.addrPattern,
      addrPatternUser: stateData.addrPatternUser,
      baseFields: {
        address: stateData.address,
        checkedAt: stateData.checkedAt,
        employeeId: stateData.employeeId,
        employeeName: stateData.employeeName
      },
      resultFields: {
        empty: stateData.empty,
        invalid: stateData.invalid,
        positive: stateData.positive,
        negative: stateData.negative,
        null: stateData.null
      },
      customMarkers: [...stateData[TAG_CUSTOM_MARKERS]],
      autoFillableFields: [...stateData[TAG_AUTO_FILLABLE]],
      extraTaskFields: [...stateData[TAG_EXTRA_TASK]]
    };

    return fieldsSettings;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleAddToFieldList = (tag: string) => {
    const newField = state[`new${tag}`] as string;
    const list = state[tag] as string[];

    if (!newField) {
      dispatch(
        updateUi({
          snackbar: {
            message: $t({ id: 'fieldsEditor.fieldNameRequired' }),
            severity: 'error',
            show: true
          }
        })
      );
      return;
    }

    if (list.includes(newField)) {
      dispatch(
        updateUi({
          snackbar: {
            message: $t({ id: 'fieldsEditor.fieldAlreadyExists' }),
            severity: 'error',
            show: true
          }
        })
      );
      return;
    }

    setState({ [tag]: [...list, newField], [`new${tag}`]: '', [`adding${tag}`]: false });
  };

  const handleRemoveListField = (tag: string, index: number) => {
    setState({ [tag]: (state[tag] as string[]).filter((val, idx) => idx !== index) });
  };

  const cancelAddingListField = (tag: string) => {
    setState({ [`new${tag}`]: '', [`adding${tag}`]: false });
  };

  const renderSkeletons = (amount) => {
    return [...Array(amount).keys()].map((idx) => (
      <Grid xs={12} key={`sskel${idx}`}>
        <Skeleton variant="rectangular" className={classes.settingsSkeleton} animation="wave" />
      </Grid>
    ));
  };

  return (
    <Container className={classes.root}>
      <form onSubmit={showConfirmationDialog}>
        <Grid container spacing={2} alignItems="center" justifyContent="start">
          {/* Addressing pattern title */}
          <Grid xs={12}>
            <Typography variant="h5" className={classes.title}>
              {$t({ id: 'dSett.addrPatternTitle' })}
              <Tooltip
                disableTouchListener
                className={classes.tooltip}
                title={
                  <>
                    <Typography variant="subtitle2">
                      {$t({ id: 'dSett.addrPatternInfo' }, { nl: <br key="nl0" /> })}
                      <br />
                      {$t({ id: 'dSett.addrPatternChars' })}
                      <ul>
                        {['R', 'C', 'N', 'A', `*(${$t({ id: 'asterisk' })})`, null].map(
                          (bold, idx) => (
                            <li key={`${String(bold)}${idx}`}>
                              {bold ? <b>{bold}</b> : ''}{' '}
                              {$t({ id: `dSett.addrPatternChar${idx}` })}
                            </li>
                          )
                        )}
                      </ul>
                    </Typography>
                    <Typography variant="subtitle2">
                      {$t({ id: 'dSett.addrPatternEgMsg' })}
                      <ul>
                        {[0, 1].map((idx) => (
                          <li key={`eg${idx}`}>{$t({ id: `dSett.addrPatternEg${idx}` })}</li>
                        ))}
                      </ul>
                    </Typography>
                  </>
                }
              >
                <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
              </Tooltip>
            </Typography>
          </Grid>
          {/* Addressing pattern data */}
          {settings ? (
            <>
              <Grid xs={1} />
              <Grid xs={4}>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  id="addrPattern"
                  name="addrPattern"
                  type="text"
                  label={$t({ id: 'dSett.barcodePattern' })}
                  value={state.addrPattern}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={1}>
                <ArrowRightAltIcon />
              </Grid>
              <Grid xs={6}>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  id="addrPatternUser"
                  name="addrPatternUser"
                  type="text"
                  label={$t({ id: 'dSett.usrReadablePattern' })}
                  value={state.addrPatternUser}
                  onChange={handleChange}
                />
              </Grid>
            </>
          ) : (
            renderSkeletons(1)
          )}

          {/* Base codes title */}
          <Grid xs={12}>
            <Typography variant="h5" className={classes.title}>
              {$t({ id: 'dSett.baseCodesTitle' })}
              <Tooltip
                disableTouchListener
                className={classes.tooltip}
                title={
                  <Typography variant="subtitle2">{$t({ id: 'dSett.baseCodesInfo' })}</Typography>
                }
              >
                <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
              </Tooltip>
            </Typography>
          </Grid>
          {/* Base codes data */}
          {settings
            ? BaseFieldsKeys.map((field, index) => (
                <Fragment key={`${field}${index}`}>
                  <Grid xs={1} />
                  <Grid xs={4}>
                    <Typography variant="body1">{$t({ id: field })}</Typography>
                  </Grid>
                  <Grid xs={1}>
                    <ArrowRightAltIcon />
                  </Grid>
                  <Grid xs={6}>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      id={field}
                      name={field}
                      type="text"
                      value={state[field]}
                      onChange={handleChange}
                    />
                  </Grid>
                </Fragment>
              ))
            : renderSkeletons(2)}

          {/* Result codes title */}
          <Grid xs={12}>
            <Typography variant="h5" className={classes.title}>
              {$t({ id: 'dSett.resultsTitle' })}
              <Tooltip
                disableTouchListener
                className={classes.tooltip}
                title={
                  <Typography variant="subtitle2">
                    {$t({ id: 'dSett.resultsInfo' }, { nl: <br key="nl1" /> })}
                  </Typography>
                }
              >
                <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
              </Tooltip>
            </Typography>
          </Grid>
          {/* Result codes data */}
          {settings
            ? ResultFieldsKeys.map((field, index) => {
                const isNotNull = field !== 'null';
                return (
                  <Fragment key={`${field}${index}`}>
                    <Grid xs={1} />
                    <Grid xs={4}>
                      <Typography variant="body1">{$t({ id: field })}</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <ArrowRightAltIcon />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        required={isNotNull} // allow empty null field
                        placeholder={
                          isNotNull
                            ? `<${$t({ id: 'required' })}>`
                            : `<${$t({ id: 'dSett.emptyText' })}>`
                        }
                        variant="outlined"
                        size="small"
                        id={field}
                        name={field}
                        type="text"
                        value={state[field]}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Fragment>
                );
              })
            : renderSkeletons(2)}

          {/* Field lists */}
          {FIELD_LIST_TAGS.map((tag, listIdx) => (
            <Fragment key={`fieldList${listIdx}`}>
              {/* Field list title */}
              <Grid container xs={12} alignItems="center" spacing={2}>
                <Grid xs="auto">
                  <Typography variant="h5" className={classes.title}>
                    {$t({ id: `fieldsEditor.${tag}Title` })}
                    <Tooltip
                      disableTouchListener
                      className={classes.tooltip}
                      title={
                        <Typography variant="subtitle2">
                          {$t({ id: `fieldsEditor.${tag}Info` }, { nl: <br key="nl1" /> })}
                        </Typography>
                      }
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark', ml: 1 }} />
                    </Tooltip>
                  </Typography>
                </Grid>
                {settings && (
                  <>
                    <Grid
                      xs="auto"
                      sx={{
                        display: state[`adding${tag}`] ? 'flex' : 'none',
                        flexDirection: 'row'
                      }}
                    >
                      <TextField
                        placeholder={`<${$t({ id: 'name' })}>`}
                        variant="outlined"
                        size="small"
                        id={`new${tag}`}
                        name={`new${tag}`}
                        type="text"
                        value={state[`new${tag}`]}
                        onChange={(e) => setState({ [`new${tag}`]: e.target.value })}
                        sx={{ mt: 0.5, display: state[`adding${tag}`] ? 'block' : 'none' }}
                      />
                      <Tooltip title={$t({ id: 'add' })}>
                        <IconButton
                          edge="end"
                          aria-label={$t({ id: 'add' })}
                          sx={{ color: 'secondary.main' }}
                          onClick={() => handleAddToFieldList(tag)}
                          size="small"
                        >
                          <AddCircleIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={$t({ id: 'cancel' })}>
                        <IconButton
                          edge="end"
                          aria-label={$t({ id: 'cancel' })}
                          sx={{ color: 'error.main' }}
                          onClick={() => cancelAddingListField(tag)}
                          size="small"
                        >
                          <CancelIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid
                      xs="auto"
                      sx={{ display: state[`adding${tag}`] ? 'none' : 'inline-flex' }}
                    >
                      <Button
                        color="secondary"
                        aria-label={$t({ id: 'add' })}
                        onClick={() => setState({ [`adding${tag}`]: true })}
                      >
                        <AddCircleIcon sx={{ mr: 1 }} />
                        {$t({ id: 'add' })}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              {/* Field list data */}
              {settings ? (
                (state[tag] as string[] | undefined)?.length ? (
                  (state[tag] as string[]).map((afField, idx) => (
                    <Fragment key={`afField${idx}`}>
                      <Grid xs={1} />
                      <Grid xs={5}>
                        <Typography variant="body1">{afField}</Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Tooltip title={$t({ id: 'remove' })}>
                          <IconButton
                            edge="end"
                            aria-label={$t({ id: 'remove' })}
                            sx={{ color: 'error.main' }}
                            onClick={() => handleRemoveListField(tag, idx)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Fragment>
                  ))
                ) : (
                  <Grid xs={12}>
                    <Typography variant="body1">
                      {$t({ id: `fieldsEditor.no${tag}Avail` })}
                    </Typography>
                  </Grid>
                )
              ) : (
                renderSkeletons(1)
              )}
            </Fragment>
          ))}

          {/* Save button */}
          <Grid xs={12}>
            <LoadingButton
              isLoading={ui.loadingButton.isEditDomainSettingsFieldsLoading}
              disabled={!settings}
              content={$t({ id: 'saveChanges' })}
              context="form"
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default FieldsEditor;
