import { Container, Grid, Typography } from '@mui/material';
import { Case, CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import { CustomMarker } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';
import { NodeType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import type { LabelDesign } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import { LogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import type { ManualInputField } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import type { PictureTaking } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/PictureTaking';
import { TwoWayMap } from 'flyid-core/dist/Util/structs';
import { cloneDeep } from 'lodash';
import React, { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import { appMakeStyles } from 'src/theme/theme';
import { difference } from 'src/util/debug';
import { getSortedMap } from 'src/util/helpers/other';
import {
  buildCasesForCustomMarkers,
  buildCasesForLabelDesigns,
  buildCasesForLogicalBlockParents,
  buildCasesForMIFs,
  buildCasesForPictureTaking,
  buildElseCase
} from 'src/util/processFlow/cases';
import { Elements, getIncomersRecursively } from 'src/util/processFlow/common';
import { filterActionTypeNodes, filterNodesByType, getNodeById } from 'src/util/processFlow/node';
import type { EditorProps, LogicalBlockParent, TypedNode } from 'src/util/processFlow/types';
import CaseEditor from './CaseEditor';

const useStyles = appMakeStyles(({ resizableContainer, spacing, other }) => ({
  container: {
    ...resizableContainer(1),
    marginLeft: 0,
    maxWidth: '1000px'
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  formControl: {
    minWidth: '100%'
  }
}));

const ConditionalEditor: React.FC<EditorProps<CaseData[]>> = (props) => {
  const classes = useStyles();
  const flowInstance = useAppReactFlow();
  const intl = useIntl();

  const [cases, setCases] = useState(() => cloneDeep(props.data));

  // Build possible cases from action nodes coming into this conditional
  const [availableCases, casesAndOpposites, elseCaseDescriptor] = ((): [
    Map<string, Case>,
    TwoWayMap<string, string>,
    string
  ] => {
    const nodes = flowInstance.getNodes();
    const elements = (nodes as Elements).concat(flowInstance.getEdges());

    const actionNodes = filterActionTypeNodes(
      // Recursively get incomers without trespassing the boundaries of another Conditional node
      getIncomersRecursively(props.nodeId, elements, [NodeType.Conditional])
    );

    const actionData = {
      labelsBoundByOr: {}
    } as {
      // Independent labels are those that can be set as invalid independently
      independentLabels: TypedNode<LabelDesign>[];
      // labelsBoundByOr cannot be distinguished when invalid is pressed
      labelsBoundByOr: {
        [parent: string]: [TypedNode<LogicalBlockParent>, TypedNode<LabelDesign>[]];
      };
      mifs: TypedNode<ManualInputField>[];
      pics: TypedNode<PictureTaking>[];
      customMarkers: TypedNode<CustomMarker>[];
    };
    actionData.mifs = filterNodesByType<ManualInputField>(NodeType.ManualInputField, actionNodes);
    actionData.pics = filterNodesByType<PictureTaking>(NodeType.TakePicture, actionNodes);
    actionData.customMarkers = filterNodesByType<CustomMarker>(NodeType.CustomMarker, actionNodes);
    // Labels
    const labels = filterNodesByType<LabelDesign>(NodeType.LabelDesign, actionNodes);
    actionData.independentLabels = labels.filter((ldn) => {
      const parent = ldn.data.parent;
      // Separate labels that are children of the same OR logical blocks
      let independent = true;
      if (parent) {
        const parentNode = getNodeById<LogicalBlockParent>(nodes, parent);
        const isBoundByOrParent = parentNode?.data.specificData?.operation === LogicalOperator.OR;
        if (isBoundByOrParent) {
          independent = false;
          const parentGroup = actionData.labelsBoundByOr[parent];
          if (parentGroup) parentGroup[1].push(ldn);
          else actionData.labelsBoundByOr[parent] = [parentNode, [ldn]];
        }
      }
      return independent;
    });

    const casesMap = new Map<string, Case>();
    const oppositesMap = new TwoWayMap<string, string>();

    buildCasesForLabelDesigns(casesMap, oppositesMap, intl, actionData.independentLabels);
    buildCasesForLogicalBlockParents(casesMap, oppositesMap, intl, actionData.labelsBoundByOr);
    buildCasesForCustomMarkers(casesMap, oppositesMap, intl, actionData.customMarkers);
    buildCasesForMIFs(casesMap, oppositesMap, intl, actionData.mifs);
    buildCasesForPictureTaking(casesMap, oppositesMap, intl, actionData.pics);

    const sortedMap = getSortedMap(casesMap);
    let elseCaseDescriptor = '';
    if (sortedMap.size) {
      elseCaseDescriptor = buildElseCase(sortedMap, intl);
    }

    return [sortedMap, oppositesMap, elseCaseDescriptor];
  })();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    props.onSave(cloneDeep(cases), (setNodes, setEdges) => {
      const diff = difference(cases, props.data);
      if ((diff as []).length) {
        // Remove edges outgoing from this node when changing cases, because ReactFlow Handler
        // associations will become confuse otherwise
        setEdges((edges) => edges.filter((e) => e.source !== props.nodeId));
      }
    });
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {intl.$t({ id: 'processFlow.Conditional' })}
      </Typography>

      {/* TODO add subtitle with explanation */}

      <Grid container alignItems="center">
        <CaseEditor
          casesData={cases}
          availableConditions={availableCases}
          conditionsAndOpposites={casesAndOpposites}
          elseCaseDescriptor={elseCaseDescriptor}
          onCasesChange={setCases}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Container>
  );
};

export default ConditionalEditor;
