import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography
} from '@mui/material';
import { orderBy, query } from 'firebase/firestore';
import { DomainSettings } from 'flyid-core/dist/Database/Models';
import { getDomainSettingsCol } from 'flyid-core/dist/Util/database';
import React, { useState } from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import LoadingButton from 'src/components/widgets/LoadingButton';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionsHandler';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectCurrentUserProfile, selectDomainParent } from 'src/redux/selectors/userSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';

const useStyles = appMakeStyles(({ spacing }) => ({
  root: { maxWidth: '800px', flexGrow: spacing(1), marginLeft: spacing(0) }
}));

const RestoreSettings: React.FC = () => {
  const classes = useStyles();
  const { text, spacing, select } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { domain } = useParams<DomainMatchParams>();
  const { ui, profile, mostRecentSettings, company, parentUid } = useAppSelector((s) => ({
    company: selectTargetCompany(s),
    ui: s.ui,
    profile: selectCurrentUserProfile(s),
    parentUid: selectDomainParent(s, domain),
    mostRecentSettings: selectSettings(s, domain)
  }));

  const [selectedSetting, setSelectedSetting] = useState('');

  const [settingsQS, loadingSettings, errorSettings] = useCollectionOnce(
    profile && company
      ? query(
          buildCollectionRef(getDomainSettingsCol(company, domain)),
          orderBy('createdDate', 'desc')
        )
      : undefined
  );

  const settings = querySnapToMap(settingsQS);

  const buildSettingsDescription = (setting: DomainSettings): string => {
    const version = 'v' + (setting.version ?? 1);
    const createdDate = new Date(setting.createdDate).toLocaleString();
    let restoredDateStr = '';

    if (
      setting.restoredFrom &&
      typeof setting.restoredFrom === 'object' &&
      'seconds' in setting.restoredFrom &&
      'nanoseconds' in setting.restoredFrom
    ) {
      const { seconds, nanoseconds } = setting.restoredFrom as {
        seconds: number;
        nanoseconds: number;
      };
      const restoredDate = new Date(seconds * 1000 + nanoseconds / 1000000).toLocaleString();
      restoredDateStr = $t({ id: 'restoreSettings.restoredFrom' }) + ' ' + restoredDate; //
      return `${createdDate} (${version}) (${restoredDateStr})`;
    }
    return `${createdDate} (${version})`;
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSetting(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (settings && parentUid) {
      const selectedSett = settings[selectedSetting];

      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: `restoreSettings.title` }),
            message: $t(
              { id: `restoreSettings.confMsg` },
              {
                nl: <br key="munl0" />,
                version: (
                  <b key={`mdb0${selectedSetting}`}>{buildSettingsDescription(selectedSett)}</b>
                )
              }
            ),
            useCheckbox: true,
            checkboxState: false,
            checkboxMessage: <b>{$t({ id: `restoreSettings.confCheck` })}</b>,
            show: true
          }).setConfirmAction(Actions.RESTORE_DOMAIN_SETTINGS, {
            parentUid,
            data: { domain, selectedSettings: selectedSett }
          })
        })
      );
    }
  };

  return (
    <>
      {!errorSettings ? (
        <Container className={classes.root}>
          <Typography variant="subtitle1" sx={text.subtitle}>
            {$t({ id: 'restoreSettings.subtitle' })}
          </Typography>
          {mostRecentSettings ? (
            <Typography variant="subtitle1" sx={text.subtitle}>
              {$t(
                { id: 'restoreSettings.currentSettings' },
                {
                  version: <b key={`mdb0`}>{buildSettingsDescription(mostRecentSettings)}</b>
                }
              )}
            </Typography>
          ) : null}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={7} md={7}>
                {!loadingSettings && !!settings ? (
                  <FormControl fullWidth>
                    <InputLabel id="select-settings">
                      {$t({ id: 'restoreSettings.settingsVersions' })}
                    </InputLabel>
                    <Select
                      labelId="settings-v-select-label"
                      id="settings-v-select"
                      name="settings"
                      required
                      value={selectedSetting}
                      onChange={handleChange}
                      input={
                        <OutlinedInput label={$t({ id: 'restoreSettings.settingsVersions' })} />
                      }
                      MenuProps={select.getMenuProps()}
                    >
                      <MenuItem value="">
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {Object.entries(settings)
                        .filter(([, setting]) => !setting.restoredFrom)
                        .map(([key, setting]) => (
                          <MenuItem key={key} value={key}>
                            {buildSettingsDescription(setting)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                )}
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  content={$t({ id: 'restoreSettings.title' })}
                  isLoading={ui.loadingButton.isRestoreSettingsLoading}
                  disabled={!selectedSetting}
                  type="submit"
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <Typography variant="body1">An error occurred: {errorSettings.message}</Typography>
      )}
    </>
  );
};

export default RestoreSettings;
