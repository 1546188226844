"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersionDataConverter = void 0;
const time_1 = require("../../Util/time");
exports.VersionDataConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { date: (0, time_1.parseDateFromTimestamp)(data.date) });
    },
    toFirestore: (data) => data
};
