"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultDomainSettingsDoc = exports.getDomainSettingsCol = exports.getInventoryTasksBackupCol = exports.getInventoryTaskDoc = exports.getInventoryTasksCol = exports.getSessionsBackupCol = exports.getSessionDoc = exports.getSessionsCol = exports.getSessionKeyDoc = exports.getSessionKeysCol = exports.getCachedLicenseDataDoc = exports.getLicenseSettingsDoc = exports.getOurLicenseDoc = exports.getOurLicensesCol = exports.getLicensesSettingsCol = exports.getCachedLicenseDataCol = exports.getApiKeysCol = exports.getUserPrivateProfileDoc = exports.getMaybeKeyUserProfileDoc = exports.getKeyUserProfileDoc = exports.getUserProfileDoc = exports.getUsersPrivateCol = exports.getAllUsersCol = exports.getKeyUsersCol = exports.getUsersCol = exports.getDomainDoc = exports.getDomainsCol = exports.getCompanyDoc = exports.getDomainsSettingsBackupDoc = exports.getCompanyDeviceDoc = exports.getCompanyDevicesCol = exports.getCompaniesCol = exports.defaultsPath = exports.getStorageKeyUserProfileThumbPath = exports.getStorageKeyUserProfileImagePath = exports.getStorageUserProfileThumbPath = exports.getStorageUserProfileImagePath = exports.getTablePath = exports.getTablesPath = exports.getLabelImageBackupPath = exports.getLabelImagesBackupPath = exports.getLabelImagePath = exports.getLabelImagesPath = exports.getPicturePath = exports.getPicturesPath = exports.getStorageDomainPath = exports.getUserProfileThumbsDir = exports.getUserProfileImageDir = exports.isValidId = exports.KEY_USERS_COMPANY_TAG = void 0;
exports.getManualInputFields = exports.getBarcodePatternFields = exports.getAllBackupPaths = exports.getCheckCodesCol = exports.getVersionsCol = exports.getSessionsBackupsCol = exports.getHardwareTrackingBatteriesCol = exports.getHardwareTrackingDoc = exports.getHardwareTrackingCol = exports.getFlightLogsCol = exports.getDeviceDoc = exports.getDevicesCol = exports.getApiCallCountDoc = exports.getAuthenticationProviderSettingsDoc = exports.getCustomPersistenceSettingsDoc = exports.getCustomInterfaceSettingsDoc = exports.getScanditLicenseDoc = void 0;
const lodash_1 = require("lodash");
const Models_1 = require("../Database/Models");
const APIKey_1 = require("../Database/Models/APIKey");
const AuthProvider_1 = require("../Database/Models/AuthProvider");
const CheckCode_1 = require("../Database/Models/CheckCode");
const Company_1 = require("../Database/Models/Company");
const CompanySettings_1 = require("../Database/Models/CompanySettings");
const BatteryData_1 = require("../Database/Models/HardwareTracking/BatteryData");
const FlightLogs_1 = require("../Database/Models/HardwareTracking/FlightLogs");
const SessionData_1 = require("../Database/Models/HardwareTracking/SessionData");
const SessionKey_1 = require("../Database/Models/SessionKey");
const APICallsCount_1 = require("../Database/Models/Settings/APICallsCount");
const DomainSettings_1 = require("../Database/Models/Settings/DomainSettings");
const User_1 = require("../Database/Models/User");
const firestore_1 = require("./firestore");
exports.KEY_USERS_COMPANY_TAG = '__key_users';
// Firestore id utils
const isValidId = (id) => !id.match(/\/|\.|\[|\]|\*|´|^__.*__$/g);
exports.isValidId = isValidId;
// Storage
const getUserProfileImageDir = (company) => company;
exports.getUserProfileImageDir = getUserProfileImageDir;
const getUserProfileThumbsDir = (company) => `${company}/thumbs`;
exports.getUserProfileThumbsDir = getUserProfileThumbsDir;
const getStorageDomainPath = (company, domain) => `companies/${company}/${domain}`;
exports.getStorageDomainPath = getStorageDomainPath;
const getPicturesPath = (company, domain) => `${(0, exports.getStorageDomainPath)(company, domain)}/pictures`;
exports.getPicturesPath = getPicturesPath;
const getPicturePath = (company, domain, filename) => `${(0, exports.getPicturesPath)(company, domain)}/${filename}`;
exports.getPicturePath = getPicturePath;
const getLabelImagesPath = (company, domain) => `${(0, exports.getStorageDomainPath)(company, domain)}/labelImages`;
exports.getLabelImagesPath = getLabelImagesPath;
const getLabelImagePath = (company, domain, id) => `${(0, exports.getLabelImagesPath)(company, domain)}/${id}`;
exports.getLabelImagePath = getLabelImagePath;
const getLabelImagesBackupPath = (company, domain) => `${(0, exports.getLabelImagesPath)(company, domain)}/backup`;
exports.getLabelImagesBackupPath = getLabelImagesBackupPath;
const getLabelImageBackupPath = (company, domain, id) => `${(0, exports.getLabelImagesBackupPath)(company, domain)}/${id}`;
exports.getLabelImageBackupPath = getLabelImageBackupPath;
const getTablesPath = (company, domain) => `${(0, exports.getStorageDomainPath)(company, domain)}/tables`;
exports.getTablesPath = getTablesPath;
const getTablePath = (company, domain, id) => `${(0, exports.getTablesPath)(company, domain)}/${id}`;
exports.getTablePath = getTablePath;
const getStorageUserProfileImagePath = (company, uid) => `${(0, exports.getUserProfileImageDir)(company)}/${uid}`;
exports.getStorageUserProfileImagePath = getStorageUserProfileImagePath;
const getStorageUserProfileThumbPath = (company, uid) => `${(0, exports.getUserProfileThumbsDir)(company)}/${uid}`;
exports.getStorageUserProfileThumbPath = getStorageUserProfileThumbPath;
const getStorageKeyUserProfileImagePath = (uid) => `${(0, exports.getUserProfileImageDir)(exports.KEY_USERS_COMPANY_TAG)}/${uid}`;
exports.getStorageKeyUserProfileImagePath = getStorageKeyUserProfileImagePath;
const getStorageKeyUserProfileThumbPath = (uid) => `${(0, exports.getUserProfileThumbsDir)(exports.KEY_USERS_COMPANY_TAG)}/${uid}`;
exports.getStorageKeyUserProfileThumbPath = getStorageKeyUserProfileThumbPath;
// Firestore
// Paths
const companiesPath = '/companies';
const getCompanyPath = (company) => `${companiesPath}/${company}`;
const getDomainsPath = (company) => `${getCompanyPath(company)}/domains`;
const getDomainPath = (company, domain) => `${getDomainsPath(company)}/${domain}`;
const getCompanyDevicesPath = (company) => `${getCompanyPath(company)}/devices`;
const getCompanyDevicePath = (company, device) => `${getCompanyDevicesPath(company)}/${device}`;
const getDomainSettingsBackupPath = (company, domain) => `${getBackupPath(company, domain)}/settings/lastUnsaveState`;
const getSessionsPath = (company, domain) => `${getDomainPath(company, domain)}/sessions`;
const getSessionPath = (company, domain, session) => `${getSessionsPath(company, domain)}/${session}`;
const getSessionsBackupPath = (company, domain) => `${getBackupPath(company, domain)}/sessions`;
const getInventoryTasksPath = (company, domain) => `${getDomainPath(company, domain)}/tasks`;
const getInventoryTaskPath = (company, domain, id) => `${getInventoryTasksPath(company, domain)}/${id}`;
const getInventoryTasksBackupPath = (company, domain) => `${getBackupPath(company, domain)}/tasks`;
const getDomainSettingsPath = (company, domain) => `${getDomainPath(company, domain)}/settings`;
const getApiCallCountPath = (company) => `${getCompanyPath(company)}/counters/apiCalls`;
const getDevicesPath = (company) => `${getCompanyPath(company)}/devices`;
const getDevicePath = (company, uuid) => `${getDevicesPath(company)}/${uuid}`;
const getBackupsPath = (company) => `${getCompanyPath(company)}/backup`;
const getBackupPath = (company, domain) => `${getBackupsPath(company)}/${domain}`;
exports.defaultsPath = '/default';
const getDefaultDomainSettingsPath = () => `${exports.defaultsPath}/settings`;
const getFlyidDefaultProductionScannerSettingsPath = () => `${exports.defaultsPath}/scanditFlyid`;
const getFlyidProductionMatrixscanScannerSettingsPath = () => `${exports.defaultsPath}/scanditFlyidMatrixScan`;
const getFlyidDefaultTestsScannerSettingsPath = () => `${exports.defaultsPath}/scanditFlyidTests`;
const getFlyidPilotDefaultTestsScannerSettingsPath = () => `${exports.defaultsPath}/scanditPilotTests`;
const getCountidDefaultProductionScannerSettingsPath = () => `${exports.defaultsPath}/scanditCountid`;
const getCountidDefaultTestsScannerSettingsPath = () => `${exports.defaultsPath}/scanditCountidTests`;
const customSettingsPath = '/settings';
const getCustomInterfaceSettingsPath = () => `${customSettingsPath}/interface`;
const getCustomPersistenceSettingsPath = () => `${customSettingsPath}/persistence`;
const getAuthenticationProviderSettingsPath = () => `${customSettingsPath}/authProviders`;
const usersPath = '/users';
const getUserPath = (uid) => `${usersPath}/${uid}`;
const usersPrivatePath = '/usersPrivate';
const getUserPrivatePath = (uid) => `${usersPrivatePath}/${uid}`;
const apiKeysPath = '/APIKeys';
const logsPath = '/logs';
const cachePath = '/cache';
const cachedLicensesPath = `${cachePath}/licenses`;
const flyidCachedLicensesDataPath = `${cachedLicensesPath}/flyid`;
const countidCachedLicensesDataPath = `${cachedLicensesPath}/countid`;
const sessionKeysPath = '/sessionKeys';
const getSessionKeyPath = (sessionId) => `/sessionKeys/${sessionId}`;
/**
 * This function checks if the target is a Count.id or Fly.id license, as well as if shoudl use v1
 * or v2 cache and returns the appropriate path.
 */
const getCachedLicenseDataPath = (license) => `${(0, Models_1.getLicenseType)(license) === Models_1.LicenseType.FLYID
    ? flyidCachedLicensesDataPath
    : countidCachedLicensesDataPath}/${license}`;
const ourLicensesPath = '/ourLicenses';
const getOurLicensePath = (license) => `${ourLicensesPath}/${license}`;
const licensesSettPath = '/licenseSettings';
const getLicenseSettingsPath = (license) => `${licensesSettPath}/${license}`;
const getFlightLogs = (company) => `/logs/flightLogs/${company}`;
const hardwareTrackingPath = '/hardwareTracking';
const getHardwareTrackingCompanyPath = (company) => `${hardwareTrackingPath}/${company}`;
const getHardwareTrackingBatteriesPath = (company) => `${getHardwareTrackingCompanyPath(company)}/battery`;
const getSessionsBackupsPath = (company, domain) => `${getBackupsPath(company)}/${domain}/sessions`;
const versionsPath = '/versions';
const checkCodesPath = '/checkCodes';
// Typed reference getters
const getCompaniesCol = () => ({
    path: companiesPath,
    converter: Company_1.CompanyConverter
});
exports.getCompaniesCol = getCompaniesCol;
const getCompanyDevicesCol = (company) => ({
    path: getCompanyDevicesPath(company),
    converter: Models_1.DeviceDataConverter
});
exports.getCompanyDevicesCol = getCompanyDevicesCol;
const getCompanyDeviceDoc = (company, device) => ({
    path: getCompanyDevicePath(company, device),
    converter: Models_1.DeviceDataConverter
});
exports.getCompanyDeviceDoc = getCompanyDeviceDoc;
const getDomainsSettingsBackupDoc = (company, domain) => ({
    path: getDomainSettingsBackupPath(company, domain)
});
exports.getDomainsSettingsBackupDoc = getDomainsSettingsBackupDoc;
const getCompanyDoc = (company) => ({
    path: getCompanyPath(company),
    converter: Company_1.CompanyConverter
});
exports.getCompanyDoc = getCompanyDoc;
const getDomainsCol = (company) => ({
    path: getDomainsPath(company)
});
exports.getDomainsCol = getDomainsCol;
const getDomainDoc = (company, domain) => ({
    path: getDomainPath(company, domain)
});
exports.getDomainDoc = getDomainDoc;
const getUsersCol = () => ({
    path: usersPath,
    converter: User_1.UserPublicConverter
});
exports.getUsersCol = getUsersCol;
const getKeyUsersCol = () => ({
    path: usersPath,
    converter: User_1.KeyUserPublicConverter
});
exports.getKeyUsersCol = getKeyUsersCol;
const getAllUsersCol = () => ({
    path: usersPath,
    converter: User_1.MaybeKeyUserPublicConverter
});
exports.getAllUsersCol = getAllUsersCol;
const getUsersPrivateCol = () => ({
    path: usersPrivatePath,
    converter: User_1.UserPrivateConverter
});
exports.getUsersPrivateCol = getUsersPrivateCol;
const getUserProfileDoc = (uid) => ({
    path: getUserPath(uid),
    converter: User_1.UserPublicConverter
});
exports.getUserProfileDoc = getUserProfileDoc;
const getKeyUserProfileDoc = (uid) => ({
    path: getUserPath(uid),
    converter: User_1.KeyUserPublicConverter
});
exports.getKeyUserProfileDoc = getKeyUserProfileDoc;
const getMaybeKeyUserProfileDoc = (uid) => ({
    path: getUserPath(uid),
    converter: User_1.MaybeKeyUserPublicConverter
});
exports.getMaybeKeyUserProfileDoc = getMaybeKeyUserProfileDoc;
const getUserPrivateProfileDoc = (uid) => ({
    path: getUserPrivatePath(uid),
    converter: User_1.UserPrivateConverter
});
exports.getUserPrivateProfileDoc = getUserPrivateProfileDoc;
const getApiKeysCol = () => ({
    path: apiKeysPath,
    converter: APIKey_1.APIKeyConverter
});
exports.getApiKeysCol = getApiKeysCol;
const getCachedLicenseDataCol = (flyid) => ({
    path: flyid ? flyidCachedLicensesDataPath : countidCachedLicensesDataPath,
    converter: Models_1.CachedMobileLicenseDataConverter
});
exports.getCachedLicenseDataCol = getCachedLicenseDataCol;
const getLicensesSettingsCol = () => ({
    path: licensesSettPath,
    converter: Models_1.LicenseSettingsConverter
});
exports.getLicensesSettingsCol = getLicensesSettingsCol;
const getOurLicensesCol = () => ({
    path: ourLicensesPath,
    converter: Models_1.LicenseConverter
});
exports.getOurLicensesCol = getOurLicensesCol;
const getOurLicenseDoc = (license) => ({
    path: getOurLicensePath(license),
    converter: Models_1.LicenseConverter
});
exports.getOurLicenseDoc = getOurLicenseDoc;
const getLicenseSettingsDoc = (license) => ({
    path: getLicenseSettingsPath(license),
    converter: Models_1.LicenseSettingsConverter
});
exports.getLicenseSettingsDoc = getLicenseSettingsDoc;
/**
 * This function checks if the target is a Count.id or Fly.id license
 * and returns the appropriate {@link FirestoreReferenceData}
 */
const getCachedLicenseDataDoc = (license) => ({
    path: getCachedLicenseDataPath(license),
    converter: Models_1.CachedMobileLicenseDataConverter
});
exports.getCachedLicenseDataDoc = getCachedLicenseDataDoc;
const getSessionKeysCol = () => ({
    path: sessionKeysPath,
    converter: SessionKey_1.SessionKeyConverter
});
exports.getSessionKeysCol = getSessionKeysCol;
const getSessionKeyDoc = (id) => ({
    path: getSessionKeyPath(id),
    converter: SessionKey_1.SessionKeyConverter
});
exports.getSessionKeyDoc = getSessionKeyDoc;
const getSessionsCol = (company, domain) => ({
    path: getSessionsPath(company, domain),
    converter: Models_1.SessionConverter
});
exports.getSessionsCol = getSessionsCol;
const getSessionDoc = (company, domain, session) => ({
    path: getSessionPath(company, domain, session),
    converter: Models_1.SessionConverter
});
exports.getSessionDoc = getSessionDoc;
const getSessionsBackupCol = (company, domain) => ({
    path: getSessionsBackupPath(company, domain),
    converter: (0, Models_1.BackupConverter)(Models_1.SessionConverter)
});
exports.getSessionsBackupCol = getSessionsBackupCol;
const getInventoryTasksCol = (company, domain) => ({
    path: getInventoryTasksPath(company, domain),
    converter: Models_1.TaskConverter
});
exports.getInventoryTasksCol = getInventoryTasksCol;
const getInventoryTaskDoc = (company, domain, id) => ({
    path: getInventoryTaskPath(company, domain, id),
    converter: Models_1.TaskConverter
});
exports.getInventoryTaskDoc = getInventoryTaskDoc;
const getInventoryTasksBackupCol = (company, domain) => ({
    path: getInventoryTasksBackupPath(company, domain),
    converter: (0, Models_1.BackupConverter)(Models_1.TaskConverter)
});
exports.getInventoryTasksBackupCol = getInventoryTasksBackupCol;
const getDomainSettingsCol = (company, domain) => ({
    path: getDomainSettingsPath(company, domain),
    converter: DomainSettings_1.DomainSettingsConverter
});
exports.getDomainSettingsCol = getDomainSettingsCol;
const getDefaultDomainSettingsDoc = () => ({
    path: getDefaultDomainSettingsPath(),
    converter: DomainSettings_1.DomainSettingsConverter
});
exports.getDefaultDomainSettingsDoc = getDefaultDomainSettingsDoc;
const getScanditLicenseDoc = (type, production, hasMatrixScan = false) => {
    let path;
    switch (type) {
        case Models_1.LicenseType.FLYID: {
            path = production
                ? hasMatrixScan
                    ? getFlyidProductionMatrixscanScannerSettingsPath()
                    : getFlyidDefaultProductionScannerSettingsPath()
                : getFlyidDefaultTestsScannerSettingsPath();
            break;
        }
        case Models_1.LicenseType.FLYID_PILOT: {
            // Update to add production license when one is available.
            path = getFlyidPilotDefaultTestsScannerSettingsPath();
            break;
        }
        case Models_1.LicenseType.COUNTID: {
            path = production
                ? getCountidDefaultProductionScannerSettingsPath()
                : getCountidDefaultTestsScannerSettingsPath();
            break;
        }
        default:
            throw Error('Unknown license type!');
    }
    return { path, converter: (0, firestore_1.DirectConverter)() };
};
exports.getScanditLicenseDoc = getScanditLicenseDoc;
const getCustomInterfaceSettingsDoc = () => ({
    path: getCustomInterfaceSettingsPath(),
    converter: CompanySettings_1.InterfaceSettingsConverter
});
exports.getCustomInterfaceSettingsDoc = getCustomInterfaceSettingsDoc;
const getCustomPersistenceSettingsDoc = () => ({
    path: getCustomPersistenceSettingsPath(),
    converter: CompanySettings_1.PersistenceSettingsConverter
});
exports.getCustomPersistenceSettingsDoc = getCustomPersistenceSettingsDoc;
const getAuthenticationProviderSettingsDoc = () => ({
    path: getAuthenticationProviderSettingsPath(),
    converter: AuthProvider_1.AuthenticationProviderConverter
});
exports.getAuthenticationProviderSettingsDoc = getAuthenticationProviderSettingsDoc;
const getApiCallCountDoc = (company) => ({
    path: getApiCallCountPath(company),
    converter: APICallsCount_1.APICallsCountConverter
});
exports.getApiCallCountDoc = getApiCallCountDoc;
const getDevicesCol = (company) => ({
    path: getDevicesPath(company),
    converter: Models_1.DeviceDataConverter
});
exports.getDevicesCol = getDevicesCol;
const getDeviceDoc = (company, uuid) => ({
    path: getDevicePath(company, uuid),
    converter: Models_1.DeviceDataConverter
});
exports.getDeviceDoc = getDeviceDoc;
const getFlightLogsCol = (company) => ({
    path: getFlightLogs(company),
    converter: FlightLogs_1.FlightLogConverter
});
exports.getFlightLogsCol = getFlightLogsCol;
const getHardwareTrackingCol = () => ({
    path: hardwareTrackingPath
});
exports.getHardwareTrackingCol = getHardwareTrackingCol;
const getHardwareTrackingDoc = (company) => ({
    path: getHardwareTrackingCompanyPath(company)
});
exports.getHardwareTrackingDoc = getHardwareTrackingDoc;
const getHardwareTrackingBatteriesCol = (company) => ({
    path: getHardwareTrackingBatteriesPath(company),
    converter: BatteryData_1.BatteryDataConverter
});
exports.getHardwareTrackingBatteriesCol = getHardwareTrackingBatteriesCol;
const getSessionsBackupsCol = (company, domain) => ({
    path: getSessionsBackupsPath(company, domain),
    converter: SessionData_1.SessionDataConverter
});
exports.getSessionsBackupsCol = getSessionsBackupsCol;
const getVersionsCol = () => ({
    path: versionsPath,
    converter: Models_1.VersionDataConverter
});
exports.getVersionsCol = getVersionsCol;
const getCheckCodesCol = () => ({
    path: checkCodesPath,
    converter: CheckCode_1.CheckCodeConverter
});
exports.getCheckCodesCol = getCheckCodesCol;
// Backups
const getAllBackupPaths = () => [
    apiKeysPath,
    cachePath,
    companiesPath,
    exports.defaultsPath,
    hardwareTrackingPath,
    licensesSettPath,
    logsPath,
    ourLicensesPath,
    customSettingsPath,
    usersPath,
    usersPrivatePath,
    versionsPath
];
exports.getAllBackupPaths = getAllBackupPaths;
// Domain settings manipulation
function getBarcodePatternFields(barcodePatterns, filterBy) {
    const ret = new Set();
    if (!(0, lodash_1.isEmpty)(barcodePatterns)) {
        barcodePatterns.forEach((barcodePattern) => {
            if (!(0, lodash_1.isEmpty)(barcodePattern.dataFields)) {
                barcodePattern.dataFields.forEach((field) => {
                    var _a;
                    if (field.type === 'data' && ((_a = filterBy === null || filterBy === void 0 ? void 0 : filterBy(field)) !== null && _a !== void 0 ? _a : true)) {
                        ret.add(field.name);
                    }
                });
            }
        });
    }
    return Array.from(ret.values());
}
exports.getBarcodePatternFields = getBarcodePatternFields;
function getManualInputFields(manualInputFields, filterBy) {
    const ret = new Set();
    if (!(0, lodash_1.isEmpty)(manualInputFields)) {
        Object.values(manualInputFields).forEach((mif) => {
            var _a;
            if ((_a = filterBy === null || filterBy === void 0 ? void 0 : filterBy(mif)) !== null && _a !== void 0 ? _a : true)
                ret.add(mif.field);
        });
    }
    return Array.from(ret.values());
}
exports.getManualInputFields = getManualInputFields;
