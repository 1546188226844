"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionKeyConverter = void 0;
const time_1 = require("../../Util/time");
exports.SessionKeyConverter = {
    toFirestore(data) {
        return data;
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        return Object.assign(Object.assign({}, data), { createdAt: (0, time_1.parseDateFromTimestamp)(data.createdAt) });
    }
};
