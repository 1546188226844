import { Vertex } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Vertex';
import type Konva from 'konva';
import React, { useRef, useState } from 'react';
import { Group, Layer, Rect as RectDrawing, Stage, Text } from 'react-konva';
import { useAppTheme } from 'src/theme/theme';
import { Rect } from 'src/util/helpers/geometry';

// Failed using suspend with konva, nested elements are breaking, maybe retry another time?
// The getKonvaComponent implementation leads to the exact same as importing with
// const Group = lazy(() => import('react-konva').then((konva) => ({ default: konva.Group })));
// Therefore it's not an implementation issue, maybe we are just missing something.
//
// Loading Konva and then picking up every component didn't work as well.
//
// const Group = lazy(() => getKonvaComponent<Konva.Group>('Group'));
// const RectDrawing = lazy(() => getKonvaComponent<Konva.Rect, Konva.RectConfig>('Rect'));
// const Layer = lazy(() => getKonvaComponent<Konva.Layer, Konva.LayerConfig>('Layer'));
// const Stage = lazy(() => getKonvaComponent<Konva.Stage, StageProps>('Stage'));
// const Text = lazy(() => getKonvaComponent<Konva.Text, Konva.TextConfig>('Text'));

type KonvaMouseOrTouchEvent = Konva.KonvaEventObject<MouseEvent | TouchEvent>;

const initialRect = { x: 0, y: 0, width: 0, height: 0 };

export enum Modes {
  NONE = 'none',
  ADD_BARCODE = 'add_bc',
  DEFINE_LABEL = 'def_label',
  DELETE = 'delete',
  PATTERN = 'pattern'
}

const getRectCenter = (rect: Rect): Vertex => ({
  x: rect.x + rect.width / 2,
  y: rect.y + rect.height / 2
});

type Props = {
  mode: Modes;
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  label: Rect;
  barcodes: Rect[];
  selectedIndex: number;
  setLabel: (label: Rect) => void;
  addBarcode: (barcode: Rect) => void;
  setSelectedIndex: (index: number) => void;
  remBarcode: (index: number) => void;
};

const LabelDesignStage: React.FC<Props> = (props) => {
  const { mode, setLabel, addBarcode, remBarcode, setSelectedIndex, scaleX, scaleY } = props;
  const theme = useAppTheme();

  const [isDrawing, setIsDrawing] = useState(false);
  const [newRect, setNewRect] = useState(initialRect);

  const newRectRef = useRef<Konva.Rect>(null);
  const stageRef = useRef<Konva.Stage>(null);

  const checkWithinLabelLimits = (_mode: Modes, point: Konva.Vector2d) => {
    const {
      scaleX,
      scaleY,
      label: { x, y, width, height }
    } = props;
    const label = {
      x: x * scaleX,
      y: y * scaleY,
      width: width * scaleX,
      height: height * scaleY
    };
    if (Math.abs(label.width) <= 0 || Math.abs(label.height) <= 0) {
      return false;
    } else {
      const otherVertex = { x: label.x + label.width, y: label.y + label.height };
      const xMin = Math.min(label.x, otherVertex.x);
      const yMin = Math.min(label.y, otherVertex.y);
      const xMax = Math.max(label.x, otherVertex.x);
      const yMax = Math.max(label.y, otherVertex.y);
      if (point.x < xMin || point.x > xMax || point.y < yMin || point.y > yMax) {
        return false;
      }
    }
    return true;
  };

  const onStageMouseDown = (e: KonvaMouseOrTouchEvent) => {
    e.cancelBubble = true;
    switch (mode) {
      case Modes.DEFINE_LABEL:
      case Modes.ADD_BARCODE: {
        const pointer = e.currentTarget.getStage()?.getPointerPosition();
        if (pointer) {
          if (isDrawing) {
            const { x, y } = newRect;
            const { scaleX, scaleY } = props;
            const endRect = {
              x: x / scaleX,
              y: y / scaleY,
              width: (pointer.x - x) / scaleX,
              height: (pointer.y - y) / scaleY
            };
            if (mode === Modes.DEFINE_LABEL) {
              setLabel(endRect);
            } else {
              // Check if current barcode is inside label region
              if (mode === Modes.ADD_BARCODE) {
                if (!checkWithinLabelLimits(mode, pointer)) return;
              }
              addBarcode(endRect);
            }
            setNewRect(initialRect);
            setIsDrawing(false);
          } else {
            // Check if current barcode is inside label region
            if (mode === Modes.ADD_BARCODE) {
              if (!checkWithinLabelLimits(mode, pointer)) return;
            }
            setNewRect({
              x: pointer.x,
              y: pointer.y,
              width: newRect.width,
              height: newRect.height
            });
            setIsDrawing(true);
          }
        }
        break;
      }
      case Modes.DELETE:
      case Modes.NONE:
      default:
        setSelectedIndex(-1);
        break;
    }
  };

  const onStageMouseMove = (e: KonvaMouseOrTouchEvent) => {
    e.cancelBubble = true;
    if ((mode === Modes.ADD_BARCODE || mode === Modes.DEFINE_LABEL) && isDrawing) {
      const pointer = e.currentTarget.getStage()?.getPointerPosition();
      // Check if current barcode is inside label region
      if (pointer) {
        if (mode === Modes.ADD_BARCODE) {
          if (!checkWithinLabelLimits(mode, pointer)) return;
        }

        newRectRef.current?.to({
          duration: 0,
          x: newRect.x,
          y: newRect.y,
          width: pointer.x - newRect.x,
          height: pointer.y - newRect.y
        });
      }
    }
  };

  const onRectSelection = (index: number) => (e: KonvaMouseOrTouchEvent) => {
    e.cancelBubble = true;
    if (mode !== Modes.DELETE) {
      setSelectedIndex(index);
    }
  };

  const onRectAction = (index: number) => (e: KonvaMouseOrTouchEvent) => {
    e.cancelBubble = true;
    switch (mode) {
      case Modes.DELETE:
        remBarcode(index);
        break;
      case Modes.ADD_BARCODE:
      case Modes.NONE:
      default:
        break;
    }
  };

  const renderRectBeingDrawn = () => {
    const isLabel = mode === Modes.DEFINE_LABEL;

    const rect = { ...newRect };
    rect.x *= scaleX;
    rect.width *= scaleX;
    rect.y *= scaleY;
    rect.height *= scaleY;

    return (
      <RectDrawing
        {...rect}
        fill={theme.palette.info.main}
        fillEnabled={!isLabel}
        dash={[10, 5]}
        dashEnabled={isLabel}
        opacity={0.5}
        stroke={theme.palette.info.main}
        strokeWidth={2}
        listening={false}
        ref={newRectRef}
        preventDefault={false}
      />
    );
  };

  const renderLabel = () => {
    const label = { ...props.label };
    label.x *= scaleX;
    label.width *= scaleX;
    label.y *= scaleY;
    label.height *= scaleY;

    return (
      <RectDrawing
        {...label}
        stroke={theme.palette.info.dark}
        dash={[10, 10]}
        dashEnabled={true}
        strokeWidth={3}
        preventDefault={false}
      />
    );
  };

  const renderDefinedRectangles = () =>
    props.barcodes.map((rect, index) => {
      const isSelected = index === props.selectedIndex;
      const currRect = { ...rect };
      currRect.x *= scaleX;
      currRect.width *= scaleX;
      currRect.y *= scaleY;
      currRect.height *= scaleY;

      const rectCenter = getRectCenter(currRect);
      return (
        <Group
          key={`rect${index}`}
          onMouseDown={onRectSelection(index)}
          onMouseUp={onRectAction(index)}
          onTouchStart={onRectSelection(index)}
          onTouchEnd={onRectAction(index)}
          preventDefault={false}
        >
          <RectDrawing
            {...currRect}
            fill={theme.palette.secondary.main}
            opacity={isSelected ? 0.9 : 0.5}
            preventDefault={false}
          />
          <RectDrawing
            {...currRect}
            stroke={isSelected ? theme.palette.secondary.light : theme.palette.secondary.dark}
            listening={false}
            strokeWidth={3}
            preventDefault={false}
          />
          <Text
            text={String(index + 1)}
            x={rectCenter.x - 6}
            y={rectCenter.y - 12}
            fontSize={24}
            fontStyle="bold"
            fill="white"
            listening={false}
            preventDefault={false}
          />
        </Group>
      );
    });

  if (stageRef.current) {
    switch (mode) {
      case 'delete':
        stageRef.current.getContent().style.cursor = 'no-drop';
        break;
      case 'add_bc':
      case 'def_label':
        stageRef.current.getContent().style.cursor = 'crosshair';
        break;
      case 'none':
      default:
        stageRef.current.getContent().style.cursor = 'default';
        break;
    }
  }

  return (
    <Stage
      width={props.width}
      height={props.height}
      onMouseDown={onStageMouseDown}
      onMouseMove={onStageMouseMove}
      onTouchStart={onStageMouseDown}
      onTouchMove={onStageMouseMove}
      onTouchEnd={onStageMouseDown}
      preventDefault={false}
      ref={stageRef}
    >
      {/* Drawing layer  */}
      <Layer preventDefault={false}>
        {/* Rectangle being drawn by user */}
        {isDrawing && renderRectBeingDrawn()}
        {/* Label marker */}
        {renderLabel()}
        {/* Already drawn barcode rectangles */}
        {renderDefinedRectangles()}
      </Layer>
    </Stage>
  );
};

export default LabelDesignStage;
