"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const userTypes_1 = require("../types/userTypes");
function getUserSlice() {
    const CustomUserState = (userTypes_1.UserState);
    const userSlice = (0, toolkit_1.createSlice)({
        name: 'user',
        initialState: new CustomUserState(),
        reducers: {
            setUserProfile: (state, action) => {
                const payload = action.payload;
                if (payload) {
                    state.claims = Object.assign(state.claims, payload.claims);
                    state.profile = Object.assign(state.profile, payload.profile);
                    state.uid = payload.uid;
                    state.emailVerified = payload.emailVerified;
                    state.isLoaded = true;
                    state.profileError = undefined;
                }
                else
                    Object.assign(state, new userTypes_1.UserState());
            },
            setUserProfileError: (state, action) => {
                const { uid, error: profileError } = action.payload;
                Object.assign(state, new userTypes_1.UserState(), { uid, isLoaded: true, profileError });
            },
            setProfileImageData: (state, action) => {
                const { uid, picData } = action.payload;
                const currPicData = state.profilePics[uid] || new userTypes_1.PictureData();
                state.profilePics[uid] = Object.assign(currPicData, picData);
            },
            setProfileImageThumb: (state, action) => {
                const { uid, picData } = action.payload;
                const currPicData = state.profilePicThumbs[uid] || new userTypes_1.PictureData();
                state.profilePicThumbs[uid] = Object.assign(currPicData, picData);
            }
        }
    });
    return {
        actions: userSlice.actions,
        reducer: userSlice.reducer
    };
}
exports.getUserSlice = getUserSlice;
