import { getRedirectResult, SAMLAuthProvider, signInWithRedirect, User } from 'firebase/auth';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'src/firebase/firebase';
import { useAppSelector } from 'src/hooks/reduxHooks';

const subdomainRegex = new RegExp('https?://([^.]+)\\.flyid(-.*)?\\.panoramaid\\.com\\.br');

export const useAuth = (): {
  user: User | null | undefined;
  loading: boolean;
  error: Error | undefined;
  usingSSO: boolean;
} => {
  const { providers, areAuthProvidersLoaded } = useAppSelector(
    ({ firestore }) => firestore.authProviders
  );
  const [triggeredRedirect, setTriggeredRedirect] = useState<boolean>(false);
  const [samlError, setSAMLError] = useState<Error | undefined>(undefined);
  const [user, authLoading, authError] = useAuthState(auth);

  // Keep loading while all auth data is not ready or redirect has been triggered
  const loading = !areAuthProvidersLoaded || authLoading || triggeredRedirect;
  const error = samlError ?? authError;

  // COR Uncomment to run mock SAML auth
  //const url = 'http://auth_provider_tests.flyid.panoramaid.com.br';
  const url = window.location.href;
  const subDomain = url.match(subdomainRegex)?.[1];
  const provider = subDomain && subDomain !== 'v2' ? providers?.[subDomain]?.provider : undefined;
  const usingSSO = !!provider;

  if (!usingSSO) return { user, loading, error: authError, usingSSO };
  // After SSO auth has been completed, user will be set to the authenticated user
  else if (!triggeredRedirect && !loading && !user && !error) {
    getRedirectResult(auth)
      .then((result) => {
        // Didn't do SAML authentication redirection yet, do it now
        if (result === null) {
          const authProvider = new SAMLAuthProvider(provider);
          signInWithRedirect(auth, authProvider).catch(console.error);
        }
        // Avoids multiple redirects
        setTriggeredRedirect(true);
      })
      .catch((err: Error) => {
        const _err =
          err instanceof Error
            ? err
            : Error(typeof err === 'string' ? (err as string) : String(err));
        console.error(`Authentication failed: ${_err.message}`);
        setSAMLError(_err);
      });
  }

  return { user, loading, error, usingSSO };
};
