"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBarcodePatternComplete = exports.BarcodePatternRequiredKeys = exports.BarcodePatternEntries = exports.BarcodePatternKeys = exports.BarcodePattern = void 0;
const helpers_1 = require("../../../../Util/helpers");
const BarcodeField_1 = require("./BarcodeField");
const Vertex_1 = require("./Vertex");
class BarcodePattern {
    constructor() {
        this.firstVertex = new Vertex_1.Vertex();
        this.secondVertex = new Vertex_1.Vertex();
        this.dataFields = [];
        this.regex = '';
        this.isRequired = false;
        this.ignoreRemaining = false;
    }
}
exports.BarcodePattern = BarcodePattern;
exports.BarcodePatternKeys = Object.keys(new BarcodePattern());
exports.BarcodePatternEntries = Object.entries(new BarcodePattern());
exports.BarcodePatternRequiredKeys = ['dataFields', 'regex', 'isRequired', 'ignoreRemaining'];
const isBarcodePatternComplete = (bp) => {
    var _a;
    const res = Boolean(bp &&
        bp.firstVertex &&
        bp.secondVertex &&
        (0, helpers_1.isNonNulli)(bp.ignoreRemaining) &&
        (0, helpers_1.isNonNulli)(bp.isRequired) &&
        bp.regex &&
        ((_a = bp.dataFields) === null || _a === void 0 ? void 0 : _a.every((df) => (0, BarcodeField_1.isBarcodeFieldComplete)(df))));
    return res;
};
exports.isBarcodePatternComplete = isBarcodePatternComplete;
