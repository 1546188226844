"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APICallsCountConverter = void 0;
const time_1 = require("../../../Util/time");
exports.APICallsCountConverter = {
    toFirestore: (data) => data,
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { lastCalls: data.lastCalls.map((ts) => (0, time_1.parseDateFromTimestamp)(ts)) });
    }
};
