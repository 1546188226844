"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPictureTakingComplete = exports.PictureTakingRequiredKeys = exports.PictureTakingEntries = exports.PictureTakingKeys = exports.PictureTaking = void 0;
const helpers_1 = require("../../../../Util/helpers");
class PictureTaking {
    constructor() {
        this.name = "";
        this.isMandatory = false;
    }
}
exports.PictureTaking = PictureTaking;
exports.PictureTakingKeys = Object.keys(new PictureTaking());
exports.PictureTakingEntries = Object.entries(new PictureTaking());
exports.PictureTakingRequiredKeys = exports.PictureTakingKeys;
const isPictureTakingComplete = (pt) => Boolean(pt && pt.name && (0, helpers_1.isNonNulli)(pt.isMandatory));
exports.isPictureTakingComplete = isPictureTakingComplete;
