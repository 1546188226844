"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundToNearestHour = exports.getDateMembers = exports.getDaysInMonth = exports.getDaysInMonthFromDate = exports.parseEpochFromTimestamp = exports.parseDateFromTimestamp = exports.isClientTimestamp = exports.isTimestamp = void 0;
/**
 * Check if input is a Timestamp (whether client or admin, contains the toDate() member)
 * @param timestamp Timestamp candidate
 */
const isTimestamp = (timestamp) => typeof (timestamp === null || timestamp === void 0 ? void 0 : timestamp.toDate) === 'function';
exports.isTimestamp = isTimestamp;
/**
 * Check if input is a Client Timestamp (belongs to Client Timestamp firebase/firestore class)
 * @param timestamp Timestamp candidate
 */
const isClientTimestamp = (timestamp) => (0, exports.isTimestamp)(timestamp) && typeof timestamp.toJSON === 'function';
exports.isClientTimestamp = isClientTimestamp;
const parseDateFromTimestamp = (timestamp, offsetMinutes = 0) => {
    let ret;
    if ((0, exports.isTimestamp)(timestamp))
        ret = timestamp.toDate();
    else if (timestamp instanceof Date)
        ret = timestamp;
    else if (typeof timestamp === 'number' || typeof timestamp === 'string')
        // If number or string, try parsing
        ret = new Date(timestamp);
    else {
        // If everything fails, fallback to 'now' to avoid breaking.
        ret = new Date();
    }
    return offsetMinutes ? new Date(ret.getTime() + offsetMinutes * 60 * 1000) : ret;
};
exports.parseDateFromTimestamp = parseDateFromTimestamp;
const parseEpochFromTimestamp = (timestamp) => (0, exports.parseDateFromTimestamp)(timestamp).getTime();
exports.parseEpochFromTimestamp = parseEpochFromTimestamp;
function getDaysInMonthFromDate(date) {
    return getDaysInMonth(date.getFullYear(), date.getMonth() + 1);
}
exports.getDaysInMonthFromDate = getDaysInMonthFromDate;
function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
}
exports.getDaysInMonth = getDaysInMonth;
function getDateMembers(date) {
    const yyyy = date.getFullYear();
    let mm = date.getMonth();
    mm = (mm > 9 ? mm : '0' + mm);
    let dd = date.getUTCDate();
    dd = (dd > 9 ? dd : '0' + dd);
    const epoch = date.getTime();
    return { yyyy, mm, dd, epoch };
}
exports.getDateMembers = getDateMembers;
function roundToNearestHour(date) {
    date.setMinutes(date.getMinutes() + 30);
    date.setMinutes(0, 0, 0);
    return date;
}
exports.roundToNearestHour = roundToNearestHour;
