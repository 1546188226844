"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabularDataConverter = exports.getEmptiableTabularDataFields = exports.TabularDataRequiredKeys = exports.TabularDataEntries = exports.TabularDataKeys = exports.TabularData = exports.getEmptiableTableDataFields = exports.TableDataRequiredKeys = exports.TableDataEntries = exports.TableDataKeys = exports.TableData = exports.AddressesMapKey = void 0;
const time_1 = require("../../../../Util/time");
exports.AddressesMapKey = 'flag_internal_addressesMap';
class TableData {
    constructor() {
        this.updatedAt = new Date();
        this.keyColumn = '';
        this.valueColumns = [];
    }
}
exports.TableData = TableData;
exports.TableDataKeys = Object.keys(new TableData());
exports.TableDataEntries = Object.entries(new TableData());
exports.TableDataRequiredKeys = exports.TableDataKeys;
const getEmptiableTableDataFields = (data) => [];
exports.getEmptiableTableDataFields = getEmptiableTableDataFields;
/** Contains information about tabulated data to be used for autofilling or address matching */
class TabularData {
    constructor() {
        /**
         * Whether bidirectional map of code identifier to address exists.
         *
         * If it does, keyColumn will be arbitrary address code column (randomly generated or similar)
         * and columnValue will be the correspondent matrix formattable address
         */
        this.hasAddressMapTable = false;
        /** Map of 'table name' to TableData for each user-defined table */
        this.dataTables = {};
    }
}
exports.TabularData = TabularData;
exports.TabularDataKeys = Object.keys(new TabularData());
exports.TabularDataEntries = Object.entries(new TabularData());
exports.TabularDataRequiredKeys = exports.TabularDataKeys;
const getEmptiableTabularDataFields = (data) => [];
exports.getEmptiableTabularDataFields = getEmptiableTabularDataFields;
exports.TabularDataConverter = {
    fromFirestore: (snap) => {
        var _a;
        const data = snap.data();
        const dataTables = Object.assign({}, Object.fromEntries(Object.entries((_a = data === null || data === void 0 ? void 0 : data.dataTables) !== null && _a !== void 0 ? _a : []).map(([k, v]) => [k, Object.assign(Object.assign({}, v), { updatedAt: (0, time_1.parseDateFromTimestamp)(v.updatedAt) })])));
        return Object.assign(Object.assign({}, data), { dataTables });
    },
    toFirestore: (data) => data
};
