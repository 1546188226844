/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo } from 'react';
import { BezierEdge, EdgeProps } from '@xyflow/react';
import { useAppTheme } from 'src/theme/theme';

const CustomEdge: React.FC<EdgeProps> = (props) => {
  const { selected, style } = props;
  const { palette } = useAppTheme();

  const markerEnd = selected ? 'url(#custom-edge-marker-sec)' : 'url(#custom-edge-marker-pri)';

  return (
    <BezierEdge
      {...props}
      style={{
        ...style,
        stroke: selected ? palette.secondary.dark : palette.primary.dark,
        fill: 'none',
        strokeWidth: 1.5
      }}
      markerEnd={markerEnd}
      interactionWidth={20}
    />
  );
};

export default memo(CustomEdge);
