import { Icon, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import cidLogo from '../../assets/images/logo/monograma_cid.png';

const useStyles = appMakeStyles(({ spacing, palette }) => ({
  iconRoot: {
    padding: spacing(1.5),
    backgroundColor: palette.primary.main,
    borderRadius: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: spacing(2),
    height: spacing(2)
  }
}));

export const CountIdIcon = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const { $t } = useIntl();

  return (
    <Tooltip
      disableTouchListener
      className={classes.tooltip}
      style={{ marginLeft: theme.spacing(1) }}
      title={<Typography variant="subtitle2">{$t({ id: 'affectsCountidOnly' })}</Typography>}
    >
      <Icon classes={{ root: classes.iconRoot }}>
        <img className={classes.logo} src={cidLogo} alt={$t({ id: 'affectsCountidOnly' })} />
      </Icon>
    </Tooltip>
  );
};
