"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAutoFillDataComplete = exports.AutoFillDataRequiredKeys = exports.AutoFillDataEntries = exports.AutoFillDataKeys = exports.AutoFillData = exports.AutofillSourceTypes = void 0;
var AutofillSourceTypes;
(function (AutofillSourceTypes) {
    AutofillSourceTypes["TABLE"] = "table";
    AutofillSourceTypes["TASK_INPUT"] = "task_input";
})(AutofillSourceTypes = exports.AutofillSourceTypes || (exports.AutofillSourceTypes = {}));
class AutoFillData {
    constructor() {
        this.sourceType = AutofillSourceTypes.TABLE;
        this.target = '';
        this.tableName = '';
        this.inputColumn = '';
        this.outputColumn = '';
    }
}
exports.AutoFillData = AutoFillData;
exports.AutoFillDataKeys = Object.keys(new AutoFillData());
exports.AutoFillDataEntries = Object.entries(new AutoFillData());
exports.AutoFillDataRequiredKeys = exports.AutoFillDataKeys;
const isAutoFillDataComplete = (afd) => {
    const tableSourceCheck = Boolean((afd === null || afd === void 0 ? void 0 : afd.sourceType) === AutofillSourceTypes.TABLE &&
        afd.target &&
        afd.inputColumn &&
        afd.outputColumn);
    const taskInputSourceCheck = Boolean((afd === null || afd === void 0 ? void 0 : afd.sourceType) === AutofillSourceTypes.TASK_INPUT && afd.target && afd.outputColumn);
    return Boolean(tableSourceCheck || taskInputSourceCheck);
};
exports.isAutoFillDataComplete = isAutoFillDataComplete;
