import { cx } from '@emotion/css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { alpha, Box, IconButton, lighten, Tooltip, Typography } from '@mui/material';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import {
  calculateParentDimensions,
  getCurrentParentDimensions,
  getOutputNodePosition,
  PARENT_TITLE_HEIGHT_PX,
  Size
} from 'flyid-core/dist/Util/processFlow';
import ConditionalWrapper from 'flyid-ui-components/dist/utils/ConditionalWrapper';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Handle, NodeProps, Position } from '@xyflow/react';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { TooltipOppositePosition } from 'src/util/helpers/geometry';
import { BaseNodeData, TypedNode } from 'src/util/processFlow/types';

const useStyles = appMakeStyles(
  ({ spacing, palette, reactFlow: { inputHandle, outputHandle } }) => ({
    mainContainer: {
      padding: spacing(1),
      color: palette.primary.dark,
      backgroundColor: alpha(palette.primary.main, 0.15),
      borderColor: palette.primary.dark,
      borderRadius: spacing(1),
      borderStyle: 'solid',
      borderWidth: '2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    contentOverlayPosition: {
      position: 'absolute',
      minWidth: 'calc(100% - 1px)',
      height: `calc(100% - ${PARENT_TITLE_HEIGHT_PX + 1}px)`,
      top: '50%',
      left: '50%',
      transform: `translate(-50%, calc(-50% + ${PARENT_TITLE_HEIGHT_PX / 2}px))`
    },
    widgetsContainer: {
      borderRadius: spacing(1),
      backgroundColor: '#7777',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    editButton: {
      margin: spacing(0.5),
      backgroundColor: palette.secondary.main,
      '&:hover': {
        backgroundColor: lighten(palette.secondary.main, 0.3)
      }
    },
    rotateButton: {
      margin: spacing(0.5),
      backgroundColor: palette.info.main,
      '&:hover': {
        backgroundColor: lighten(palette.info.main, 0.3)
      }
    },
    dragButton: {
      margin: spacing(0.5),
      backgroundColor: '#0002',
      '&:hover': {
        backgroundColor: '#0003'
      }
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: palette.grey[200],
      color: palette.primary.dark
    },
    underlinedBox: {
      width: '100%',
      borderBottom: 'solid thin',
      marginBottom: spacing(1)
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    icon: { width: spacing(3), height: spacing(3) },
    inputHandle: inputHandle(1.5, 1.5),
    outputHandle: outputHandle(1.5, 1.5),
    wrapButtonsContainer: {
      flex: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    addChild: {
      backgroundColor: alpha(palette.secondary.dark, 0.5),
      color: palette.primary.dark
    }
  })
);

// Content description
export type Content = {
  titleId?: string;
  placeholder?: string;
};

export type Handles = {
  inputHandles?: HandleType[];
  inputHandleDescriptors?: JSX.Element[];
  outputHandles?: HandleType[];
  outputHandleDescriptors?: JSX.Element[];
};

type Props = Omit<BaseNodeData, 'onDetachFromParent'> &
  Partial<NodeProps> & {
    content: Content;
    handles?: Handles;
    autoHandle?: boolean;
    intersectsWith?: string;
    contentChildrenIds: string[];
    outputNodeId?: string;
  };

const StylesByPos: Record<Position, [string, string]> = {
  [Position.Top]: ['start', 'top'],
  [Position.Bottom]: ['start', 'bottom'],
  [Position.Left]: ['top', 'left'],
  [Position.Right]: ['top', 'right']
};

/* Returns positioning style per handle position and index/count */
const getHandleStyle = (index: number, count: number, pos: Position) => {
  const styles = StylesByPos[pos];
  let percent = 100 / count;
  percent = percent * (1 + index) - percent / 2;
  let x = `${percent}%`;
  const y = pos === Position.Top ? `${PARENT_TITLE_HEIGHT_PX}px` : 0;
  if (pos === Position.Left || pos === Position.Right)
    x = `calc(${x} + ${PARENT_TITLE_HEIGHT_PX / 2}px)`;
  return { [styles[0]]: x, [styles[1]]: y };
};

const initialHandlePosition = { input: Position.Left, output: Position.Right };

// Use this flag to signal specific layout effect reasons
enum TriggerType {
  OTHER,
  WAITING_CHILD_DIMENTIONS,
  WAITING_NATURAL_RESIZE
}

/**
 * This component is not really a node, but the styled box that will render node content
 */
const ParentNode: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { palette, spacing } = useAppTheme();
  const { $t } = useIntl();

  const [calcDimensions, setCalcDimensions] = useState<Size | undefined>(undefined);
  const [mouseOver, setMouseOver] = useState(false);
  const [handlePosition] = useState({ ...initialHandlePosition });
  const [triggerResize, setTriggerResize] = useState(0);
  // Use a second state to track resize trigger reason to avoid infinite loops.
  const [lastTriggerReason, setLastTriggerReason] = useState(TriggerType.OTHER);
  const containerRef = useRef<HTMLDivElement>(null);

  const { getNodes, setNodes } = useAppReactFlow();

  const { content, handles, contentChildrenIds, outputNodeId } = props;
  // eslint-disable-next-line prefer-const
  let { width, height } = calcDimensions ?? {};
  if (height) height = height - PARENT_TITLE_HEIGHT_PX;

  const getInputHandleStyle = useCallback(
    (index: number) =>
      getHandleStyle(index, handles?.inputHandles?.length || 0, handlePosition.input),
    [handles, handlePosition]
  );

  const getOutputHandleStyle = useCallback(
    (index: number) =>
      getHandleStyle(index, handles?.outputHandles?.length || 0, handlePosition.output),
    [handles, handlePosition]
  );

  // This layout effect is used to react to changes on parent/children
  useLayoutEffect(() => {
    let outputNode: TypedNode | undefined;
    const childrenNodes = getNodes().filter((n) => {
      if (n.id === outputNodeId) {
        outputNode = n;
        return false;
      }
      return contentChildrenIds.includes(n.id);
    });

    // Trigger delayed resize whenever a child does not have some dimension
    if (
      childrenNodes.some((n) => !n.measured?.width || !n.measured?.height) ||
      (outputNodeId && (!outputNode?.measured?.width || !outputNode?.measured?.height))
    ) {
      setTimeout(() => setTriggerResize(Date.now()), 30);
      setLastTriggerReason(TriggerType.WAITING_CHILD_DIMENTIONS);
      return;
    }

    // Get dimensions from child or just use current size
    let parentDim = calculateParentDimensions(childrenNodes, outputNode);
    // Set dimensions. If there is no child, parentDim will be undefined and dimensions will be set
    // to natural block size on the next render.
    setCalcDimensions(parentDim);

    if (outputNode) {
      // If parent has no children, wait a while so the UI can reflect setting
      // parent dimensions to it's natural size.
      if (!parentDim) {
        if (lastTriggerReason !== TriggerType.WAITING_NATURAL_RESIZE) {
          setTimeout(() => setTriggerResize(Date.now()), 30);
          setLastTriggerReason(TriggerType.WAITING_NATURAL_RESIZE);
          return;
        } else {
          // Set reason to something other than waiting to make sure next wait will be properly triggered.
          setLastTriggerReason(TriggerType.OTHER);
        }
      }
      const dimToUse = parentDim ?? getCurrentParentDimensions(containerRef);

      // Reposition output node after determining parent size
      setNodes((ns) =>
        ns.map((n) => {
          if (n.id === outputNodeId) {
            return {
              ...n,
              position: getOutputNodePosition(outputNode!, dimToUse!.width, dimToUse!.height)
            };
          }
          return n;
        })
      );
    }
  }, [contentChildrenIds, outputNodeId, triggerResize, setNodes]);

  const maxHandleSize = Math.max(
    handles?.inputHandles?.length || 0,
    handles?.outputHandles?.length || 0
  );

  const showPlaceholder = Boolean(
    content.placeholder && !props.intersectsWith && !contentChildrenIds?.length
  );
  return (
    <Box
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      ref={containerRef}
    >
      <Typography noWrap color={palette.primary.dark}>
        {content.titleId}
      </Typography>

      <Box
        className={cx(
          classes.mainContainer,
          'custom-drag-handle',
          props.intersectsWith ? classes.addChild : undefined
        )}
        sx={{
          boxShadow: props.selected ? `0 0 1px .5px ${palette.primary.dark}` : undefined,
          width: width ?? 'fit-content',
          height: height ?? 'fit-content',
          minHeight: spacing(maxHandleSize * 3)
        }}
      >
        {/* Content & Widgets for common nodes */}
        <Box>
          {/* Placeholder */}
          {!contentChildrenIds.length && (
            <Typography
              variant="body1"
              sx={{
                maxWidth: '150px',
                visibility: showPlaceholder ? 'visible' : 'hidden',
                mr: outputNodeId ? 8 : 0,
                fontWeight: 'bold'
              }}
              align="center"
            >
              {content.placeholder}
            </Typography>
          )}
          {/* Widgets overlay */}
          <Box
            className={cx(classes.widgetsContainer, classes.contentOverlayPosition)}
            sx={{ visibility: mouseOver ? 'visible' : 'hidden' }}
          >
            {props.onEditClick && (
              <Tooltip
                title={$t({ id: 'edit' })}
                sx={{ position: 'absolute', top: -16, right: -16 }}
              >
                <IconButton className={classes.editButton} onClick={props.onEditClick} size="small">
                  <EditIcon sx={{ color: palette.common.white }} fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {/* Add child overlay */}
          {props.intersectsWith && (
            <Box className={cx(classes.widgetsContainer, classes.contentOverlayPosition)}>
              <AddCircleIcon fontSize="large" style={{ color: palette.common.white }} />
            </Box>
          )}
        </Box>

        {/* Handles */}
        {handles?.inputHandles?.map((type, index) => (
          <Handle
            key={`target${index}`}
            type="target"
            id={`${type}_${index}`}
            style={getInputHandleStyle(index)}
            position={handlePosition.input}
            className={classes.inputHandle}
          />
        ))}

        {handles?.outputHandles?.map((type, index) => {
          const descriptor = handles?.outputHandleDescriptors?.[index];
          const hasDescriptor = descriptor !== undefined;
          return (
            <ConditionalWrapper
              key={`PN-OutHandle-CW-${type}-${index}`}
              condition={hasDescriptor}
              wrapper={(children) =>
                children && (
                  <Tooltip
                    placement={TooltipOppositePosition[handlePosition.output]}
                    arrow
                    sx={{ ml: 'auto' }}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    title={<Typography variant="subtitle2">{descriptor}</Typography>}
                  >
                    {children}
                  </Tooltip>
                )
              }
            >
              <Handle
                key={`source${index}`}
                type="source"
                position={handlePosition.output}
                id={`${type}_${index}`}
                className={classes.outputHandle}
                style={getOutputHandleStyle(index)}
                // onConnect={(c) => console.log(c)}
              />
            </ConditionalWrapper>
          );
        })}
      </Box>
    </Box>
  );
};

export default ParentNode;
