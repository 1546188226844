import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { CustomMarker } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';
import { cloneDeep } from 'lodash';
import React, { ChangeEvent, FormEvent } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { EditorProps } from 'src/util/processFlow/types';

const useStyles = appMakeStyles(({ resizableContainer, spacing, other }) => ({
  container: {
    ...resizableContainer(1),
    marginLeft: 0,
    minWidth: '500px',
    maxWidth: '1000px'
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  formControl: {
    minWidth: '100%'
  },
  menuItemRoot: {
    whiteSpace: 'unset',
    wordBreak: 'break-all'
  }
}));

const CustomMarkerEditor: React.FC<EditorProps<CustomMarker>> = (props) => {
  const { $t } = useIntl();
  const classes = useStyles();
  const { select } = useAppTheme();
  const { domain } = useParams<DomainMatchParams>();

  const [customMarkerData, setCustomMarkerData] = useStateReducer(cloneDeep(props.data));
  const settings = useAppSelector((s) => selectSettings(s, props.domain));

  const availableCustomMarkers = settings?.fieldSettings?.customMarkers ?? [];

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomMarkerData({ [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) =>
    setCustomMarkerData({ [name]: e.target.checked });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onSave(cloneDeep(customMarkerData));
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              {$t({ id: 'processFlow.CustomMarker' }, { domain })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.title}>
              {$t({ id: 'fieldsEditor.CustomMarkersInfo' }, { nl: <br key="nl3" /> })}
            </Typography>
          </Grid>

          {availableCustomMarkers.length ? (
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} sx={{ py: 1, px: 1 }} justifySelf="end">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={customMarkerData.isMandatory}
                      onChange={handleCheckboxChange('isMandatory')}
                    />
                  }
                  label={<Typography variant="body2">{$t({ id: 'mif.mandatory' })}</Typography>}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  id="name"
                  name="name"
                  type="text"
                  label={$t({ id: 'name' })}
                  value={customMarkerData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="allowed-markers-label">
                    {$t({ id: 'cmEditor.allowedMarkers' })}
                  </InputLabel>
                  <Select
                    labelId="allowed-markers-label"
                    id="allowedMarkers"
                    multiple
                    name={'allowedMarkers'}
                    value={customMarkerData.allowedMarkers}
                    onChange={(e) => handleChange(e as never)}
                    input={<OutlinedInput label={$t({ id: 'cmEditor.allowedMarkers' })} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={select.getMenuProps()}
                  >
                    {availableCustomMarkers.map((value) => (
                      <MenuItem key={value} value={value} classes={{ root: classes.menuItemRoot }}>
                        <Checkbox checked={customMarkerData.allowedMarkers.indexOf(value) > -1} />
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.title}>
                {$t({ id: 'cmEditor.noAvailableMarkers' })}
              </Typography>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Grid item xs />
            <Grid item xs="auto">
              <Button variant="contained" color="secondary" type="submit">
                {$t({ id: 'saveChanges' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CustomMarkerEditor;
