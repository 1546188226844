import { getDoc } from 'firebase/firestore';
import { getSessionDoc } from 'flyid-core/dist/Util/database';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import { useEffect, useMemo, useState } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { buildDocumentRef } from 'src/firebase/firestore';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { useClosestSettings } from 'src/hooks/useClosestSettings';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { useSessionDataParser } from 'src/workers/dataWorkerApi';
import SessionDataReviewer from './Review/SessionDataReviewer';

const showInputDataStorageKey = 'sessionShowInputData';
const showBaseFieldsStorageKey = 'sessionShowBaseFields';

const SessionReview: React.FC = () => {
  const { domain, session } = useParams<SessionMatchParams>();

  // Getting user profile and settings
  const { profile, mostRecentSettings, company } = useAppSelector((state) => ({
    company: selectTargetCompany(state),
    profile: selectCurrentUserProfile(state),
    mostRecentSettings: selectSettings(state, domain)
  }));

  // Getting session data
  const [sessionData, loadingSessionData] = useDocumentDataOnce(
    profile && buildDocumentRef(getSessionDoc(company!, domain, session))
  );

  const [, settings] = useClosestSettings(
    company,
    domain,
    sessionData?.startTime,
    mostRecentSettings
  );

  const wasTask = Boolean(sessionData?.wasTask);

  // Variable for parsing data
  const [showInputData, setShowInputData] = useStoredState(showInputDataStorageKey, wasTask);
  const [showBaseFields, setShowBaseFields] = useStoredState(showBaseFieldsStorageKey, false);

  // Using parser to inflate the addresses
  const { isWorking, rowsAndColumnsOrError } = useSessionDataParser(
    sessionData,
    settings,
    showInputData,
    showBaseFields
  );

  // Getting the fields that are going to be checked
  const checkFields = useMemo(() => {
    const _checkFields: string[] = [];
    if (settings != null) {
      // Checking for barcode check fields
      Object.values(settings.processFlow.labelDesigns).forEach((ld) =>
        ld.barcodePatterns.forEach((bp) => {
          const checkFieldDatalist = bp.dataFields.filter((df) => Boolean(df.useCheckField));
          checkFieldDatalist.forEach((cfd) => _checkFields.push(cfd.name));
        })
      );

      //Checking for Manual Input Fields check fields
      Object.values(settings.processFlow.manualInputFields)
        .filter((mif) => Boolean(mif.useCheckField))
        .forEach((mif) => _checkFields.push(mif.checkField));
    }

    return _checkFields.filter((field, index) => _checkFields.indexOf(field) === index);
  }, [settings]);

  const [totalAddresses, setTotalAddresses] = useState<{}[]>([]);
  const [reviewableAddresses, setReviewableAddresses] = useState<{}[]>([]);

  // Filtering which addresses are going to be reviewed
  const getReviewableAddresses = async () => {
    if (!profile || !company) return;

    const docRef = buildDocumentRef(getSessionDoc(company, domain, session));
    const docSnap = await getDoc(docRef);

    const _reviewableAddresses = docSnap.data()?.reviewableAddresses;

    setTotalAddresses(rowsAndColumnsOrError?.['rows']);

    if (reviewableAddresses.length) {
      setReviewableAddresses([]);
    }

    rowsAndColumnsOrError?.['rows'].map((row: {}) => {
      _reviewableAddresses?.map((address) => {
        if (row['address'] === address['address']) {
          setReviewableAddresses((old) => [...old, row]);
        }
      });
    });
  };

  useEffect(() => {
    getReviewableAddresses();
  }, [rowsAndColumnsOrError]);

  return (
    <SessionDataReviewer
      reviewableAddresses={reviewableAddresses}
      totalAddresses={totalAddresses}
      checkFields={checkFields}
      settings={settings}
    />
  );
};

export default SessionReview;
