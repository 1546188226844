import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import ConditionalWrapper from 'flyid-ui-components/dist/utils/ConditionalWrapper';

const useStyles = appMakeStyles(({ form }) => ({
  flexEnd: {
    maxWidth: form.maxWidth,
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1
  }
}));

type Props = ButtonProps & {
  isLoading?: boolean;
  content?: JSX.Element | string;
  context?: 'form' | 'simple';
};

const LoadingButton: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const { isLoading, content, context, variant, color, onClick, disabled, ...otherButtonProps } =
    props;

  return (
    <ConditionalWrapper
      condition={(context ?? 'simple') === 'form'}
      wrapper={(children) => <div className={classes.flexEnd}>{children}</div>}
    >
      <Button
        className={props.className}
        sx={{
          minWidth: theme.spacing(12),
          minHeight: theme.spacing(5)
        }}
        variant={variant ?? 'contained'}
        color={color ?? 'secondary'}
        onClick={onClick}
        disabled={isLoading || disabled}
        {...otherButtonProps}
      >
        {isLoading ? <CircularProgress size={theme.spacing(4)} color="secondary" /> : content}
      </Button>
    </ConditionalWrapper>
  );
};

export default LoadingButton;
