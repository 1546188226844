import { cx } from '@emotion/css';
import { Container, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import history from 'src/history';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import useStateReducer from 'src/hooks/useStateReducer';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles } from 'src/theme/theme';
import { changePassword, resetPassword } from '../../redux/actions/userActions';
import { updateUi } from '../../redux/reducers/uiReducer';
import { isPilotProf } from '../../util/helpers/user';
import BadRequest from '../widgets/BadRequest';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2)
  },
  title: {
    color: theme.other.grey.dark
  },
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0,
    maxWidth: '1000px'
  }
}));

type Props = {
  resetData?: {
    email: string;
    actionCode: string;
  };
  isPin?: boolean;
};
const SetPassword: React.FC<Props> = (props) => {
  const [state, setState] = useStateReducer({
    newPassword: '',
    confirmation: ''
  });

  const { $t } = useIntl();
  const dispatch = useAppDispatch();
  const { usingSSO } = useAuth();
  const classes = useStyles();
  const { profile, ui } = useAppSelector((_state) => ({
    profile: selectCurrentUserProfile(_state),
    ui: _state.ui
  }));

  const isPin = !!props.isPin;
  const { email, actionCode } = props.resetData ?? {};

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (isPasswordReset: boolean) => (e: React.FormEvent) => {
    e.preventDefault();

    const { newPassword, confirmation } = state;

    // Password reset doesn't require a logged in user, therefore profile will be undefined and
    // password check for pilot profile has to be delegated to the backend
    if (!isPasswordReset) {
      if (isPilotProf(profile)) {
        if (!/^\d{6}$/.test(newPassword)) {
          dispatch(
            updateUi({
              snackbar: {
                message: $t({
                  id: 'err.pilotPassword'
                }),
                severity: 'error',
                show: true
              }
            })
          );
          return;
        }
      }
    }

    if (newPassword && confirmation && newPassword === confirmation) {
      if (isPasswordReset && actionCode) {
        dispatch(resetPassword({ newPassword, actionCode, isPin }));
      } else {
        dispatch(changePassword({ newPassword, isPin }));
      }
      return;
    }

    dispatch(
      updateUi({
        snackbar: {
          message: $t({ id: 'err.pwNoMatch' }),
          severity: 'error',
          show: true
        }
      })
    );
  };

  // Avoid showing setpw/pin again because of delay on profile update
  useEffect(() => {
    const timeoutId = setInterval(() => {
      /* istanbul ignore if */
      if (!!profile?.pwUpdated) history.push('/');
    }, 100);
    return () => clearInterval(timeoutId);
  }, [profile]);

  const pwSetCondition = profile?.pwUpdated === false && (!usingSSO || isPin);
  const pwResetCondition = !!actionCode && !!email;

  return pwSetCondition || pwResetCondition ? (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(pwResetCondition)}>
        <Typography variant="h4" className={cx(classes.margin, classes.title)}>
          {$t({
            id: `${isPin ? 'setPin' : 'setPw'}.${pwResetCondition ? 'reset' : 'set'}Title`
          })}
        </Typography>
        <Typography variant="body1" className={classes.margin}>
          {$t(
            { id: `${isPin ? 'setPin' : 'setPw'}.${pwResetCondition ? 'reset' : 'set'}Subtitle` },
            { email }
          )}
        </Typography>
        <Grid container direction={'column'}>
          <Grid>
            <TextField
              required
              id="newPassword"
              name="newPassword"
              data-testid="newPassword"
              type="password"
              label={$t({ id: isPin ? 'pin' : 'password' })}
              value={state.newPassword}
              onChange={handleChange}
              className={classes.margin}
              autoComplete="new-password"
              inputProps={{ minLength: 6, maxLength: isPin ? 6 : 999 }}
            />
          </Grid>
          <Grid>
            <TextField
              required
              id="confirmation"
              name="confirmation"
              data-testid="confirmation"
              type="password"
              label={$t({ id: 'chgPw.confirmation' })}
              value={state.confirmation}
              onChange={handleChange}
              className={classes.margin}
              autoComplete="new-password"
              inputProps={{ minLength: 6, maxLength: isPin ? 6 : 999 }}
            />
          </Grid>
        </Grid>
        <Typography variant="caption" display="block" className={classes.margin}>
          <b>* </b>
          {$t({ id: 'setPw.reqFields' })}
        </Typography>

        <LoadingButton
          isLoading={ui.loadingButton.isChangePasswordLoading}
          content={$t({ id: 'submit' })}
          type="submit"
          data-testid="submit-btn"
        />
      </form>
    </Container>
  ) : (
    <BadRequest text={$t({ id: 'unauthorized' })} />
  );
};

export default SetPassword;
