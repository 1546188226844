import { Container, Grid, Typography } from '@mui/material';
import { getBarcodePatternFields, getManualInputFields } from 'flyid-core/dist/Util/database';
import { MapOf } from 'flyid-core/dist/Util/types';
import { cloneDeep } from 'lodash';
import React, { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { appMakeStyles } from 'src/theme/theme';
import { filterNodesByType } from 'src/util/processFlow/node';
import { EditorProps } from 'src/util/processFlow/types';
import AutoFillDataEditor from './AutoFillDataEditor';
import { AutoFillData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/AutoFillData';
import {
  LabelDesign,
  isLabelDesignComplete
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import {
  ManualInputField,
  isMifComplete
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import { NodeType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';

const useStyles = appMakeStyles(({ resizableContainer, spacing, other }) => ({
  container: {
    ...resizableContainer(1),
    marginLeft: 0
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  formControl: {
    minWidth: '100%'
  }
}));

const AutoFillEditor: React.FC<EditorProps<AutoFillData[]>> = (props) => {
  const { $t } = useIntl();
  const classes = useStyles();

  const [autoFillData, setAutoFillData] = useState(() => cloneDeep(props.data));
  const settings = useAppSelector((s) => selectSettings(s, props.domain));
  
  const nodes = useAppReactFlow().getNodes();
  const labels = filterNodesByType<LabelDesign>(NodeType.LabelDesign, nodes).map(
    (n) => n.data?.specificData
  );
  const mifs = filterNodesByType<ManualInputField>(NodeType.ManualInputField, nodes).reduce(
    (obj, node) => {
      if (isMifComplete(node.data?.specificData)) obj[node.id] = node.data.specificData;
      return obj;
    },
    {} as MapOf<ManualInputField>
  );

  // Get available fields from LabelDesigns, MIFs and AutoFillableFields
  let autoFillableFields: string[] | undefined;
  if (settings) {
    autoFillableFields = [
      ...new Set([
        ...settings.fieldSettings.autoFillableFields,
        ...getBarcodePatternFields(
          labels
            .filter((l): l is LabelDesign => isLabelDesignComplete(l))
            .flatMap((label) => label.barcodePatterns),
          (bf) => !!bf.isAutoFillable
        ),
        ...getManualInputFields(mifs, (mif) => !!mif.isAutoFillable)
      ])
    ];
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onSave(cloneDeep(autoFillData));
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {$t({ id: 'processFlow.AutoFillData' })}
      </Typography>

      {/* TODO add subtitle with explanation */}

      <Grid container alignItems="center">
        {settings &&
          (autoFillableFields?.length ? (
            <AutoFillDataEditor
              autoFillData={autoFillData}
              tabularData={settings.tabularData}
              autoFillableFields={autoFillableFields}
              onAutoFillDataChange={setAutoFillData}
              handleSubmit={handleSubmit}
              extraTaskFields={settings.fieldSettings.extraTaskFields}
            />
          ) : (
            <Typography>{$t({ id: 'autoFillEditor.missingFillableFields' })}</Typography>
          ))}
      </Grid>
    </Container>
  );
};

export default AutoFillEditor;
