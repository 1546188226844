import CameraAltIcon from '@mui/icons-material/CameraAlt';
import React, { memo } from 'react';
import { isSpecificDataEqual } from 'src/util/processFlow/node';
import { TypedNode } from 'src/util/processFlow/types';
import BaseNode, { Content, Handles } from './BaseNode';
import { PictureTaking } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/PictureTaking';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { NodeProps } from '@xyflow/react';

export const TakePictureNode: React.FC<NodeProps<TypedNode<PictureTaking>>> = (props) => {
  const picTaking = props.data.specificData;
  const content: Content = {
    titleId: 'processFlow.TakePicture',
    iconStart: CameraAltIcon,
    description: picTaking?.name?.concat(picTaking?.isMandatory ? '*' : '')
  };

  const handles: Handles = {
    inputHandles: [HandleType.MULTIPLE],
    outputHandles: [HandleType.SINGLE]
  };

  return (
    <BaseNode
      selected={props.selected}
      content={content}
      handles={handles}
      id={props.id}
      {...props.data.baseNodeData}
    />
  );
};

export default memo(TakePictureNode, isSpecificDataEqual);
