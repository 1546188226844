"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlightLogConverter = void 0;
exports.FlightLogConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign({}, data);
    },
    toFirestore: (data) => data
};
