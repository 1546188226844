"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLicenseType = exports.CachedMobileLicenseDataConverter = exports.MobileLicenseDataConverter = exports.LicenseSettingsConverter = exports.LicenseConverter = exports.LicenseType = exports.COUNT_ID_PREFIX = void 0;
const lodash_1 = require("lodash");
const firestore_1 = require("../../Util/firestore");
const time_1 = require("../../Util/time");
const DomainSettings_1 = require("./Settings/DomainSettings");
exports.COUNT_ID_PREFIX = 'CountId-';
var LicenseType;
(function (LicenseType) {
    LicenseType["FLYID"] = "FLYID";
    LicenseType["FLYID_PILOT"] = "FLYID_PILOT";
    LicenseType["COUNTID"] = "COUNTID";
})(LicenseType = exports.LicenseType || (exports.LicenseType = {}));
exports.LicenseConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { expDate: (0, time_1.parseDateFromTimestamp)(data.expDate) });
    },
    toFirestore: (data) => data
};
exports.LicenseSettingsConverter = (0, firestore_1.DirectConverter)();
exports.MobileLicenseDataConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        const convertedData = Object.assign(Object.assign({}, data), { expDate: (0, time_1.parseEpochFromTimestamp)(data.expDate) });
        Object.values(convertedData.domains).forEach((mdd) => {
            mdd.settings = (0, DomainSettings_1.convertDomainSettingsFromFirestore)(mdd.settings);
        });
        return convertedData;
    },
    toFirestore: (data) => {
        const _data = (0, lodash_1.cloneDeep)(data);
        Object.values(_data.domains).forEach((mdd) => {
            mdd.settings = DomainSettings_1.DomainSettingsConverter.toFirestore(mdd.settings);
        });
        return _data;
    }
};
exports.CachedMobileLicenseDataConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { date: (0, time_1.parseDateFromTimestamp)(data.date) });
    },
    toFirestore: (data) => data
};
const getLicenseType = (license) => license.startsWith(exports.COUNT_ID_PREFIX) ? LicenseType.COUNTID : LicenseType.FLYID;
exports.getLicenseType = getLicenseType;
