"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHandleTypesFromEdge = exports.getNodeTypesFromEdge = exports.getType = exports.getPureId = exports.getNodeTypeFromStringValue = exports.ManualActionNodeTypes = exports.ParentSpecificData = exports.EdgeTypes = exports.NodeTypeValues = exports.NodeTypeKeys = exports.NodeType = exports.FlowEdge = exports.FlowNode = exports.HandleType = void 0;
const Vertex_1 = require("./Vertex");
var HandleType;
(function (HandleType) {
    HandleType["START"] = "START";
    HandleType["SINGLE"] = "SINGLE";
    HandleType["MULTIPLE"] = "MULTIPLE";
    HandleType["END"] = "END";
})(HandleType = exports.HandleType || (exports.HandleType = {}));
class FlowNode {
    constructor() {
        this.type = NodeType.Start;
        this.position = new Vertex_1.Vertex();
    }
}
exports.FlowNode = FlowNode;
class FlowEdge {
    constructor() {
        this.source = '';
        this.sourceHandle = '';
        this.target = '';
        this.targetHandle = '';
    }
}
exports.FlowEdge = FlowEdge;
var NodeType;
(function (NodeType) {
    NodeType["Start"] = "Start";
    NodeType["LabelDesign"] = "LabelDesign";
    NodeType["ManualInputField"] = "ManualInputField";
    NodeType["TakePicture"] = "TakePicture";
    NodeType["AutoFillData"] = "AutoFillData";
    NodeType["Conditional"] = "Conditional";
    NodeType["LogicalBlock"] = "LogicalBlock";
    NodeType["CustomMarker"] = "CustomMarker";
    NodeType["End"] = "End";
})(NodeType = exports.NodeType || (exports.NodeType = {}));
exports.NodeTypeKeys = Object.keys(NodeType);
exports.NodeTypeValues = Object.values(NodeType);
var EdgeTypes;
(function (EdgeTypes) {
    EdgeTypes["Custom"] = "Custom";
})(EdgeTypes = exports.EdgeTypes || (exports.EdgeTypes = {}));
/** Classes which parent a node should extendo from this */
class ParentSpecificData {
    constructor() {
        this.name = '';
        this.contentChildrenIds = [];
    }
}
exports.ParentSpecificData = ParentSpecificData;
exports.ManualActionNodeTypes = [
    NodeType.ManualInputField,
    NodeType.TakePicture,
    NodeType.CustomMarker
];
function getNodeTypeFromStringValue(value) {
    return value && exports.NodeTypeValues.includes(value) ? value : undefined;
}
exports.getNodeTypeFromStringValue = getNodeTypeFromStringValue;
const typedIdRegex = /^([^_]+)_([0-9a-z]+)$/; // ids are <Type>_<N base36 chars>
/** Get randomized part of element id (without element type) */
function getPureId(elementId) {
    var _a, _b;
    return (_b = (_a = elementId === null || elementId === void 0 ? void 0 : elementId.match(typedIdRegex)) === null || _a === void 0 ? void 0 : _a[2]) !== null && _b !== void 0 ? _b : elementId;
}
exports.getPureId = getPureId;
/**
 * This method returns the type of the given element id, whether node or handle.
 * It expects the id to be in the format '&lt;type&gt;_&lt;id&gt;'
 */
function getType(elementId) {
    var _a;
    return (_a = elementId === null || elementId === void 0 ? void 0 : elementId.match(typedIdRegex)) === null || _a === void 0 ? void 0 : _a[1];
}
exports.getType = getType;
function getNodeTypesFromEdge(edge) {
    const sourceType = getType(edge.source);
    const targetType = getType(edge.target);
    return [sourceType, targetType];
}
exports.getNodeTypesFromEdge = getNodeTypesFromEdge;
function getHandleTypesFromEdge(edge) {
    const sourceType = getType(edge.sourceHandle);
    const targetType = getType(edge.targetHandle);
    return [sourceType, targetType];
}
exports.getHandleTypesFromEdge = getHandleTypesFromEdge;
