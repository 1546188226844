import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  lighten,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import clsx from 'clsx';
import { ELLIPSIS } from 'flyid-core/dist/Common/const';
import {
  BarcodeCharacterFilter,
  BarcodeField,
  BarcodeFieldLimiterTypeKeys,
  BarcodeFieldLimiterTypes,
  BarcodeFieldTypeKeys,
  BarcodeFieldTypes
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/BarcodeField';
import { BarcodePattern } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/BarcodePattern';
import { isNonNulli } from 'flyid-core/dist/Util/helpers';
import { cloneDeep, initial, isEmpty } from 'lodash';
import React, { ChangeEvent, FormEvent, Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useStateReducer from 'src/hooks/useStateReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { Nullable } from 'tsdef';

const myWidth = 900;

const useStyles = appMakeStyles(({ spacing, palette, other }) => ({
  root: { padding: spacing(1) },
  gridContainer: {
    width: myWidth
  },
  labelInputContainer: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  input: {
    marginBottom: spacing(2)
  },
  tooltip: {
    alignSelf: 'center'
  },
  formControl: {
    margin: spacing(0, 1),
    minWidth: '100%'
  },
  extendedButtonIcon: {
    marginRight: spacing(1)
  },
  header: {
    color: other.grey.dark,
    alignSelf: 'center'
  },
  gridItem: {
    alignSelf: 'center'
  },
  fieldModifierTop: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '15px 15px 0 0',
    minHeight: spacing(6)
  },
  fieldItemLight: {
    backgroundColor: lighten(palette.primary.dark, 0.96)
  },
  fieldItemDark: {
    backgroundColor: lighten(palette.primary.dark, 0.93)
  },
  fieldModifier: {
    backgroundColor: lighten(palette.primary.dark, 0.85)
  },
  fieldModifierBottom: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '0 0 15px 15px',
    padding: spacing(1)
  },
  addFieldGridItem: {
    paddingLeft: spacing(1),
    minHeight: spacing(6)
  },
  testRegexError: {
    marginLeft: spacing(2),
    color: palette.error.main,
    display: 'inline'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: spacing(3),
    marginBottom: spacing(2),
    width: myWidth
  }
}));

type BarcodeFieldForFilling = Omit<BarcodeField, 'type' | 'limiterType' | 'limiter'> & {
  type: number;
  limiterType: number;
  range: string;
  separator: string;
};

type BarcodePatternForFilling = Omit<BarcodePattern, 'dataFields'> & {
  dataFields: BarcodeFieldForFilling[];
};

const initialInputState: BarcodeFieldForFilling = {
  // Current field-to-be-added values
  type: BarcodeFieldTypeKeys.indexOf(BarcodeFieldTypes.data),
  name: '',
  limiterType: BarcodeFieldLimiterTypeKeys.indexOf(BarcodeFieldLimiterTypes.range),
  range: '',
  separator: '',
  prefix: '',
  postfix: '',
  checkField: '',
  useCheckField: false,
  isAutoFillable: false,
  characterFilters: []
};

const initialState = {
  fields: [] as BarcodeFieldForFilling[],
  currentRegex: '',
  ignoreRemaining: false,
  allowNull: false,
  isRequired: false,
  pos: 0,
  dialogOpen: false,
  index: -1,
  regex: '',
  // Check regex
  regexResult: {} as { [key: string]: string },
  regexError: null as Nullable<string>,
  regexTest: '',
  triggerBarcodePatternChange: 0, // flip to trigger effect
  // Character filtering,
  useCharacterFilter: false,
  // Builder errors
  nameError: null as Nullable<string>,
  rangeError: null as Nullable<string>,
  separatorError: null as Nullable<string>,
  posError: null as Nullable<string>,
  customFilterError: null as Nullable<string>,
  characterFiltersError: null as Nullable<string>,
  ...initialInputState
};
type State = typeof initialState;

// If these matches, means data contains disallowed characters
// const nameInvalidCharsRegexCheck = /[^a-zA-Z0-9_]+/;
const prepostfixInvalidCharsRegexCheck = /[^a-zA-Z0-9_@]+/;
// Filters special regex characters, which should be prepended by _\
const specialCharsRegex = /[-/\\^$*+?.()|[\]{}]/g;
const specialCharsRegexIntentional = /_\\([-/\\^$*+?.()|[\]{}])/g;
const rangeRegex = /^(-1)$|^(\d)+$|^(\d+),(\d+)$/;

const regexEscape = (s: string) => s.replace(specialCharsRegex, '\\$&');

const regexAllowIntentional = (s: string) => s.replace(specialCharsRegexIntentional, '$1');

const escapeRegexAllowingIntentional = (s: string) => regexAllowIntentional(regexEscape(s));

const getBarcodePatternDataFieldsForFilling = (
  barcodePatterns: BarcodePattern[],
  index: number
): BarcodeFieldForFilling[] => {
  const settingsBarcodeFields = cloneDeep(barcodePatterns?.[index]?.['dataFields']) ?? [];

  const bfffs: BarcodeFieldForFilling[] = settingsBarcodeFields.map((field) => {
    const isRangeLimiter = field.limiterType === 'range';
    return {
      ...field,
      type: BarcodeFieldTypeKeys.indexOf(field.type),
      limiterType: BarcodeFieldLimiterTypeKeys.indexOf(field.limiterType),
      range: isRangeLimiter ? field.limiter : '',
      separator: isRangeLimiter ? '' : field.limiter
    };
  });
  return bfffs;
};

type Props = {
  index: number;
  barcodePatterns: BarcodePattern[];
  domain: string;
  labelName: string;
  stageWidget: ReactJSXElement;
  saveButton: ReactJSXElement;
  onBackToLabelEditor: () => void;
  onBarcodePatternChange: (index: number, barcodePattern: BarcodePattern) => void;
  onLabelDesignCheckboxChange: (name: string) => (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

const getStateFromProps = (barcodePatterns: BarcodePattern[], barcodePatternIndex: number) => {
  if (barcodePatterns && barcodePatternIndex > -1) {
    const dataFields = getBarcodePatternDataFieldsForFilling(barcodePatterns, barcodePatternIndex);
    return {
      ...initialState,
      fields: dataFields,
      currentRegex: barcodePatterns[barcodePatternIndex]?.['regex'] ?? '',
      ignoreRemaining: barcodePatterns[barcodePatternIndex]?.['ignoreRemaining'] ?? false,
      isRequired: barcodePatterns[barcodePatternIndex]?.['isRequired'] ?? false,
      pos: dataFields.length,
      index: barcodePatternIndex
    };
  }
  return initialState;
};

const BarcodePatternEditor: React.FC<Props> = (props) => {
  const { barcodePatterns, index: barcodePatternIndex } = props;

  const [state, setState] = useStateReducer<State>(
    getStateFromProps(barcodePatterns, barcodePatternIndex)
  );
  const { $t } = useIntl();
  const { other, spacing, select } = useAppTheme();
  const classes = useStyles();

  useEffect(() => {
    setState(getStateFromProps(barcodePatterns, barcodePatternIndex));
  }, [barcodePatternIndex]);

  useEffect(() => {
    props.onBarcodePatternChange(
      barcodePatternIndex,
      buildDatabaseFields({
        dataFields: state.fields,
        regex: state.currentRegex,
        isRequired: state.isRequired,
        ignoreRemaining: state.ignoreRemaining
      })
    );
  }, [state.triggerBarcodePatternChange]);

  const handleSelectorChange = (e: SelectChangeEvent<string[]>) => {
    const changes: Partial<State> = { [e.target.name]: e.target.value };
    if (e.target.name === 'characterFilters') {
      // Clean errors on change
      changes.characterFiltersError = '';
      // Clean customFilter if BarcodeCharacterFilter.Custom is not selected.
      if (!e.target.value.includes(BarcodeCharacterFilter.Custom)) {
        changes.customFilter = undefined;
      }
    }
    setState(changes);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const fieldWithError = /(pos|range|separator|name|customFilter)/g.exec(e.target.name);
    let nextState = {};

    if (fieldWithError) {
      nextState[fieldWithError[1].concat('Error')] = '';
    }

    switch (e.target.name) {
      case 'type': {
        nextState = {
          ...nextState,
          ...initialInputState,
          name: state.name
        };
        break;
      }
      case 'prefix':
      case 'postfix':
        if (
          prepostfixInvalidCharsRegexCheck.test(e.target.value) &&
          !specialCharsRegex.test(e.target.value)
        ) {
          return;
        }
        break;
      default:
        break;
    }

    nextState[e.target.name] = e.target.value;
    setState(nextState);
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // rebuild regex based on type of checkbox change
    const checked = event.target.checked;
    const currentRegex =
      name === 'ignoreRemaining' ? buildRegex(undefined, checked) : state.currentRegex;
    setState({ [name]: checked, currentRegex, triggerBarcodePatternChange: Math.random() });
  };

  const handleRemoveField = (index) => {
    const newFields = state.fields.filter((val, idx) => idx !== index);
    const newRegex = buildRegex(newFields);

    setState({
      ...initialState,
      fields: newFields,
      currentRegex: newRegex,
      pos: newFields.length,
      triggerBarcodePatternChange: Math.random()
    });
  };

  const handleAddField = () => {
    const {
      pos,
      type,
      name,
      limiterType,
      range,
      separator,
      prefix,
      postfix,
      checkField,
      useCheckField,
      isAutoFillable,
      characterFilters,
      customFilter,
      useCharacterFilter,
      fields
    } = state;
    const mandatory = $t({ id: 'ebp.mandatoryField' });

    // Check fields validity
    if (!name?.length) {
      setState({ nameError: mandatory });
      return;
    } else if (type === 0) {
      const match = specialCharsRegex.exec(name);
      if (match) {
        setState({
          nameError: $t({ id: 'ebp.charNotAllowed' }, { char: match[0] })
        });
        return;
      }
    }

    if (pos < 0 || pos > fields.length) {
      setState({ posError: $t({ id: 'ebp.invalidPos' }) });
      return;
    }

    const data: BarcodeFieldForFilling = {
      type,
      name,
      limiterType,
      range,
      separator,
      prefix,
      postfix,
      checkField,
      useCheckField,
      isAutoFillable,
      characterFilters,
      customFilter
    };
    // Check all required input fields in data type
    if (type === 0) {
      // data
      if (limiterType === 0) {
        // range
        if (isEmpty(range)) {
          setState({ rangeError: mandatory });
          return;
        }

        const match = rangeRegex.exec(range);
        if (match) {
          if (match[3] && match[4]) {
            if (parseInt(match[3], 10) >= parseInt(match[4], 10)) {
              setState({ rangeError: $t({ id: 'ebp.rangeBet' }) });
              return;
            }
          }
        } else {
          setState({ rangeError: $t({ id: 'ebp.invalidRange' }) });
          return;
        }
      } // separator
      else if (isEmpty(separator)) {
        setState({ separatorError: mandatory });
        return;
      }

      // Check for repeated items if is data field
      for (const field of fields) {
        if (field.name === name) {
          setState({ dialogOpen: true });
          return;
        }
      }

      //Use name as checkfield if checkfield is left empty
      if (useCheckField && isEmpty(checkField)) {
        data.checkField = name;
      }
    }

    // Check character filters
    if (useCharacterFilter) {
      if (!characterFilters.length) {
        setState({ characterFiltersError: mandatory });
        return;
      } else if (
        characterFilters.includes(BarcodeCharacterFilter.Custom) &&
        !isNonNulli(customFilter)
      ) {
        setState({ customFilterError: mandatory });
        return;
      }
    }

    const newFields = [...fields];
    newFields.splice(pos, 0, data);

    console.log(newFields);
    const newRegex = buildRegex(newFields);
    setState({
      ...initialState,
      fields: newFields,
      currentRegex: newRegex,
      pos: newFields.length,
      triggerBarcodePatternChange: Math.random()
    });
  };

  const buildRegex = (_fields?: BarcodeFieldForFilling[], ignoreRemaining = false) => {
    const fields = Array.isArray(_fields) ? _fields : state.fields;

    if (fields && fields.length) {
      const regexData = ['^'];
      fields.forEach((field) => {
        const escPrefix = escapeRegexAllowingIntentional(field.prefix);
        const escPostfix = escapeRegexAllowingIntentional(field.postfix);
        const escSeparator = escapeRegexAllowingIntentional(field.separator);

        switch (field.type) {
          case 0: // Field
            const charMatcher = field.characterFilters?.length
              ? '[' +
                field.characterFilters
                  .map((f) =>
                    f === BarcodeCharacterFilter.Custom
                      ? field.customFilter ?? ''
                      : BarcodeCharacterFilter[f]
                  )
                  .join('') +
                ']'
              : '.';
            console.log(field.characterFilters, field.customFilter, charMatcher);
            if (field.limiterType === 0) {
              // Range
              const match = rangeRegex.exec(field.range);
              if (match) {
                if (match[1]) {
                  regexData.push(`(${escPrefix}${charMatcher}*${escPostfix})`);
                } else {
                  regexData.push(`(${escPrefix}${charMatcher}{${field.range}}${escPostfix})`);
                }
              }
            } else {
              // Limiter
              regexData.push(`(${escPrefix}[^${field.postfix}${field.separator}]*)${escSeparator}`);
            }
            break;
          case 1: // Constant
            regexData.push(escapeRegexAllowingIntentional(field.name));
            break;
          default:
            break;
        }
      });

      regexData.push(ignoreRemaining ? '.*$' : '$');
      return regexData.join('');
    }
    return undefined;
  };

  const buildDatabaseFields = (data: BarcodePatternForFilling) => {
    const fields = data.dataFields !== undefined ? data.dataFields : state.fields;

    const dataFields: BarcodeField[] = [];
    fields.forEach((field) => {
      dataFields.push({
        name: field.name,
        type: BarcodeFieldTypeKeys[field.type],
        prefix: field.prefix,
        postfix: field.postfix,
        limiterType: BarcodeFieldLimiterTypeKeys[field.limiterType],
        limiter: field.limiterType === 0 ? field.range : field.separator,
        checkField: field.checkField,
        useCheckField: field.useCheckField,
        isAutoFillable: field.isAutoFillable ?? false,
        characterFilters: field.characterFilters,
        customFilter: field.customFilter
      });
    });

    const barcodePattern: BarcodePattern = {
      regex: data.regex || state.regex,
      ignoreRemaining:
        data.ignoreRemaining !== undefined ? data.ignoreRemaining : state.ignoreRemaining,
      isRequired: data.isRequired !== undefined ? data.isRequired : state.isRequired,
      dataFields
    };

    return barcodePattern;
  };

  const testRegex = () => {
    const regex = new RegExp(state.currentRegex);
    if (regex) {
      const match = regex.exec(state.regexTest);
      if (match && match.length > 1) {
        let index = 1;
        const regexResult = {};
        state.fields.forEach((field) => {
          if (field.type === 0) {
            regexResult[field.name] = match[index];
            index++;
          }
        });
        setState({ regexResult, regexError: null });
        return;
      }
    }
    setState({ regexResult: {}, regexError: $t({ id: 'ebp.failMatch' }) });
  };

  const clearTest = () => setState({ regexResult: {}, regexError: null });

  const readyToRender = barcodePatternIndex !== -1;

  const renderHeader = () => (
    <Box className={classes.headerContainer}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography variant="h5" className={classes.title}>
          {$t({ id: 'ebp.title' })}
          <>
            {':'}
            <br />
            &emsp;↪ {$t({ id: 'domain' })}
            {`: ${props.domain}`}
            <br /> &emsp;&emsp;↪ {$t({ id: 'label' })}
            {`: ${props.labelName}`}
            <br />
            {readyToRender ? (
              <span>
                &emsp;&emsp;&emsp;↪{' '}
                {$t({ id: 'ebp.patternIndex' }, { index: barcodePatternIndex + 1 })}
              </span>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  m: 2
                }}
              >
                <WarningRoundedIcon sx={{ color: 'error.main', fontSize: spacing(4) }} />
                <Typography variant="h5">{$t({ id: 'ebp.noBarcodeSelected' })}</Typography>
              </Box>
            )}
          </>
        </Typography>

        {readyToRender && (
          <Typography variant="h6" sx={{ color: other.grey.dark }}>
            {$t({ id: 'ebp.currRegex' })}
            <Tooltip
              disableTouchListener
              className={classes.tooltip}
              title={
                <Typography variant="caption">
                  {$t({ id: 'ebp.regexInfo' }, { nl: <br key="nl2" /> })}
                </Typography>
              }
            >
              <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
            </Tooltip>
            {': '}
            {state.currentRegex}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {props.stageWidget}
        <Button
          aria-label={$t({ id: 'ebp.editLabel' })}
          variant="contained"
          color="primary"
          onClick={props.onBackToLabelEditor}
          sx={{ mt: 0.5, minWidth: '202px' }}
        >
          <SettingsOverscanIcon sx={{ mr: 2 }} />
          {$t({ id: 'ebp.editLabel' })}
        </Button>
      </Box>
    </Box>
  );

  const renderPatternEditor = () => {
    const renderTitle = () => (
      <Grid item xs={12} sx={{ py: 1, px: 0 }}>
        <Typography variant="subtitle1" sx={{ color: other.grey.main, fontWeight: 'bold' }}>
          {$t({ id: 'ebp.fields' })}
        </Typography>
      </Grid>
    );

    const renderExistingFieldsHeader = () => (
      <Grid item xs={12} container className={classes.fieldModifierTop}>
        {/* Header */}
        <Grid item xs={1} className={classes.header}>
          <Typography align="center">Pos</Typography>
        </Grid>
        <Grid item xs={1.8} className={classes.header}>
          <Typography>{$t({ id: 'ebp.fieldType' })}</Typography>
        </Grid>
        <Grid item xs={1.2} className={classes.header}>
          <Typography>{$t({ id: 'ebp.name' })}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.header}>
          <Typography>{$t({ id: 'ebp.prefix' })}</Typography>
        </Grid>
        <Grid item xs={1.5} className={classes.header}>
          <Typography>{$t({ id: 'ebp.limiter' })}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.header}>
          <Typography>{$t({ id: 'ebp.postfix' })}</Typography>
        </Grid>
        <Grid item xs={1.25} className={classes.header}>
          <Typography>{$t({ id: 'checkField' })}</Typography>
        </Grid>
        <Grid item xs={1.25} className={classes.header}>
          <Typography>{$t({ id: 'ebp.characterFilters' })}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.header}>
          <Typography>{$t({ id: 'autoFillable' })}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.header}>
          <Typography>{$t({ id: 'actions' })}</Typography>
        </Grid>
      </Grid>
    );

    const renderExistingFields = () =>
      state.fields.map((field, index) => (
        <Grid
          key={`${field.name}${index}`}
          xs={12}
          item
          container
          justifyContent="center"
          className={index % 2 === 0 ? classes.fieldItemDark : classes.fieldItemLight}
        >
          <Grid item xs={1} className={classes.gridItem}>
            <Typography align="center">{index}</Typography>
          </Grid>
          <Grid item xs={1.8} className={classes.gridItem}>
            <Typography>
              {$t({ id: `ebp.${BarcodeFieldTypeKeys[field.type] as string}` })}
            </Typography>
          </Grid>
          <Grid item xs={1.2} className={classes.gridItem}>
            <Typography>{field.name}</Typography>
          </Grid>
          {field.type === 0 ? (
            <Fragment key={`field${index}`}>
              <Grid item xs={1} className={classes.gridItem}>
                <Typography>{field.prefix}</Typography>
              </Grid>
              <Grid item xs={1} className={classes.gridItem}>
                <Typography>
                  {`${$t({
                    id: `ebp.${BarcodeFieldLimiterTypeKeys[field.limiterType] as string}`
                  })}: ${field.limiterType === 0 ? field.range : field.separator}`}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.gridItem}>
                <Typography>{field.postfix}</Typography>
              </Grid>
              <Grid item xs={1.5} className={classes.gridItem}>
                <Typography>{field.checkField}</Typography>
              </Grid>
              <Grid item xs={1.5} className={classes.gridItem}>
                <Typography>
                  {field.characterFilters
                    ?.map((f) =>
                      f === BarcodeCharacterFilter.Custom
                        ? field.customFilter
                        : BarcodeCharacterFilter[f]
                    )
                    .filter(Boolean)
                    .join('')}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.gridItem}>
                {field.isAutoFillable ? <CheckIcon /> : null}
              </Grid>
            </Fragment>
          ) : (
            <Grid item xs={7} />
          )}
          <Grid item xs={1} className={classes.gridItem} sx={{ textAlign: 'center' }}>
            <Tooltip title={$t({ id: 'remove' })}>
              <IconButton
                edge="end"
                aria-label={$t({ id: 'remove' })}
                sx={{ color: 'error.main' }}
                onClick={() => handleRemoveField(index)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ));

    const renderAddField = () => (
      <>
        <Grid
          item
          xs={12}
          className={state.fields.length ? classes.fieldModifier : classes.fieldModifierTop}
        >
          <Typography
            variant="subtitle1"
            className={classes.addFieldGridItem}
            sx={{
              color: other.grey.dark,
              fontWeight: 'bold',
              minHeight: spacing(6),
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {$t({ id: 'ebp.addNewFields' })}
          </Typography>
        </Grid>

        {/* Field values */}
        <Grid
          item
          container
          xs={12}
          className={state.type === 0 ? classes.fieldModifier : classes.fieldModifierBottom}
        >
          {/* Field type */}
          <Grid item xs={2} sx={{ pl: 2 }}>
            <TextField
              fullWidth
              select
              variant="standard"
              name="type"
              id="type"
              label={$t({ id: 'ebp.fieldType' })}
              value={state.type}
              onChange={handleChange}
            >
              {BarcodeFieldTypeKeys.map((value, index) => (
                <MenuItem value={index} key={`${value}${index}`}>
                  {$t({ id: `ebp.${BarcodeFieldTypeKeys[index]}` })}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={state.type === 0 ? 2 : 3} className={classes.addFieldGridItem}>
            <TextField
              error={!isEmpty(state.nameError)}
              name="name"
              id="name"
              type="text"
              variant="standard"
              label={`${$t({
                id: `ebp.${
                  state.type === BarcodeFieldTypeKeys.indexOf(BarcodeFieldTypes.constant)
                    ? 'constant'
                    : 'name'
                }`
              })} *`}
              value={state.name}
              onChange={handleChange}
              helperText={state.nameError}
            />
          </Grid>
          {state.type === 0 ? (
            <>
              <Grid item xs={1} className={classes.addFieldGridItem}>
                <TextField
                  name="prefix"
                  id="prefix"
                  type="text"
                  variant="standard"
                  label={$t({ id: 'ebp.prefix' })}
                  value={state.prefix}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2} className={classes.addFieldGridItem}>
                <TextField
                  fullWidth
                  select
                  variant="standard"
                  name="limiterType"
                  id="limiterType"
                  label={$t({ id: 'ebp.limiter' })}
                  value={state.limiterType}
                  onChange={handleChange}
                  sx={{ ml: 0 }}
                >
                  {BarcodeFieldLimiterTypeKeys.map((value, index) => (
                    <MenuItem value={index} key={value}>
                      {$t({ id: `ebp.${BarcodeFieldLimiterTypeKeys[index]}` })}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2} className={classes.addFieldGridItem}>
                {state.limiterType === 0 ? (
                  <TextField
                    fullWidth
                    error={!isEmpty(state.rangeError)}
                    id="range"
                    name="range"
                    type="text"
                    variant="standard"
                    label={`${$t({ id: 'ebp.range' })} *`}
                    value={state.range}
                    onChange={handleChange}
                    helperText={state.rangeError}
                  />
                ) : (
                  <TextField
                    fullWidth
                    error={!isEmpty(state.separatorError)}
                    id="separator"
                    name="separator"
                    type="text"
                    variant="standard"
                    label={`${$t({ id: 'ebp.separator' })} *`}
                    value={state.separator}
                    onChange={handleChange}
                    helperText={state.separatorError}
                  />
                )}
              </Grid>

              <Grid item xs={1} className={classes.addFieldGridItem}>
                <TextField
                  fullWidth
                  name="postfix"
                  id="postfix"
                  variant="standard"
                  type="text"
                  label={$t({ id: 'ebp.postfix' })}
                  value={state.postfix}
                  onChange={handleChange}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs className={classes.addFieldGridItem}>
            <TextField
              fullWidth
              error={!isEmpty(state.posError)}
              name="pos"
              id="pos"
              type="number"
              label="Pos"
              variant="standard"
              value={state.pos}
              onChange={handleChange}
              inputProps={{ min: 0, max: state.fields ? state.fields.length : 0 }}
              helperText={state.posError}
            />
          </Grid>

          {/* Add button */}
          {state.type === 0 ? null : <Grid item xs={5} />}
          <Grid item xs={1} sx={{ textAlign: 'center' }}>
            <Tooltip title={$t({ id: 'add' })} onClick={handleAddField}>
              <Fab size="small" color="secondary" aria-label="add">
                <AddIcon fontSize="large" />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>

        {/* Field options */}
        {state.type === 0 && (
          <>
            {/* Check Field & Autofillable */}
            <Grid
              item
              xs={12}
              container
              className={clsx(classes.addFieldGridItem, classes.fieldModifier)}
              sx={{ px: 1, pt: 1 }}
            >
              {/* Autofillable */}
              <Grid item xs={4} alignSelf="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={state.isAutoFillable}
                      onChange={handleCheckboxChange('isAutoFillable')}
                    />
                  }
                  label={$t({ id: 'ebp.isAutoFillable' })}
                  labelPlacement="end"
                />
              </Grid>

              {/* Check Field */}
              <Grid item container xs={8} alignItems="center">
                <Grid item xs={6} alignSelf="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={state.useCheckField}
                        onChange={handleCheckboxChange('useCheckField')}
                      />
                    }
                    label={$t({ id: 'ebp.useCheckField' })}
                    labelPlacement="end"
                  />
                </Grid>
                {state.useCheckField && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={state.checkField ? undefined : state.name}
                      size="small"
                      id="checkField"
                      name="checkField"
                      type="text"
                      label={`${$t({ id: 'checkField' })} (${state.name})`}
                      value={state.checkField}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* Chatacter filters */}
            <Grid item xs={12} container className={classes.fieldModifierBottom}>
              {/* Use filter */}
              <Grid item xs={4} alignSelf="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={state.useCharacterFilter}
                      onChange={handleCheckboxChange('useCharacterFilter')}
                    />
                  }
                  label={$t({ id: 'ebp.useCharacterFilter' })}
                  labelPlacement="end"
                />
              </Grid>

              {/* Character filter values */}
              <Grid item container xs={8} alignItems="center" sx={{ pr: 1 }}>
                {state.useCharacterFilter && (
                  <>
                    {/* Character filter selector */}
                    <Grid item xs={5}>
                      <FormControl
                        sx={{ pr: 1 }}
                        fullWidth
                        error={!isEmpty(state.characterFiltersError)}
                      >
                        <InputLabel id="character-filter-select-label">
                          {$t({ id: 'ebp.characterFilters' })}
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="character-filter-select-label"
                          id="character-filter-select"
                          required={state.useCharacterFilter}
                          multiple
                          name="characterFilters"
                          value={state.characterFilters}
                          onChange={handleSelectorChange}
                          input={<OutlinedInput label={$t({ id: 'ebp.characterFilters' })} />}
                          renderValue={(selected) => {
                            const joined = selected
                              .map((s) =>
                                s === BarcodeCharacterFilter.Custom
                                  ? $t({ id: 'custom' })
                                  : BarcodeCharacterFilter[s]
                              )
                              .join(', ');
                            return joined.length > 25 ? joined.substring(0, 25) + ELLIPSIS : joined;
                          }}
                          MenuProps={select.getMenuProps()}
                          error={!isEmpty(state.characterFiltersError)}
                        >
                          {Object.entries(BarcodeCharacterFilter).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              <Checkbox
                                checked={
                                  state.characterFilters.indexOf(key as BarcodeCharacterFilter) > -1
                                }
                              />
                              {key === BarcodeCharacterFilter.Custom ? $t({ id: 'custom' }) : value}
                            </MenuItem>
                          ))}
                        </Select>
                        {!isEmpty(state.characterFiltersError) && (
                          <FormHelperText>{state.characterFiltersError}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* Custom character filter */}
                    {state.characterFilters.includes(BarcodeCharacterFilter.Custom) && (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            error={!isEmpty(state.customFilterError)}
                            variant="outlined"
                            placeholder={state.customFilter ? undefined : state.name}
                            size="small"
                            id="customFilter"
                            name="customFilter"
                            type="text"
                            label={$t({ id: 'ebp.customFilter' })}
                            value={state.customFilter ?? ''}
                            onChange={handleChange}
                            helperText={state.customFilterError}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip
                            disableTouchListener
                            title={<Typography variant="subtitle2">Regex-like</Typography>}
                          >
                            <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark', m: 1 }} />
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );

    const renderBarcodeOptions = () => (
      <Grid container className={classes.labelInputContainer}>
        <Grid item xs={12} sx={{ py: 1, px: 0 }}>
          <Typography variant="subtitle1" sx={{ color: other.grey.main }}>
            {$t({ id: 'ebp.barcodeOptions' })}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={state.ignoreRemaining}
                onChange={handleCheckboxChange('ignoreRemaining')}
              />
            }
            label={$t({ id: 'ebp.ignoreRemaining' })}
            labelPlacement="end"
          />
        </Grid>

        <Grid item xs="auto">
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={state.isRequired}
                onChange={handleCheckboxChange('isRequired')}
              />
            }
            label={$t({ id: 'ebp.isRequired' })}
            labelPlacement="end"
          />
        </Grid>
      </Grid>
    );

    const renderRegexTesting = () => (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.labelInputContainer}
        rowSpacing={1}
      >
        <Grid item xs={12} sx={{ pl: 0 }}>
          <Typography variant="subtitle1" sx={{ color: other.grey.main }}>
            {$t({ id: 'ebp.checkRegex' })}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pl: 0 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="regexTest"
            id="regexTest"
            type="text"
            label={$t({ id: 'ebp.codeTest' })}
            value={state.regexTest}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs="auto">
          <Button onClick={testRegex} color="primary" variant="outlined" sx={{ ml: 1 }}>
            {$t({ id: 'ebp.test' })}
          </Button>
        </Grid>

        <Grid item xs="auto">
          <Button onClick={clearTest} color="info" variant="outlined" sx={{ ml: 1 }}>
            {$t({ id: 'ebp.clearResults' })}
          </Button>

          {state.regexError && (
            <Typography variant="body1" className={classes.testRegexError}>
              {state.regexError}
            </Typography>
          )}
        </Grid>
        <Grid item xs />

        {Object.keys(state.regexResult)?.length > 0 && (
          <>
            <Grid item xs={12} sx={{ pl: 0 }} key={'ResultsTypog'}>
              <Typography>{`${$t({ id: 'ebp.results' })}:`}</Typography>
            </Grid>
            {Object.keys(state.regexResult).map((key, index) => (
              <Grid item xs sx={{ pl: 0 }} key={`${key}${index}`}>
                <Typography
                  variant="body1"
                  noWrap
                >{`${key}: ${state.regexResult[key]}`}</Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    );

    return (
      <>
        <Grid container direction="column">
          {renderTitle()}
          {/* Existing fields */}
          {state.fields.length ? (
            <>
              {/* Field Descriptions */}
              {renderExistingFieldsHeader()}
              {/* Field Items */}
              {renderExistingFields()}
            </>
          ) : (
            /* If there are no fields added yet */
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="body1">{$t({ id: 'ebp.noFields' })}</Typography>
            </Grid>
          )}

          {/* Adding new fields */}
          {renderAddField()}
        </Grid>

        {/* Barcode Options */}
        {renderBarcodeOptions()}

        {/* Regex testing */}
        {renderRegexTesting()}
      </>
    );
  };

  const renderErrorDialog = () => (
    <Dialog
      open={state.dialogOpen}
      onClose={() => setState({ dialogOpen: false })}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-content"
    >
      <DialogTitle id="dialog-title">
        {$t(
          { id: 'ebp.fieldExistsError' },
          { name: <b key={`name${Math.random()}`}>{state.name}</b> }
        )}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setState({ dialogOpen: false })} color="primary" autoFocus>
          {$t({ id: 'ok' })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container className={classes.root}>
      {/* Header with title, subtitle and label preview */}
      {renderHeader()}

      {readyToRender && (
        <>
          {/* Barcode pattern editor */}
          {renderPatternEditor()}

          {/* Error dialog */}
          {renderErrorDialog()}

          {/* Save button aligned to the end */}
          <Grid
            container
            direction={'row'}
            justifyContent={'space-between'}
            alignContent={'flex-end'}
            flexGrow={1}
          >
            <Grid item />
            <Grid item>{props.saveButton}</Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default BarcodePatternEditor;
