"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseFieldsRequiredKeys = exports.BaseFieldsEntries = exports.BaseFieldsKeys = exports.BaseFields = void 0;
/** Fields always present in every acquisition */
class BaseFields {
    constructor() {
        this.address = "address";
        this.checkedAt = "checkedAt";
        this.employeeName = "employeeName";
        this.employeeId = "employeeId";
    }
}
exports.BaseFields = BaseFields;
exports.BaseFieldsKeys = Object.keys(new BaseFields());
exports.BaseFieldsEntries = Object.entries(new BaseFields());
exports.BaseFieldsRequiredKeys = exports.BaseFieldsKeys;
