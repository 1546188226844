"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VertexRequiredKeys = exports.VertexEntries = exports.VertexKeys = exports.Vertex = void 0;
class Vertex {
    constructor() {
        this.x = 0;
        this.y = 0;
    }
}
exports.Vertex = Vertex;
exports.VertexKeys = Object.keys(new Vertex());
exports.VertexEntries = Object.entries(new Vertex());
exports.VertexRequiredKeys = ["x", "y"];
