"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClassKeys = exports.convertListToMap = exports.convertMapToList = void 0;
function convertMapToList(data) {
    return Object.values(data).map((v) => v);
}
exports.convertMapToList = convertMapToList;
function convertListToMap(data) {
    const map = {};
    data.forEach((v, i) => (map[i] = v));
    return map;
}
exports.convertListToMap = convertListToMap;
function getClassKeys(o) {
    return Object.keys(o);
}
exports.getClassKeys = getClassKeys;
